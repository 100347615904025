/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const FamilyIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M14.25,15.4467562 C14.6101607,15.31923 14.9964549,15.25 15.4,15.25 L19.6,15.25 C21.6469207,15.25 23.25,17.0311992 23.25,19.1666667 L23.25,20.5 C23.25,21.1903559 22.6903559,21.75 22,21.75 C21.3096441,21.75 20.75,21.1903559 20.75,20.5 L20.75,19.1666667 C20.75,18.3566155 20.2040461,17.75 19.6,17.75 L15.4,17.75 C14.7959539,17.75 14.25,18.3566155 14.25,19.1666667 L14.25,20.5 C14.25,21.1903559 13.6903559,21.75 13,21.75 C12.3096441,21.75 11.75,21.1903559 11.75,20.5 L11.75,15.1666667 C11.75,14.4122511 11.0215238,13.75 10.0666667,13.75 L4.93333333,13.75 C3.97847619,13.75 3.25,14.4122511 3.25,15.1666667 L3.25,20.5 C3.25,21.1903559 2.69035594,21.75 2,21.75 C1.30964406,21.75 0.75,21.1903559 0.75,20.5 L0.75,15.1666667 C0.75,12.9755636 2.64811994,11.25 4.93333333,11.25 L10.0666667,11.25 C12.3518801,11.25 14.25,12.9755636 14.25,15.1666667 L14.25,15.4467562 Z M17.5,14.25 C15.7050746,14.25 14.25,12.7949254 14.25,11 C14.25,9.20507456 15.7050746,7.75 17.5,7.75 C19.2949254,7.75 20.75,9.20507456 20.75,11 C20.75,12.7949254 19.2949254,14.25 17.5,14.25 Z M17.5,11.75 C17.9142136,11.75 18.25,11.4142136 18.25,11 C18.25,10.5857864 17.9142136,10.25 17.5,10.25 C17.0857864,10.25 16.75,10.5857864 16.75,11 C16.75,11.4142136 17.0857864,11.75 17.5,11.75 Z M7.5,9.75 C5.42893219,9.75 3.75,8.07106781 3.75,6 C3.75,3.92893219 5.42893219,2.25 7.5,2.25 C9.57106781,2.25 11.25,3.92893219 11.25,6 C11.25,8.07106781 9.57106781,9.75 7.5,9.75 Z M7.5,7.25 C8.19035594,7.25 8.75,6.69035594 8.75,6 C8.75,5.30964406 8.19035594,4.75 7.5,4.75 C6.80964406,4.75 6.25,5.30964406 6.25,6 C6.25,6.69035594 6.80964406,7.25 7.5,7.25 Z" />
  </SvgIcon>
  );