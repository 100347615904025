import React from 'react';
import { noop } from 'lodash';
import { cx } from 'emotion';
import { bubbleRowStyle, bubbleStyle, iconContainerStyle, imageStyle } from './text-bubble.styles';
import { Body } from '../../foundation/typography/typography.component';
import { Tooltip, TooltipAnchorPositions } from '../tooltip/tooltip.component';
import { Icons, Svg } from '../../svgs/svg.component';

interface Props {
  anchor?: TooltipAnchorPositions;
  className?: string;
  color?: 'blue' | 'gray1' | 'blueDark' | 'green';
  position?: 'left' | 'right';
  hoverText?: string;
  img?: React.ReactNode;
  text: string;
  onClick?: () => void;
  title?: string;
  icons?: { icon: Icons; onClick: () => void }[];
}

/**
 * @deprecated not replaced in design-system
 */
export const TextBubble = ({
  anchor,
  text = '',
  color = 'gray1',
  position = 'left',
  img,
  hoverText,
  title,
  icons,
  className,
  onClick = noop,
}: Props) => {
  let tipAnchor = position === 'left' ? 0 : 2;
  if (typeof anchor === 'number') {
    tipAnchor = anchor;
  }
  return (
    <div className={cx(bubbleRowStyle, position, className)} onClick={onClick} title={title}>
      {img && <div className={imageStyle}>{img}</div>}
      <div className={cx(bubbleStyle, color)}>
        {text.split('\n').map((txt, i) => (
          <React.Fragment key={`txt-bubble_${i}`}>
            {hoverText ? (
              <Tooltip tip={hoverText} anchor={tipAnchor}>
                <Body className="default">{txt}</Body>
              </Tooltip>
            ) : (
              <Body className="white">{txt}</Body>
            )}
          </React.Fragment>
        ))}
      </div>
      {icons && (
        <div className={iconContainerStyle}>
          {icons.map((icon, k) => (
            <Svg key={k} {...icon} className="text-bubble_icon interactive sm gray" />
          ))}
        </div>
      )}
    </div>
  );
};
