import { cx } from 'emotion';
import * as React from 'react';
import * as styles from './tabs.styles';
import { get } from 'lodash';

interface TabsProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactElement<TabProps>[];
  activeTab?: number;
  onActiveTabChange?: (
    activeTabIndex: number,
    activeTab: React.ReactElement<TabProps>
  ) => void;
  variant?: 'standard' | 'fullWidth';
}

interface TabProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string | React.ReactNode;
}

/**
 * @deprecated Replaced by Tabs, TabNav, and TabFilter in design-system
 */
export const Tabs: React.FunctionComponent<TabsProps> = ({
  activeTab,
  onActiveTabChange,
  variant = 'standard',
  children,
  ...props
}) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(activeTab || 0);
  const [activeTabWidth, setActiveTabWidth] = React.useState<number>();
  const [activeTabOffset, setActiveTabOffset] = React.useState<number>();
  const activeTabRef = React.useRef(null);

  if (activeTab !== undefined && activeTab !== activeTabIndex) {
    setActiveTabIndex(activeTab);
  }

  React.useEffect(() => {
    setActiveTabWidth(get(activeTabRef, 'current.offsetWidth'));
    setActiveTabOffset(get(activeTabRef, 'current.offsetLeft'));
  }, [activeTabIndex, setActiveTabWidth, setActiveTabOffset, children]);

  const getActiveTabPosition = () => {
    return {
      width: activeTabWidth,
      left: activeTabOffset,
    };
  };

  const hasChildren = (component: React.ReactElement<TabProps>) => {
    return React.Children.toArray(component.props.children).length > 0;
  };

  const handleTabClick = (tabIndex: number) => {
    const component = children[tabIndex];
    if (hasChildren(component) && tabIndex !== activeTabIndex) {
      setActiveTabIndex(tabIndex);

      if (onActiveTabChange) {
        onActiveTabChange(tabIndex, component);
      }
    }
  };

  const renderTabs = () => {
    return React.Children.map(children, (tab: React.ReactElement<TabProps>, index) => {
      const className = cx(
        styles.tab,
        { [styles.active]: index === activeTabIndex },
        { [get(tab, 'props.className')]: !hasChildren(tab) }
      );
      return (
        <li
          ref={index === activeTabIndex ? activeTabRef : undefined}
          className={className}
          onClick={() => handleTabClick(index)}
        >
          {tab.props.label}
        </li>
      );
    });
  };

  const renderContent = () => {
    return children[activeTabIndex];
  };

  return (
    <div {...props}>
      <div className={styles.header}>
        <ul className={variant === 'fullWidth' ? styles.tabsFullWidth : styles.tabs}>
          {renderTabs()}
        </ul>
        <div className={styles.activeTabBorder} style={getActiveTabPosition()} />
      </div>
      {renderContent()}
    </div>
  );
};

export const Tab: React.FunctionComponent<TabProps> = ({
  label,
  className,
  children,
  ...props
}) => {
  return (
    <div {...props} className={cx(styles.content, className)}>
      {children}
    </div>
  );
};
