import { css } from 'emotion';

export const radioGroup = css`
  margin-top: 15px;

  & > div {
    display: flex;
    align-items: flex-start;
    margin: 5px 0;
  }

  &.horizontal > div {
    display: inline-block;
    margin-right: 15px;
    text-align: center;
  }

  input[type='radio'] + label {
    cursor: pointer;
  }

  input {
    display: inline-block;
    vertical-align: middle;
  }

  input[type='radio'] {
    margin-right: 10px !important;
  }

  .relative-checkbox {
    margin-right: 10px;
  }

  &.horizontal .relative-checkbox {
    display: inline-block;
  }

  label: radioGroup;
`;
