import { weaveRed, gray3, gray5 } from '../../colors/colors';
import { jubcss } from '../../../helpers/jub-css';

export const groupJub = jubcss(`
  font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
  position: relative !important;
  label {
    position: absolute !important;
    padding: 0 4px !important;
    top: -9px !important;
    left: 8px !important;
    transition: top 0.1s ease-in, left 0.1s ease-in, font-size 0.1s ease-in, color 0.1s ease-in !important;
  }
  input[type="text"] + label,
  input[type="password"] + label {
    top: 10px !important;
    left: 13px !important;
    font-size: 14px !important;
    color: ${gray3} !important;
    cursor: text !important;
    border-radius: 4px !important;

    &.label-click-pointer {
      cursor: pointer !important;
    }

    &.active {
      color: ${gray5} !important;
      top: -8px !important;
      left: 8px !important;
      border-radius: 4px !important;
    }
  }
  input {
    margin-bottom: 0 !important;
  }
  input + .secondary-wv {
    margin-bottom: 5px !important;
  }
  .error-style + span.secondary-wv {
    color: ${weaveRed} !important;
  }
  label: group;
`);

export const groupIconLeftJub = jubcss(`
  input[type="text"] + label {
    left: 33px !important;
  }
`);
