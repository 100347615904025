import { css } from 'emotion';
import { gray1, gray3, gray9 } from '../../foundation/colors/colors';
import { z1 } from '../../helpers/storybook-helpers/dimensions';

export const toggleWrapper = css`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 20px;
  background-color: ${gray1};
  height: 35px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .subtitle-wv {
    transition: color 0.1s ease-in;
    line-height: 35px;
    &:hover {
      color: ${gray9};
    }
  }

  &.md {
    height: 25px;
    .toggle-option {
      padding: 0 2px;
    }
    .subtitle-wv {
      font-size: 13px;
      line-height: 25px;
    }
  }

  .toggle-option {
    display: flex;
    flex: 1;
    z-index: ${z1};
    border-radius: 20px;
    height: 100%;
    justify-content: center;
    text-align: center;
    padding: 0 5px;

    &.active .subtitle-wv {
      color: ${gray3};
    }
  }

  & .active-toggle {
    position: absolute;
    border-radius: 20px;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
    background-color: ${gray9};
    transition: left 0.3s ease;
  }

  label: toggleStyles;
`;
