/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const AssignUserIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M10.7189941,14.2508545 C12.5247803,14.25 12.5247803,16.75 10.7189941,16.7508545 L7.5,16.75 C5.70507456,16.75 4.25,18.2050746 4.25,20 L4.25,22.25 C4.25,22.9403559 3.69035594,23.5 3,23.5 C2.30964406,23.5 1.75,22.9403559 1.75,22.25 L1.75,20 C1.75,16.8243627 4.32436269,14.25 7.5,14.25 L10.7189941,14.2508545 Z M21.9926407,16.3786797 C22.4807961,16.866835 22.4807961,17.6582912 21.9926407,18.1464466 L17.3964466,22.7426407 C16.938801,23.2002863 16.2145823,23.2288892 15.723615,22.8284492 L15.6286797,22.7426407 L13.1538059,20.267767 C12.6656506,19.7796116 12.6656506,18.9881554 13.1538059,18.5 C13.6419613,18.0118446 14.4334175,18.0118446 14.9215729,18.5 L16.5125631,20.089576 L20.2248737,16.3786797 C20.7130291,15.8905243 21.5044853,15.8905243 21.9926407,16.3786797 Z M12,0.75 C15.1756373,0.75 17.75,3.32436269 17.75,6.5 C17.75,9.67563731 15.1756373,12.25 12,12.25 C8.82436269,12.25 6.25,9.67563731 6.25,6.5 C6.25,3.32436269 8.82436269,0.75 12,0.75 Z M12,3.25 C10.2050746,3.25 8.75,4.70507456 8.75,6.5 C8.75,8.29492544 10.2050746,9.75 12,9.75 C13.7949254,9.75 15.25,8.29492544 15.25,6.5 C15.25,4.70507456 13.7949254,3.25 12,3.25 Z" />
  </SvgIcon>
  );