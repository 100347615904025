import React from 'react';
import { Heading } from '../heading';
import { useModalContext } from './use-modal-context';
import { css } from '@emotion/core';
import { WeaveTheme } from 'dsm/theme';

type Props = {
  title?: string;
};

export const FullscreenModalHeader = ({ title, ...rest }: Props) => {
  const { titleId } = useModalContext();
  return (
    <header
      css={(theme: WeaveTheme) => css`
        -webkit-app-region: drag;
        height: 60px;
        border-bottom: 1px solid ${theme.colors.gray['300']};
        padding: ${theme.spacing(2, 6)};
      `}
      {...rest}
    >
      {!!title && (
        <Heading
          id={titleId}
          css={css`
            margin: 0;
          `}
          level={2}
          textAlign="center"
        >
          {title}
        </Heading>
      )}
    </header>
  );
};
