export const toNumber = (txt: string): number => {
  return Number(txt.replace('px', ''));
};

export const appWidth = '350px'; // There is a 3px border on the app.
export const appHeight = '635px';

export const innerAppWidth = '344px'; // 3px + 344px + 3px = 350px
export const innerAppHeight = '629px'; // 3px + 24px + 605px + 3px = 635px

export const headerHeight = '24px'; // This is the top-most header (has weave logo on the left and _ X on the right)
export const bodyHeight = '605px'; // appheight = headerHeight + bodyHeight + top-bottom border

// export const largeBodyHeaderHeight = '75px';
// export const smallBodyContentHeight = '530px';

export const bodyHeaderHeight = '50px'; // Visually this is below the appHead & above bodyContent
export const bodyContentHeight = '555px'; // bodyHeaderHeight + bodyContentHeight = bodyHeight

export const bodyContentTop = toNumber(headerHeight) + toNumber(bodyHeaderHeight);

// export const smallBodyHeaderHeight = '25px';
// export const largeBodyContentHeight = '580px';

export const z1 = '10';
export const z2 = '20';
export const z3 = '30';
export const z4 = '40';
