/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const SyncIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M4.18707423,7.91069401 C6.93560671,3.86986206 8.94999835,2.25 12.4605847,2.25 C14.7435349,2.25 16.2434279,2.81179499 17.6852725,4.19612845 C18.5040004,4.98219963 18.6382685,5.15357848 20.9605847,8.25 C21.3747982,8.80228475 21.2628694,9.58578644 20.7105847,10 C20.1582999,10.4142136 19.3747982,10.3022847 18.9605847,9.75 C16.7747459,6.83554827 16.6233355,6.64228914 15.9538371,5.9994951 C14.9940659,5.07800648 14.1183467,4.75 12.4605847,4.75 C10.0556977,4.75 8.60507174,5.88513852 6.34826915,9.17876315 L6.76671223,9.08215795 C7.43937439,8.92686165 8.11056733,9.34626955 8.26586363,10.0189317 C8.42115993,10.6915939 8.00175203,11.3627868 7.32908987,11.5180831 L3.91879464,12.3054118 C3.24613248,12.4607081 2.57493954,12.0413002 2.41964324,11.368638 L1.63231455,7.95834281 C1.47701825,7.28568065 1.89642615,6.61448771 2.56908831,6.45919141 C3.24175047,6.30389512 3.91294342,6.72330302 4.06823971,7.39596518 L4.18707423,7.91069401 Z M19.7340951,16.0665805 C16.9855626,20.1074125 14.971171,21.7272745 11.4605847,21.7272745 C9.17763448,21.7272745 7.67774139,21.1654795 6.23589683,19.7811461 C5.4171689,18.9950749 5.28290081,18.823696 2.96058467,15.7272745 C2.54637111,15.1749898 2.65829992,14.3914881 3.21058467,13.9772745 C3.76286942,13.563061 4.54637111,13.6749898 4.96058467,14.2272745 C7.14642346,17.1417263 7.29783383,17.3349854 7.96733228,17.9777794 C8.92710346,18.899268 9.80282265,19.2272745 11.4605847,19.2272745 C13.8654716,19.2272745 15.3160976,18.092136 17.5729002,14.7985114 L17.1544571,14.8951166 C16.4817949,15.0504129 15.810602,14.631005 15.6553057,13.9583428 C15.5000094,13.2856807 15.9194173,12.6144877 16.5920795,12.4591914 L20.0023747,11.6718627 C20.3387058,11.5942146 20.6746696,11.6602425 20.9459626,11.8297652 C21.2172556,11.9992879 21.4238779,12.2723054 21.5015261,12.6086365 L22.2888548,16.0189317 C22.4441511,16.6915939 22.0247432,17.3627868 21.352081,17.5180831 C20.6794189,17.6733794 20.0082259,17.2539715 19.8529296,16.5813093 L19.7340951,16.0665805 Z" />
  </SvgIcon>
  );