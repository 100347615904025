import React from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '../theme';

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  disabled?: boolean;
};

/**
 * @deprecated import from design-system
 */
export const TextButton = ({ children, ...rest }: Props) => (
  <button
    css={(theme: WeaveTheme) => css`
      background: transparent;
      border: 2px solid transparent;
      color: ${theme.colors.gray[600]};
      cursor: pointer;
      font-family: ${theme.fontFamily};
      font-size: ${theme.fontSize(14)};
      padding: ${theme.spacing(0.5, 1)};
      transition: background 250ms ease-out, border-color 250ms ease-out;

      :hover:not(:disabled),
      :focus:not(:disabled) {
        background: ${theme.colors.gray[300]};
        border-color: ${theme.colors.gray[300]};
        outline: none;
      }

      :active:not(:disabled) {
        background: white;
        border-color: ${theme.colors.weaveBlue};
      }

      :disabled {
        color: ${theme.colors.disabled};
        cursor: default;
      }
    `}
    {...rest}
  >
    {children}
  </button>
);
