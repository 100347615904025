/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const AsteriskIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M11,9.83493649 L11,3.25 C11,2.55964406 11.5596441,2 12.25,2 C12.9403559,2 13.5,2.55964406 13.5,3.25 L13.5,9.83493649 L19.2027223,6.54246825 C19.8005881,6.19729028 20.5650761,6.40213422 20.910254,7 C21.255432,7.59786578 21.0505881,8.36235379 20.4527223,8.70753175 L14.75,12 L20.4527223,15.2924682 C21.0505881,15.6376462 21.255432,16.4021342 20.910254,17 C20.5650761,17.5978658 19.8005881,17.8027097 19.2027223,17.4575318 L13.5,14.1650635 L13.5,20.75 C13.5,21.4403559 12.9403559,22 12.25,22 C11.5596441,22 11,21.4403559 11,20.75 L11,14.1650635 L5.29727772,17.4575318 C4.69941194,17.8027097 3.93492393,17.5978658 3.58974596,17 C3.24456799,16.4021342 3.44941194,15.6376462 4.04727772,15.2924682 L9.75,12 L4.04727772,8.70753175 C3.44941194,8.36235379 3.24456799,7.59786578 3.58974596,7 C3.93492393,6.40213422 4.69941194,6.19729028 5.29727772,6.54246825 L11,9.83493649 Z" />
  </SvgIcon>
  );