/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const ImageIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M19.0270798,21.75 C19.0096483,21.7503657 18.9922357,21.7503645 18.9748533,21.75 L5.02895031,21.75 C5.0096312,21.7504524 4.99026002,21.7504546 4.97085116,21.75 L4,21.75 C2.20507456,21.75 0.75,20.2949254 0.75,18.5 L0.75,5.5 C0.75,3.70507456 2.20507456,2.25 4,2.25 L20,2.25 C21.7949254,2.25 23.25,3.70507456 23.25,5.5 L23.25,18.5 C23.25,20.2949254 21.7949254,21.75 20,21.75 L19.0270801,21.75 Z M19.7725425,19.25 L20,19.25 C20.4142136,19.25 20.75,18.9142136 20.75,18.5 L20.75,5.5 C20.75,5.08578644 20.4142136,4.75 20,4.75 L4,4.75 C3.58578644,4.75 3.25,5.08578644 3.25,5.5 L3.25,18.5 C3.25,18.9142136 3.58578644,19.25 4,19.25 L4.12524464,19.25 L6.54678369,12.6080643 C7.12731151,11.0157594 8.88874046,10.1955521 10.4810453,10.7760799 C11.3107519,11.0785771 11.9699189,11.7238615 12.2900058,12.5469422 L13.1609107,14.7864118 C13.9633344,13.5962203 15.5760979,13.2694738 16.7796291,14.0592912 C17.167195,14.3136313 17.4805473,14.6660097 17.6878617,15.0806384 L19.7725425,19.25 Z M16.9774575,19.25 L15.4517937,16.1986723 C15.4417945,16.1786741 15.426681,16.1616782 15.407988,16.1494109 C15.3494906,16.111022 15.2709488,16.1273232 15.2325599,16.1858206 C14.9491643,16.6176615 14.538136,16.9503609 14.0567308,17.137574 C12.7787859,17.6345526 11.3399261,17.0014543 10.8429475,15.7235094 L9.95999416,13.4530578 C9.90066962,13.300509 9.77850038,13.1809128 9.62472354,13.1248483 C9.32960761,13.0172539 9.00314643,13.1692702 8.89555208,13.4643861 L6.78621369,19.25 L16.9774575,19.25 Z M15.5,11.75 C13.9812169,11.75 12.75,10.5187831 12.75,9 C12.75,7.48121694 13.9812169,6.25 15.5,6.25 C17.0187831,6.25 18.25,7.48121694 18.25,9 C18.25,10.5187831 17.0187831,11.75 15.5,11.75 Z M15.5,9.25 C15.6380712,9.25 15.75,9.13807119 15.75,9 C15.75,8.86192881 15.6380712,8.75 15.5,8.75 C15.3619288,8.75 15.25,8.86192881 15.25,9 C15.25,9.13807119 15.3619288,9.25 15.5,9.25 Z" />
  </SvgIcon>
  );