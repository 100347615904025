import React from 'react';
import { cx } from 'emotion';

import { Title } from '../../foundation/typography/typography.component';
import { ListItem } from './list-item.component';
import { listStyle } from './list.styles';
export { ListItem };

interface Props {
  className?: string;
  styles?: 'borderless' | 'borderless-top' | '';
  children: React.ReactNode;
  title?: string;
}

/**
 * @deprecated Replaced by Listbox or ListField in design-system
 */
export const List = ({ className = '', title = '', styles = '', children }: Props) => {
  return (
    <div className={cx(listStyle, className, styles)}>
      {title && <Title>{title}</Title>}
      <div className="list-items-wv">{children}</div>
    </div>
  );
};
