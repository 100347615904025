/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const TrainingIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M6.65518036,22.415445 C5.92917095,23.0534335 4.82343217,22.9820785 4.18544367,22.256069 C3.90478653,21.9366907 3.75,21.5260604 3.75,21.1008887 L3.75,4.5 C3.75,2.70507456 5.20507456,1.25 7,1.25 L17,1.25 C18.7949254,1.25 20.25,2.70507456 20.25,4.5 L20.25,21.0974508 C20.25,22.0639491 19.4664983,22.8474508 18.5,22.8474508 C18.0734232,22.8474508 17.661535,22.6916415 17.3417585,22.4093107 L12.0145075,17.7058793 L6.65518036,22.415445 Z M17.75,4.5 C17.75,4.08578644 17.4142136,3.75 17,3.75 L7,3.75 C6.58578644,3.75 6.25,4.08578644 6.25,4.5 L6.25,19.4433825 L10.860492,15.3918636 C11.5223525,14.8102466 12.513411,14.8114017 13.1739139,15.3945599 L17.75,19.4347876 L17.75,4.5 Z" />
  </SvgIcon>
  );