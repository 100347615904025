/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const WellnessIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M4.93781702,1 C5.62817296,1 6.18781702,1.55964406 6.18781702,2.25 C6.18781702,2.89720869 5.69594236,3.4295339 5.06562226,3.49354639 L4.93781702,3.5 L4.70749663,3.5012674 C4.74238508,5.11296347 4.89751228,6.45163101 5.16857004,7.53421723 L12.6406258,7.53327811 C12.9707464,6.44955189 13.1960063,5.11051626 13.3126482,3.50081593 L13.0915856,3.50000297 C12.4012313,3.49849177 11.8428089,2.93762878 11.8443111,2.24727448 C11.8457253,1.60006733 12.3387595,1.06881588 12.9692177,1.0061779 L13.0970367,1.00000297 L14.6220611,1.00332817 C15.3124154,1.00483343 15.8708379,1.56569642 15.8693356,2.25605072 C15.8692315,2.30372135 15.8664602,2.3507629 15.8611633,2.39703445 C15.8687114,2.45863991 15.8709425,2.52219481 15.8682374,2.58668638 C15.5966984,9.06044774 13.6850535,12.8716194 10.0158629,13.5402397 L10.0207021,13.2679058 C9.89951795,18.237926 11.0039551,20.3887939 13.0943112,20.3887939 C15.0093664,20.3887939 16.3213103,19.3346033 17.0754826,16.9916891 C16.3007314,16.4479163 15.795361,15.5496386 15.795361,14.5333252 C15.795361,12.8764709 17.1385067,11.5333252 18.795361,11.5333252 C20.4522152,11.5333252 21.795361,12.8764709 21.795361,14.5333252 C21.795361,15.9314764 20.8389098,17.1062364 19.5446067,17.4390061 C18.4870321,20.9787323 16.3187603,22.8887939 13.0943112,22.8887939 C9.22855023,22.8887939 7.44655153,19.553472 7.5168321,13.528197 C3.88536233,12.8258994 2.15774928,8.98481188 2.20078422,2.52597325 L2.20808923,2.39821384 L2.20808923,2.39821384 L2.21181458,2.37780524 C2.20754708,2.3357839 2.20536097,2.29314725 2.20536097,2.25 C2.20536097,1.60279131 2.69723563,1.0704661 3.32755573,1.00645361 L3.45536097,1 L4.93781702,1 Z M11.7310327,9.53477286 L5.96550345,9.53419745 C6.64606557,10.6296822 7.59461569,11.1463623 8.79536097,11.1463623 C10.005162,11.1463623 10.9882435,10.6276556 11.7310327,9.53477286 Z" />
  </SvgIcon>
  );