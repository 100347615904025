import { css } from 'emotion';
import { weaveRed, gray3, gray5 } from '../../colors/colors';

export const group = css`
  position: relative;
  label {
    position: absolute;
    padding: 0 2px;
    background-color: #00000000;
    top: -9px;
    left: 8px;
    font-size: 14px;
    color: ${gray5};
    transition: top 0.1s ease-in, left 0.1s ease-in, font-size 0.1s ease-in, color 0.1s ease-in;
  }
  input[type='text'] + label,
  input[type='password'] + label {
    top: 10px;
    left: 13px;
    color: ${gray3};
    cursor: text;
    border-radius: 4px;

    &.label-click-pointer {
      cursor: pointer;
    }

    &.active {
      color: ${gray5};
      top: -8px;
      left: 8px;
      border-radius: 4px;
    }
  }
  input {
    margin-bottom: 0;
  }
  input + .secondary-wv {
    margin-bottom: 5px;
  }
  .error-style + span.secondary-wv {
    color: ${weaveRed};
  }
  label: group;
`;

export const groupIconLeft = css`
  input[type='text'] + label {
    left: 33px;
  }
`;
