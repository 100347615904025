/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const XIconSmall: React.FC<SvgIconProps> = props => (
  <SvgIcon size={16} {...props} viewBox="0 0 16 16">
    <path d="M9.41421356,8 L13.7071068,12.2928932 C14.0976311,12.6834175 14.0976311,13.3165825 13.7071068,13.7071068 C13.3165825,14.0976311 12.6834175,14.0976311 12.2928932,13.7071068 L8,9.41421356 L3.70710678,13.7071068 C3.31658249,14.0976311 2.68341751,14.0976311 2.29289322,13.7071068 C1.90236893,13.3165825 1.90236893,12.6834175 2.29289322,12.2928932 L6.58578644,8 L2.29289322,3.70710678 C1.90236893,3.31658249 1.90236893,2.68341751 2.29289322,2.29289322 C2.68341751,1.90236893 3.31658249,1.90236893 3.70710678,2.29289322 L8,6.58578644 L12.2928932,2.29289322 C12.6834175,1.90236893 13.3165825,1.90236893 13.7071068,2.29289322 C14.0976311,2.68341751 14.0976311,3.31658249 13.7071068,3.70710678 L9.41421356,8 Z" />
  </SvgIcon>
  );