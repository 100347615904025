import { jubcss } from '../../helpers/jub-css';

export const toggleWrapperJub = jubcss(`
  font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;  

  .subtitle-wv {
    line-height: 35px !important;
  }

  &.md {
    .subtitle-wv {
      line-height: 25px !important;
    }
  }

  label: toggleStyles;
`);
