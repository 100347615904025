import { jubcss } from '../../../helpers/jub-css';

export const radioGroupJub = jubcss(`
  font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
  margin-top: 15px !important;

  & > div {
    display: flex;
    align-items: flex-start;
    margin: 5px 0;
  }

  &.horizontal > div {
    display: inline-block !important;
    margin-right: 15px !important;
    text-align: center !important;
  }

  input[type="radio"] + label {
    cursor: pointer !important;
  }

  input {
    display: inline-block !important;
    margin-top: 0 !important;
    vertical-align: middle !important;
  }

  input[type="radio"] {
    margin-right: 10px !important;
  }

  .relative-checkbox {
    margin-right: 10px !important;
  }
  
  label: radioGroup;
`);
