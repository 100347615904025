/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const OptOutIconSmall: React.FC<SvgIconProps> = props => (
  <SvgIcon size={16} {...props} viewBox="0 0 16 16">
    <path d="M8,0.5 C12.418278,0.5 16,3.73407999 16,7.72351983 C16,11.7129597 12.418278,14.9470397 8,14.9470397 C6.88751069,14.9470397 6.52058919,14.7757103 5.16202799,14.5194658 C3.8034668,14.2632213 1.0164388,15.9250189 0.45711263,15.3949825 C-0.102213542,14.8649462 1.18351237,11.8660215 0.665987527,10.6135841 C0.148462685,9.36114667 0,8.75110965 0,7.72351983 C0,3.73407999 3.581722,0.5 8,0.5 Z M5.37652416,4.45875721 C5.08363094,4.16586399 4.60875721,4.16586399 4.31586399,4.45875721 C4.02297077,4.75165043 4.02297077,5.22652416 4.31586399,5.51941738 L4.31586399,5.51941738 L6.79,7.994 L4.31586399,10.4691649 C4.04959743,10.7354314 4.02539138,11.1520951 4.24324584,11.4457066 L4.31586399,11.529825 C4.60875721,11.8227182 5.08363094,11.8227182 5.37652416,11.529825 L5.37652416,11.529825 L7.851,9.054 L10.3262716,11.529825 C10.5925382,11.7960916 11.0092019,11.8202976 11.3028134,11.6024432 L11.3869318,11.529825 C11.679825,11.2369318 11.679825,10.7620581 11.3869318,10.4691649 L11.3869318,10.4691649 L8.911,7.994 L11.3869318,5.51941738 C11.6531984,5.25315082 11.6774044,4.83648714 11.45955,4.54287564 L11.3869318,4.45875721 C11.0940386,4.16586399 10.6191649,4.16586399 10.3262716,4.45875721 L10.3262716,4.45875721 L7.851,6.933 Z" />
  </SvgIcon>
  );