import { useEffect, useRef } from 'react';

/**
 * @deprecated not replaced design-system
 */
export function useEventListener(
  eventName: string,
  handler: Function,
  element: HTMLElement | Window | Document = window
) {
  const handlerRef = useRef<Function>();

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const listener = (e) => handlerRef.current!(e);
    element.addEventListener(eventName, listener);
    return () => {
      element.removeEventListener(eventName, listener);
    };
  }, [eventName, element]);
}
