import { css } from '@emotion/core';
import { WeaveTheme } from '../dsm/theme';

export type FontColorType =
  | 'primary'
  | 'default'
  | 'light'
  | 'disabled'
  | 'white'
  | 'error'
  | 'success'
  | 'warn'
  | 'inherit';

// Maps the font DSM properties to a theme color
export const getFontColorStyle = (color: FontColorType, theme: WeaveTheme) => {
  const COLOR_MAP: { [key in FontColorType]: string } = {
    primary: theme.colors.weaveBlue,
    default: theme.colors.gray[600],
    light: theme.colors.gray[500],
    disabled: theme.colors.gray[400],
    white: theme.colors.white,
    error: theme.colors.error,
    success: theme.colors.success,
    warn: theme.colors.warning,
    inherit: 'inherit',
  };

  return css`
    color: ${COLOR_MAP[color] || COLOR_MAP.default};
  `;
};
