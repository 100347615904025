/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const BackIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M5.25304833,10.732233 L21.782233,10.732233 C22.472589,10.732233 23.032233,11.2918771 23.032233,11.982233 C23.032233,12.672589 22.472589,13.232233 21.782233,13.232233 L5.28248558,13.232233 L9.8513979,17.8011454 C10.3395533,18.2893007 10.3395533,19.080757 9.8513979,19.5689123 C9.36324253,20.0570677 8.57178631,20.0570677 8.08363094,19.5689123 L1.36611652,12.8513979 C1.12203884,12.6073202 1,12.2874173 1,11.9675144 C1,11.6476115 1.12203884,11.3277086 1.36611652,11.0836309 L8.08363094,4.36611652 C8.57178631,3.87796116 9.36324253,3.87796116 9.8513979,4.36611652 C10.3395533,4.85427189 10.3395533,5.64572811 9.8513979,6.13388348 L5.25304833,10.732233 Z" />
  </SvgIcon>
  );