import { cx } from 'emotion';
import React from 'react';
import ReactModal from 'react-modal';

import { Headline } from '../../foundation/typography/typography.component';
import { modal, modalBackdrop, modalCard, modalHeader, modalBody } from './modal.styles';

export interface ModalProps {
  className?: string;
  overlayClassName?: string;
  onRequestClose: () => void;
  children: React.ReactNode;
  title?: string;
  addPadding?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  isOpen: boolean;
  parentSelector?: HTMLElement;
  style?: { [key: string]: string | number };
}

// ReactModal.setAppElement('#client-app'); This breaks storybook
/**
 * @deprecated Replaced by Modal and useModalControl in design-system
 */
export const Modal = ({
  className = '',
  overlayClassName = '',
  onRequestClose,
  children,
  title = '',
  isOpen,
  addPadding,
  shouldCloseOnOverlayClick = true,
  parentSelector,
  style = {},
}: ModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      className={`react-modal ${modal} ${className}`}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={cx('react-modal-backdrop', modalBackdrop, overlayClassName)}
      portalClassName="react-modal-portal"
      parentSelector={parentSelector}
      style={style}
    >
      <div className={`react-modal-card ${modalCard} ${addPadding ? 'add-padding' : ''}`}>
        <div className={`react-modal-header ${title ? modalHeader : ''}`}>
          <Headline>{title || ''}</Headline>
        </div>
        <div className={`react-modal-body ${modalBody}`}>{children}</div>
      </div>
    </ReactModal>
  );
};
