/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const AwardIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M14.4709778,14.8180197 C13.6997995,15.0975673 12.867701,15.25 12,15.25 C11.1322268,15.25 10.300062,15.097542 9.5288297,14.8179499 L8.17829102,20.0155369 L10.9124023,18.5059171 C11.5892782,18.1321851 12.4106387,18.1321851 13.0875145,18.5059171 L15.8199422,20.0146072 L14.4709778,14.8180197 Z M16.7033603,13.5175112 C16.7258609,13.5714849 16.7448428,13.6276911 16.7599599,13.6859262 L18.7173807,21.2336854 C18.9180806,22.2234097 18.2040639,23.0892668 17.2537196,23.2299733 C16.8573016,23.2886663 16.4486293,23.2174958 16.0971216,23.0234133 L11.9999584,20.7611944 L7.90279527,23.0234133 C7.1310551,23.4495241 6.1251456,23.2647805 5.58586045,22.5516527 C5.30179678,22.1760189 5.18810454,21.6993621 5.29770195,21.1680349 L7.23995693,13.6859262 C7.25507888,13.6276723 7.27406818,13.5714486 7.29657834,13.5174589 C5.73814926,12.1876802 4.75,10.2092374 4.75,8 C4.75,3.99593556 7.99593556,0.75 12,0.75 C16.0040644,0.75 19.25,3.99593556 19.25,8 C19.25,10.2092664 18.2618248,12.1877321 16.7033603,13.5175112 Z M12,12.75 C14.6233526,12.75 16.75,10.6233526 16.75,8 C16.75,5.37664744 14.6233526,3.25 12,3.25 C9.37664744,3.25 7.25,5.37664744 7.25,8 C7.25,10.6233526 9.37664744,12.75 12,12.75 Z" />
  </SvgIcon>
  );