import { css } from 'emotion';
import { gray1, gray3 } from '../../foundation/colors/colors';

export const attributesStyle = css`
  text-align: left;
  vertical-align: middle;

  label: attributesStyle;
`;

export const capitalize = css`
  text-transform: capitalize;
`;

export const right = css`
  padding-top: 2px;
  float: right;
`;

export const attrStyle = css`
  display: block;
  text-align: left;
  height: 32px;
  overflow: hidden;
  padding: 5px;
  border-bottom: 1px solid ${gray3};

  &.click:hover {
    transition: background-color 0.2s ease-in;
    background-color: ${gray1};
    cursor: pointer;
  }

  label: attrStyle;
`;
