import { css } from 'emotion';
import {
  gray1,
  gray5,
  gray7,
  gray9,
  weaveBlue,
  weaveBlueDark,
  weaveBlueLight,
  weaveRed,
  weaveGreen,
  gray3,
  gray4,
} from '../colors/colors';

export const buttonFont = css`
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 400;
`;

const colorMap = {
  blue: weaveBlue,
  green: weaveGreen,
  gray: gray3,
  red: weaveRed,
};

const fillOptions = (fill, color, disabled) => {
  if (fill === 'outline') {
    return css`
      border: 2px solid ${color === 'gray' ? colorMap[color] + '99' : colorMap[color]};
      background: transparent;
      color: ${color === 'gray' ? gray4 : colorMap[color]};
      ${disabled
        ? `cursor: not-allowed;
        border-color: ${colorMap[color]}99;
        
        `
        : `&:hover {
          color: white;
          background-color: ${colorMap[color]};
        }
        `}
    `;
  } else {
    return css`
      border: none;
      background-color: ${colorMap[color]};
      color: white;
      ${disabled
        ? `cursor: not-allowed;
          background-color: ${colorMap[color]}99;  
        `
        : `&:hover {
          background-color: ${colorMap[color]}99;
        }`}
    `;
  }
};

const sizeOptions = (size) => {
  if (size === 'small') {
    return css`
      height: 28px;
      padding: 0 16px;
      font-size: 15px;
    `;
  } else if (size === 'large') {
    return css`
      height: 56px;
      padding: 10px 24px;
      font-size: 17px;
    `;
  } else {
    return css`
      height: 36px;
      padding: 0 24px;
      font-size: 17px;
    `;
  }
};

export const buttonStyle = (props) => {
  const { color, fill, variant, fullWidth, size, disabled } = props;
  return css`
    ${buttonFont};
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: ${variant === 'rounded' ? '60px' : '0px'};
    cursor: pointer;
    transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in;
    ${fullWidth ? `width:100%;` : `width:inherit;`}
    ${sizeOptions(size)}
    ${fillOptions(fill, color, disabled)}

    &:active, &:focus {
      outline: 0;
    }

    label: buttonStyle;
  `;
};

export const wizardButtonStyle = css`
  font-family: 'Proxima Nova', sans-serif;
  width: 100%;
  box-sizing: border-box;
  height: 56px;
  border: none;
  color: white;
  font-size: 17px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: ${weaveBlue};
  &.hover,
  &:hover {
    background-color: ${weaveBlueLight};
  }
  &.active,
  &:active {
    background-color: ${weaveBlueDark};
  }
  &.red {
    background-color: ${weaveRed};
    &.hover,
    &:hover {
      background-color: ${gray5};
    }
    &.active,
    &:active {
      background-color: ${gray7};
    }
  }
  &.gray5 {
    background-color: ${gray5};
    &.hover,
    &:hover {
      background-color: ${gray7};
    }
    &.active,
    &:active {
      background-color: ${gray9};
    }
  }
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background-color: ${gray1};
  }
  label: next-step-button;
`;
