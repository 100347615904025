/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const AutomatedRemindersIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M3.25,7.25 L16.75,7.25 L16.75,5.75 C16.75,5.33578644 16.4142136,5 16,5 L14.5,5 L14.5,5.5 C14.5,6.19035594 13.9403559,6.75 13.25,6.75 C12.5596441,6.75 12,6.19035594 12,5.5 L12,5 L8,5 L8,5.5 C8,6.19035594 7.44035594,6.75 6.75,6.75 C6.05964406,6.75 5.5,6.19035594 5.5,5.5 L5.5,5 L4,5 C3.58578644,5 3.25,5.33578644 3.25,5.75 L3.25,7.25 Z M3.25,9.75 L3.25,17.75 C3.25,18.1642136 3.58578644,18.5 4,18.5 L8.68225098,18.5 C9.37260691,18.5 9.93225098,19.0596441 9.93225098,19.75 C9.93225098,20.4403559 9.37260691,21 8.68225098,21 L4,21 C2.20507456,21 0.75,19.5449254 0.75,17.75 L0.75,5.75 C0.75,3.95507456 2.20507456,2.5 4,2.5 L5.5,2.5 L5.5,2 C5.5,1.30964406 6.05964406,0.75 6.75,0.75 C7.44035594,0.75 8,1.30964406 8,2 L8,2.5 L12,2.5 L12,2 C12,1.30964406 12.5596441,0.75 13.25,0.75 C13.9403559,0.75 14.5,1.30964406 14.5,2 L14.5,2.5 L16,2.5 C17.7949254,2.5 19.25,3.95507456 19.25,5.75 L19.25,8.49287415 C19.25,8.49406255 19.2499983,8.49525056 19.249995,8.49643819 C19.2499983,8.49762358 19.25,8.4988116 19.25,8.5 C19.25,9.19035594 18.6903559,9.75 18,9.75 L3.25,9.75 Z M17.5,17.0830304 L18.7541599,17.6678548 C19.2546999,17.9012604 19.471255,18.4962409 19.2378494,18.9967808 C19.0044438,19.4973208 18.4094633,19.713876 17.9089234,19.4804704 L16.0963078,18.6352338 C16.0100413,18.5950071 15.9322101,18.5440403 15.8636639,18.484668 C15.6415565,18.3012515 15.5,18.023767 15.5,17.7132202 L15.5,14.7132202 C15.5,14.1609355 15.9477153,13.7132202 16.5,13.7132202 C17.0522847,13.7132202 17.5,14.1609355 17.5,14.7132202 L17.5,17.0830304 Z M16.75,23.25 C13.2982203,23.25 10.5,20.4517797 10.5,17 C10.5,13.5482203 13.2982203,10.75 16.75,10.75 C20.2017797,10.75 23,13.5482203 23,17 C23,20.4517797 20.2017797,23.25 16.75,23.25 Z M16.75,20.75 C18.8210678,20.75 20.5,19.0710678 20.5,17 C20.5,14.9289322 18.8210678,13.25 16.75,13.25 C14.6789322,13.25 13,14.9289322 13,17 C13,19.0710678 14.6789322,20.75 16.75,20.75 Z" />
  </SvgIcon>
  );