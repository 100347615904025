/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const ArchiveIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M20,1.75 C21.190864,1.75 22.1656449,2.67516159 22.2448092,3.84595119 L22.25,4 L22.25,20 C22.25,21.2426407 21.2426407,22.25 20,22.25 L20,22.25 L4,22.25 C2.75735931,22.25 1.75,21.2426407 1.75,20 L1.75,20 L1.75,4 C1.75,2.80913601 2.67516159,1.83435508 3.84595119,1.75519081 L4,1.75 L20,1.75 Z M19.75,8.25 L4.25,8.25 L4.25,19.75 L19.75,19.75 L19.75,8.25 Z M12.0073593,9 C12.6977153,9 13.2573593,9.55964406 13.2573593,10.25 L13.257,14.864 L15.0052038,13.1161165 C15.4933592,12.6279612 16.2848154,12.6279612 16.7729708,13.1161165 C17.2611261,13.6042719 17.2611261,14.3957281 16.7729708,14.8838835 L12.8838835,18.7729708 L12.8838835,18.7729708 C12.6886213,18.9682329 12.444831,19.0853902 12.1913351,19.1244426 L12.0639581,19.1374601 L12.0639581,19.1374601 L11.9360419,19.1374601 C11.6377804,19.1222731 11.3439224,19.0007766 11.1161165,18.7729708 L7.22702923,14.8838835 C6.73887386,14.3957281 6.73887386,13.6042719 7.22702923,13.1161165 C7.71518459,12.6279612 8.50664082,12.6279612 8.99479618,13.1161165 L10.757,14.879 L10.7573593,10.25 C10.7573593,9.55964406 11.3170034,9 12.0073593,9 Z M19.75,4.25 L4.25,4.25 L4.25,5.75 L19.75,5.75 L19.75,4.25 Z" />
  </SvgIcon>
  );