import { css } from 'emotion';
import {
  weaveGreen,
  weaveRed,
  weaveBlue,
  weaveYellow,
  gray3,
  gray5,
} from '../../foundation/colors/colors';

export const reactAlert = css`
  display: flex;
  align-items: center;
  text-align: left;
  background-color: white;
  border-left: 5px solid grey;
  margin: 10px auto;
  width: 90%;
  border-radius: 6px;
  padding: 10px;
  color: ${gray3};
  box-shadow: 0 3px 10px ${gray3};
  text-align: left;
  z-index: 100;

  label: react-alert;
`;
export const inlineStyle = css`
  display: inline-block;
  color: ${gray5};
`;
export const spanClass = css`
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-weight: 800;
  background-color: ${gray3};
  color: white;
  margin-right: 8px;

  label: spanClass;
`;

export const successClass = css`
  border-color: ${weaveGreen};
  span {
    background-color: ${weaveGreen};
  }
  label: success-class;
`;

export const warningClass = css`
  border-color: ${weaveYellow};
  span {
    background-color: ${weaveYellow};
  }
  label: warning-class;
`;

export const infoClass = css`
  border-color: ${weaveBlue};
  span {
    background-color: ${weaveBlue};
  }
  label: info-class;
`;

export const dangerClass = css`
  border-color: ${weaveRed};
  span {
    background-color: ${weaveRed};
  }
  label: danger-class;
`;

export const alertModal = css`
  top: 10px;
  left: 10px;
  right: 10px;
  max-height: 200px;
  max-width: 100%;
  overflow: hidden;

  label: alertModal;
`;

export const overlay = {
  // Used as a style prop.
  top: '10px',
  left: '0',
  right: '0',
  bottom: 'inherit',
  backgroundColor: 'transparent',
};
