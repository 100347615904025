import moment from 'moment';

export const supportedLocales = [{ locale: 'en', name: 'english' }];

export const roundMinutesToQuarters = (date: moment.Moment): moment.Moment => {
  const newDate = date.clone();
  let minute = date.minute(); // 0-59

  if (minute >= 1 && minute < 15) {
    minute = 15;
  } else if (minute > 15 && minute < 30) {
    minute = 30;
  } else if (minute > 30 && minute < 45) {
    minute = 45;
  } else if (minute > 45 && minute <= 59) {
    const hour = date.hour() + 1;
    newDate.hour(hour);
    minute = 0;
  }

  newDate.minute(minute);
  return newDate;
};

export const getMeridiem = (date: moment.Moment): string => {
  return date.format('a');
};

export const setMeridiem = (date: moment.Moment, meridiem: string, locale: 'en' = 'en'): moment.Moment => {
  const newDate = date.clone();

  let newHour = date.hour();
  if (locale === 'en') {
    if (meridiem === 'am' && date.hour() >= 12) {
      newHour = date.hour() - 12;
    } else if (meridiem === 'pm' && date.hour() < 12) {
      newHour = date.hour() + 12;
    }
  } else {
    if (meridiem === 'am' && date.hour() >= 12) {
      newHour = date.hour() - 12;
    } else if (meridiem === 'pm' && date.hour() < 12) {
      newHour = date.hour() + 12;
    }
  }

  return newDate.hour(newHour);
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const sequenceGenerator = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
