/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const FolderPlusIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M13,12.25 L14.5,12.25 C15.1903559,12.25 15.75,12.8096441 15.75,13.5 C15.75,14.1903559 15.1903559,14.75 14.5,14.75 L13,14.75 L13,16.25 C13,16.9403559 12.4403559,17.5 11.75,17.5 C11.0596441,17.5 10.5,16.9403559 10.5,16.25 L10.5,14.75 L9,14.75 C8.30964406,14.75 7.75,14.1903559 7.75,13.5 C7.75,12.8096441 8.30964406,12.25 9,12.25 L10.5,12.25 L10.5,10.75 C10.5,10.0596441 11.0596441,9.5 11.75,9.5 C12.4403559,9.5 13,10.0596441 13,10.75 L13,12.25 Z M12.6689797,5.75 L20,5.75 C21.2426407,5.75 22.25,6.75735931 22.25,8 L22.25,19 C22.25,20.2426407 21.2426407,21.25 20,21.25 L4,21.25 C2.75735931,21.25 1.75,20.2426407 1.75,19 L1.75,5 C1.75,3.75735931 2.75735931,2.75 4,2.75 L9.46481624,2.75 C10.2171109,2.75 10.9196314,3.12597755 11.3369294,3.75192456 L12.6689797,5.75 Z M4.25,5.25 L4.25,18.75 L19.75,18.75 L19.75,8.25 L12.5351838,8.25 C11.7828891,8.25 11.0803686,7.87402245 10.6630706,7.24807544 L9.3310203,5.25 L4.25,5.25 Z" />
  </SvgIcon>
  );