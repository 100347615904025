import { useHint, HintProps, UseHintProps, UseHintResults } from '../use-hint';
import { useOnClickOutside } from '..';
import { Tooltip } from './tooltip.component';

type Props = Omit<UseHintProps, 'trigger'>;

type UseTooltipResults = Omit<UseHintResults, 'hintProps'> & {
  Tooltip: typeof Tooltip;
  tooltipProps: HintProps;
};

/**
 * @deprecated import from design-system
 */
export function useTooltip(props: Props = {}): UseTooltipResults {
  const { hintProps, ...rest } = useHint({ ...props, trigger: 'click' });
  useOnClickOutside({
    active: rest.visible,
    ref: hintProps.ref,
    handler: rest.hide,
    ignoreRefs: [rest.triggerProps.ref],
  });
  return { ...rest, tooltipProps: hintProps, Tooltip };
}
