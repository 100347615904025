import React from 'react';
import get from 'lodash/get';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import MUISelect from '@material-ui/core/Select';
import MUIFormControl from '@material-ui/core/FormControl';
import MUIInputLabel from '@material-ui/core/InputLabel';
import MUIOutlinedInput from '@material-ui/core/OutlinedInput';
import MUIFormHelperText from '@material-ui/core/FormHelperText';
import { MuiTheme } from '../theme';
import { CarrotDownIconSmall } from '../icon';

interface SelectProps {
  id: string;
  name: string;
  label: string;
  value?: string | number;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  onChange?: (event: any) => void;
  className?: string;
  style?: React.CSSProperties;
}

const useFormControlStyles = makeStyles({
  root: ({ labelWidth }: { labelWidth: number }) => ({
    minWidth: labelWidth + 14 + 32 + 16, // left pad, right pad, plus space for left side of icon
    marginTop: '8px',
    marginBottom: '24px',
  }),
});

const useInputStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: ({ isOpen }: { isOpen?: boolean }) => ({
      borderRadius: isOpen
        ? `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
        : `${theme.shape.borderRadius}px`,
    }),
  })
);

const useMenuStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    paper: ({ isOpen }: { isOpen?: boolean }) => ({
      borderRadius: isOpen
        ? `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`
        : `${theme.shape.borderRadius}px`,
    }),
  })
);

const useSelectStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    select: {
      paddingRight: '32px',
      '&&:focus': {
        backgroundColor: 'unset',
      },
    },
    selectMenu: {
      '& + ul': {
        padding: '0 !important',
      },
    },
    icon: ({ error, disabled }: { error?: boolean; disabled?: boolean }) => ({
      top: 'calc(50% - 8px)',
      color: disabled
        ? theme.palette.grey['300']
        : error
        ? theme.palette.error.main
        : theme.palette.grey['600'],
    }),
    iconOutlined: { right: '15px' },
  })
);

/**
 * @deprecated replaced by DropdownField from design-system
 */
export const DSMSelect: React.FC<SelectProps> = ({
  children,
  id,
  name,
  label,
  placeholder,
  fullWidth = true,
  helperText,
  error,
  disabled,
  className,
  style,
  ...props
}) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLabelWidth(get(inputLabel, ['current', 'offsetWidth'], 0));
  }, []);

  return (
    <MUIFormControl
      variant="outlined"
      classes={useFormControlStyles({ labelWidth })}
      fullWidth={fullWidth}
      className={className}
      style={style}
    >
      <MUIInputLabel
        ref={inputLabel}
        htmlFor={id}
        margin="dense"
        error={error}
        disabled={disabled}
      >
        {label}
      </MUIInputLabel>
      <MUISelect
        {...props}
        classes={useSelectStyles({ error, disabled })}
        disabled={disabled}
        input={
          <MUIOutlinedInput
            classes={useInputStyles({ isOpen })}
            labelWidth={labelWidth}
            name={name}
            id={id}
            margin="dense"
            error={error}
            disabled={disabled}
          />
        }
        IconComponent={CarrotDownIconSmall}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        MenuProps={{
          classes: useMenuStyles({ isOpen }),
          elevation: 2,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {children}
      </MUISelect>
      {helperText && (
        <MUIFormHelperText error={error} disabled={disabled}>
          {helperText}
        </MUIFormHelperText>
      )}
    </MUIFormControl>
  );
};
