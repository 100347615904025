import React from 'react';
import { PrimaryButton, SecondaryButton, ButtonBar } from '../buttons';
import { isFunction } from 'lodash';
import { css } from '@emotion/core';

type Props = {
  actionName?: string;
  cancelName?: string;
  disableAction?: boolean;
  onAction: () => void;
  onCancel?: () => void;
};

export const FullscreenModalActions = ({
  onAction,
  onCancel,
  actionName = 'Save',
  cancelName = 'Cancel',
  disableAction,
}: Props) => (
  <ButtonBar
    css={css`
      position: relative;
    `}
  >
    {isFunction(onCancel) && (
      <SecondaryButton onClick={onCancel}>{cancelName}</SecondaryButton>
    )}
    <PrimaryButton onClick={onAction} disabled={disableAction}>
      {actionName}
    </PrimaryButton>
  </ButtonBar>
);
