import { cx, css } from 'emotion';
import React from 'react';
import ReactModal from 'react-modal';
import {
  alertModal,
  dangerClass,
  infoClass,
  reactAlert,
  spanClass,
  successClass,
  warningClass,
  overlay,
  inlineStyle,
} from './alert.styles';
import { Subtitle } from '../../foundation';
if (window.document.getElementById('frame')) {
  ReactModal.setAppElement('#frame');
}
type Type = 'success' | 'warning' | 'info' | 'danger';

export interface AlertProps {
  type?: Type;
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
  closeDelay?: number | false;
  position?: 'inline' | '';
  anchorElementById: 'weave-react-modal' | string;
  show?: boolean;
}

interface State {
  display: boolean;
}

/**
 * @deprecated Use Alert and useAlert from design-system
 */
export class Alert extends React.Component<AlertProps, State> {
  public static defaultProps = {
    className: '',
    position: '',
    type: 'info',
    anchorElementById: 'weave-react-modal',
    show: true,
  };

  public timeout;

  constructor(props) {
    super(props);

    this.state = {
      display: true,
    };
  }

  componentDidUpdate(prevProps: AlertProps) {
    if (!this.state.display && this.props.show !== prevProps.show) {
      this.setState({ display: !!this.props.show });
      if (this.props.show) {
        this.setTimeout();
      }
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  public componentDidMount() {
    if (this.props.closeDelay !== false) {
      this.setTimeout();
    }
  }

  public handleCloseAlert = () => {
    this.clearTimeout();

    this.setState({ display: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  public getColorClass(type: Type = 'info') {
    return {
      success: successClass,
      warning: warningClass,
      info: infoClass,
      danger: dangerClass,
    }[type];
  }

  public render() {
    if (!this.props.show) {
      return null;
    }

    const displayClass = this.state.display
      ? ''
      : css`
          display: none;
        `;
    const combined = cx(reactAlert, displayClass, this.getColorClass(this.props.type));
    const classNames = cx('react-alert', combined, this.props.className);

    const AlertComp = (
      <div className={classNames} onClick={this.handleCloseAlert}>
        <span className={spanClass}>!</span>
        <Subtitle className={inlineStyle}>{this.props.children}</Subtitle>
      </div>
    );

    if (this.props.position === 'inline' || (this.props.className || '').includes('inline')) {
      return AlertComp;
    }

    const el = () => {
      return window.document.getElementById(this.props.anchorElementById) || window.document.body;
    };

    return (
      <ReactModal
        isOpen={this.state.display}
        ariaHideApp={false}
        parentSelector={el}
        className={cx('react-modal__alert', alertModal)}
        portalClassName="react-modal-portal_alert"
        style={{ overlay }}
      >
        {AlertComp}
      </ReactModal>
    );
  }

  private clearTimeout = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  private setTimeout = () => {
    this.timeout = window.setTimeout(() => {
      this.setState({ display: false }, () => {
        if (this.props.onClose) {
          this.props.onClose();
        }
      });
    }, (this.props.closeDelay || 3) * 1000);
  };
}
