import React, { forwardRef } from 'react';
import { WeaveTheme } from '../theme/weaveTheme';
import { ButtonProps, ButtonRef } from './button-type';
import { baseButtonStyle } from './common';
import { css } from '@emotion/core';

/**
 * @deprecated import from design-system
 */
export const PrimaryButton = forwardRef<ButtonRef, ButtonProps>(
  ({ children, size = 'small', ...rest }, ref) => (
    <button
      css={(theme: WeaveTheme) => [
        baseButtonStyle(theme, size),
        css`
          background: ${theme.colors.weaveBlue};
          border: 2px solid ${theme.colors.weaveBlue};
          color: white;
          font-weight: ${theme.font.weight.bold};
          transition: background 250ms ease-out, color 250ms ease-out,
            border-color 250ms ease-out box-shadow 200ms ease-out;

          :hover,
          :focus {
            background: ${theme.colors.hoverBlue};
            border-color: ${theme.colors.hoverBlue};
            box-shadow: 0 6px 8px 0 rgba(41, 190, 223, 0.4);
          }

          :active {
            background: ${theme.colors.pressedBlue};
            border-color: ${theme.colors.pressedBlue};
            box-shadow: none;
          }

          :disabled {
            background: ${theme.colors.gray[300]};
            border-color: ${theme.colors.gray[300]};
            box-shadow: none;
            color: ${theme.colors.gray[500]};
            font-weight: normal;
          }

          svg {
            margin-left: 8px;
          }
        `,
      ]}
      {...rest}
      ref={ref}
    >
      {children}
    </button>
  )
);
