import { css } from 'emotion';
import {
  gray5,
  gray9,
  weaveBlue,
  weaveBlueDark,
  weaveBlueLight,
  weaveGreen,
  weaveRed,
  weaveYellow,
} from '../colors/colors';

export const cursorStyle = css`
  cursor: pointer;
`;

export const badgeStyle = css`
  font-size: 10px;
  display: inline-block;
  text-align: center;
  color: white;
  padding: 4px 8px;
  border-radius: 10px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  transition: opacity 0.2s ease-in;
  font-family: 'Proxima Nova', sans-serif;

  &.blue {
    background: ${weaveBlue};
  }
  &.lightBlue {
    background: ${weaveBlueLight};
  }
  &.darkBlue {
    background: ${weaveBlueDark};
  }
  &.green {
    background: ${weaveGreen};
  }
  &.yellow {
    background: ${weaveYellow};
  }
  &.red {
    background: ${weaveRed};
  }
  &.gray,
  &.gray9 {
    background: ${gray9};
  }
  &.lightGray,
  &.gray5 {
    background: ${gray5};
  }
  &.white {
    color: ${gray9};
    border: 1px solid ${gray9};
    background: white;
  }
  &:hover {
    opacity: 0.8;
  }
`;
