import React, { Children } from 'react';
import { TabNavItem } from './tab-nav-item.component';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { tabBarStyle } from '../common/styles';
import { getKeyboardHandler } from '../common/utils';

type TabNavProps = React.HTMLAttributes<HTMLElement> & {
  children: Array<JSX.Element | boolean | null>;
};

const handleKeyDown = getKeyboardHandler('a');

/**
 * @deprecated import from design-system
 */
export const TabNav = ({ children, ...rest }: TabNavProps) => {
  const { pathname } = useLocation();
  const childArr = Children.toArray(children).filter(Boolean) as JSX.Element[];
  const tabWidth = 100 / childArr.length;
  const activeIndex = childArr.findIndex(
    (child) =>
      !!matchPath(pathname, {
        path: child.props.to,
        exact: child.props.exact,
      })
  );

  return (
    <nav
      css={(theme) => tabBarStyle(theme, { activeIndex, tabWidth })}
      {...rest}
      tabIndex={-1}
      onKeyDown={handleKeyDown}
    >
      {children}
    </nav>
  );
};

TabNav.Tab = TabNavItem;
