import { css } from 'emotion';
import { gray1, gray3 } from '../../foundation/colors/colors';

export const cardStyle = css`
  background: white;
  overflow: hidden;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 10px 5px;
  box-shadow: 0 0 5px ${gray3};

  word-wrap: break-word;

  .center-text {
    text-align: center;
  }
  &.sm {
    width: 125px;
    height: 150px;
  }
  .md {
    width: 395px;
    height: 450px;
  }
  svg.card-svg {
    max-width: 100%;
    max-height: 50%;
    overflow: hidden;
  }

  &.gray {
    background-color: ${gray1};
  }

  label: card;
`;
