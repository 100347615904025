/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const RecordIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M10.75,20.75 L10.75,18.9109003 C6.92546275,18.3601492 4,15.3225659 4,11.6578947 L4,11.1842105 C4,10.5301891 4.5776971,10 5.29032258,10 C6.00294806,10 6.58064516,10.5301891 6.58064516,11.1842105 L6.58064516,11.6578947 C6.58064516,14.4047847 9.00697297,16.6315789 12,16.6315789 C14.993027,16.6315789 17.4193548,14.4047847 17.4193548,11.6578947 L17.4193548,11.1842105 C17.4193548,10.5301891 17.9970519,10 18.7096774,10 C19.4223029,10 20,10.5301891 20,11.1842105 L20,11.6578947 C20,15.3225659 17.0745372,18.3601492 13.25,18.9109003 L13.25,20.75 L15.75,20.75 C16.4403559,20.75 17,21.3096441 17,22 C17,22.6903559 16.4403559,23.25 15.75,23.25 L8.25,23.25 C7.55964406,23.25 7,22.6903559 7,22 C7,21.3096441 7.55964406,20.75 8.25,20.75 L10.75,20.75 Z M12,0.75 C14.3472102,0.75 16.25,2.79373721 16.25,5.31481481 L16.25,10.6851852 C16.25,13.2062628 14.3472102,15.25 12,15.25 C9.65278981,15.25 7.75,13.2062628 7.75,10.6851852 L7.75,5.31481481 C7.75,2.79373721 9.65278981,0.75 12,0.75 Z M12,3.43518519 C11.0335017,3.43518519 10.25,4.27672404 10.25,5.31481481 L10.25,10.6851852 C10.25,11.723276 11.0335017,12.5648148 12,12.5648148 C12.9664983,12.5648148 13.75,11.723276 13.75,10.6851852 L13.75,5.31481481 C13.75,4.27672404 12.9664983,3.43518519 12,3.43518519 Z" />
  </SvgIcon>
  );