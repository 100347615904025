import { useRef, useState } from 'react';
import { isFunction } from 'lodash';

export type ModalControlModalProps = {
  onClose: () => void;
  show: boolean;
};

export type ModalControlTriggerProps = {
  onClick: () => void;
  ref: React.Ref<any>;
};

type ModalControlProps = {
  focusDelay?: number;
  onClose?: () => void;
};

export type ModalControlResponse = {
  modalProps: ModalControlModalProps;
  triggerProps: ModalControlTriggerProps;
};

/**
 * @deprecated import from design-system
 */
export function useModalControl({
  focusDelay = 0,
  onClose,
}: ModalControlProps = {}): ModalControlResponse {
  const [show, setShow] = useState(false);
  const triggerRef = useRef<any>(null);

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    if (triggerRef.current) {
      setTimeout(() => {
        if (triggerRef.current) {
          triggerRef.current.focus();
        }
      }, focusDelay);
    }
    if (isFunction(onClose)) {
      // @ts-ignore
      onClose();
    }
  };

  return {
    modalProps: {
      onClose: closeModal,
      show,
    },
    triggerProps: {
      onClick: openModal,
      ref: triggerRef,
    },
  };
}
