import { css } from 'emotion';
import * as colors from '../../foundation/colors/colors';

export const header = css`
  position: relative;

  label: header;
`;

export const tabs = css`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid ${colors.gray3};

  label: tabs;
`;

export const tabsFullWidth = css`
  ${tabs};

  display: grid;
  grid-auto-flow: column;
`;

export const tab = css`
  color: ${colors.gray5};
  list-style: none;
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 12px 2px;
  margin: 0 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 800;
  text-align: center;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${colors.gray9};
  }

  label: tab;
`;

export const active = css`
  color: ${colors.gray9};

  label: active;
`;

export const activeTabBorder = css`
  height: 5px;
  background: ${colors.weaveBlue};
  position: absolute;
  bottom: 0;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  label: activeTabBorder;
`;

export const content = css`
  padding-top: 16px;

  label: content;
`;
