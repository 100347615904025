import { css } from 'emotion';
import { gray1, gray3 } from '../../foundation/colors/colors';

export const listItemWrapper = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  min-height: 40px;
  overflow: hidden;
  padding: 8px;
  border-bottom: 1px solid ${gray1};
  align-items: center;

  .left-side {
    flex: 1;
    max-width: 40px;
    max-height: 40px;
    margin-right: 6px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .center-side {
    flex: 3;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
    justify-content: start;
  }
  .right-side {
    text-align: right;
    justify-content: end;
    margin-left: 6px;
    flex: 1;
    max-width: 72px;
    max-height: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .borderless & {
    border-color: transparent;
  }

  &:first-child {
    border-top: 1px solid ${gray1};
  }

  &.svg-left-pad {
    padding-left: 42px;
  }

  label: listWrapper;
`;

export const iconStyle = css`
  overflow: hidden;
`;

export const listStyle = css`
  cursor: pointer;
  background-color: white;

  & > .title-wv {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 6px;
  }

  .list-items-wv > .list-item-wv:first-child {
    border-top: 1px solid ${gray1};
  }
  &.borderless-top {
    .list-items-wv > .list-item-wv:first-child {
      border-top: 1px solid transparent;
    }
  }
  &.borderless {
    .list-items-wv > .list-item-wv:first-child {
      border-color: transparent;
    }
  }
  .list-items-wv > div {
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
  }
  .list-items-wv > div:hover {
    background-color: ${gray1};
  }
  .list-items-wv > div:active {
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    background-color: ${gray3};
  }

  label: listStyle;
`;
