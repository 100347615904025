/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const UserManagementIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M20,17.75 L21.5,17.75 C22.1903559,17.75 22.75,18.3096441 22.75,19 C22.75,19.6903559 22.1903559,20.25 21.5,20.25 L20,20.25 L20,21.75 C20,22.4403559 19.4403559,23 18.75,23 C18.0596441,23 17.5,22.4403559 17.5,21.75 L17.5,20.25 L16,20.25 C15.3096441,20.25 14.75,19.6903559 14.75,19 C14.75,18.3096441 15.3096441,17.75 16,17.75 L17.5,17.75 L17.5,16.25 C17.5,15.5596441 18.0596441,15 18.75,15 C19.4403559,15 20,15.5596441 20,16.25 L20,17.75 Z M13.7189941,16.7508545 L7.5,16.75 C5.70507456,16.75 4.25,18.2050746 4.25,20 L4.25,22.25 C4.25,22.9403559 3.69035594,23.5 3,23.5 C2.30964406,23.5 1.75,22.9403559 1.75,22.25 L1.75,20 C1.75,16.8243627 4.32436269,14.25 7.5,14.25 L13.7189941,14.2508545 C15.5247803,14.25 15.5247803,16.75 13.7189941,16.7508545 Z M12,12.25 C8.82436269,12.25 6.25,9.67563731 6.25,6.5 C6.25,3.32436269 8.82436269,0.75 12,0.75 C15.1756373,0.75 17.75,3.32436269 17.75,6.5 C17.75,9.67563731 15.1756373,12.25 12,12.25 Z M12,9.75 C13.7949254,9.75 15.25,8.29492544 15.25,6.5 C15.25,4.70507456 13.7949254,3.25 12,3.25 C10.2050746,3.25 8.75,4.70507456 8.75,6.5 C8.75,8.29492544 10.2050746,9.75 12,9.75 Z" />
  </SvgIcon>
  );