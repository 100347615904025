import { cx } from 'emotion';
import React from 'react';

import { group, groupIconLeft } from './group.styles';
import { groupJub, groupIconLeftJub } from './group.styles.jubjub';
import { Icons } from '../../../svgs/svg.component';

interface Props {
  className?: string;
  children: React.ReactNode;
  iconLeft?: Icons;
}

/**
 * @deprecated not replaced in design-system
 */
export const Group = ({ className = '', children, iconLeft, ...props }: Props) => {
  return (
    <div className={cx(className, group, groupJub, !!iconLeft ? cx(groupIconLeft, groupIconLeftJub) : '')} {...props}>
      {children}
    </div>
  );
};
