import * as React from 'react';
import { gray3 } from '../../';
import { Button } from '../../foundation/buttons/button.component';
import { css } from 'emotion';
import moment, { Moment } from 'moment';
import { SelectionType, Calendar } from '../calendar/calendar.component';

interface CustomPickerProps {
  disablePast?: Boolean;
  disableFuture?: Boolean;
  defaultStart?: Moment;
  defaultEnd?: Moment;
  onChange: (start: Moment, end: Moment) => void;
}

const PICKER_MAX_HEIGHT = '410px';

/**
 * @deprecated Replaced by DatePicker and DateRangePicker in the design-system
 */
export const CustomPicker = ({ disablePast, disableFuture, defaultStart, defaultEnd, onChange }: CustomPickerProps) => {
  const [start, setStart] = React.useState(defaultStart || moment());
  const [end, setEnd] = React.useState(defaultEnd);
  const currentMonthEl = React.useCallback((node) => {
    if (node && !!node.scrollIntoView) {
      node.scrollIntoView();
    }
  }, []);

  const onRangeChange = (range) => {
    setStart(range.start && range.start.startOf('day'));
    setEnd(range.end && range.end.endOf('day'));
  };

  return (
    <React.Fragment>
      <div
        className={css`
          position: relative;
        `}
      >
        <div
          className={css`
            margin: 0 16px;
            max-height: ${PICKER_MAX_HEIGHT};
            overflow-y: scroll;
            &::-webkit-scrollbar {
              width: 4px;
              display: unset;
            }
            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
              margin-top: 16px;
              background-color: #ced4d6;
              border-radius: 2px;
              border: solid #ced4d6;
            }
          `}
        >
          <div
            className={css`
              padding: 16px 0 0;
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            {!disablePast &&
              [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((month) => (
                <Calendar
                  key={`before-${month}`}
                  value={{ start: start, end: end }}
                  onChange={onRangeChange}
                  selectionType={SelectionType.Range}
                  visibleMonth={moment().subtract(month, 'month')}
                  disableMonthClick
                />
              ))}
            <div ref={currentMonthEl}>
              <Calendar
                value={{ start: start, end: end }}
                onChange={onRangeChange}
                selectionType={SelectionType.Range}
                visibleMonth={moment()}
                disableMonthClick
              />
            </div>
            {!disableFuture &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                <Calendar
                  key={`after-${month}`}
                  value={{ start: start, end: end }}
                  onChange={onRangeChange}
                  selectionType={SelectionType.Range}
                  visibleMonth={moment().add(month, 'month')}
                  disableMonthClick
                />
              ))}
          </div>
        </div>
      </div>
      <div
        className={css`
          display: flex;
          justify-content: center;
          padding: 14px 0;
          border-top: 1px solid ${gray3};
        `}
      >
        <Button
          onClick={() => {
            if (!end) {
              onChange(start, moment(start).endOf('day'));
            } else {
              onChange(start, end);
            }
          }}
        >
          Set
        </Button>
      </div>
    </React.Fragment>
  );
};
