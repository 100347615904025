import React, { Children } from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from 'dsm/theme';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  as?: React.ElementType;
  /**
   * For when ButtonBar is embedded in an element with external padding (eg Modal)
   * Only one of these props should be passed at once. They do not stack.
   */
  removeHorizontalSpacing?: boolean;
  removeSpacing?: boolean;
  removeVerticalSpacing?: boolean;
};

const getChildSpacing = (theme: WeaveTheme, count: number) => {
  const splitSpacing = theme.spacing(1);
  return css`
    > button,
    > div {
      width: calc(${100 / count}% - ${theme.spacing(1)});

      :not(:first-child) {
        margin-left: ${splitSpacing};
      }

      :not(:last-child) {
        margin-right: ${splitSpacing};
      }
    }
  `;
};

/**
 * @deprecated replaced by ButtonBar from design-system
 */
export const ButtonBar = ({
  children,
  as = 'div',
  removeHorizontalSpacing,
  removeSpacing,
  removeVerticalSpacing,
  ...rest
}: Props) => {
  const Component = as;
  const childCount = Children.toArray(children).length;

  let spacing = [2];
  if (removeSpacing) {
    spacing = [];
  } else if (removeHorizontalSpacing) {
    spacing = [2, 0];
  } else if (removeVerticalSpacing) {
    spacing = [0, 2];
  }

  return (
    <Component
      css={(theme: WeaveTheme) => [
        css`
          width: 100%;
          background: white;
          display: flex;
        `,
        ,
        childCount > 1 && getChildSpacing(theme, childCount),
        spacing.length > 0 &&
          css`
            padding: ${theme.spacing.apply(null, spacing)};
          `,
      ]}
      {...rest}
    >
      {children}
    </Component>
  );
};
