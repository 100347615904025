/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const FilterIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M17.25,15 L17.25,4.25 C17.25,3.55964406 17.8096441,3 18.5,3 C19.1903559,3 19.75,3.55964406 19.75,4.25 L19.75,15 L21,15 C21.6903559,15 22.25,15.5596441 22.25,16.25 C22.25,16.9403559 21.6903559,17.5 21,17.5 L19.75,17.5 L19.75,19.75 C19.75,20.4403559 19.1903559,21 18.5,21 C17.8096441,21 17.25,20.4403559 17.25,19.75 L17.25,17.5 L16,17.5 C15.3096441,17.5 14.75,16.9403559 14.75,16.25 C14.75,15.5596441 15.3096441,15 16,15 L17.25,15 Z M10.75,7 L10.75,4.25 C10.75,3.55964406 11.3096441,3 12,3 C12.6903559,3 13.25,3.55964406 13.25,4.25 L13.25,7 L14.5,7 C15.1903559,7 15.75,7.55964406 15.75,8.25 C15.75,8.94035594 15.1903559,9.5 14.5,9.5 L13.25,9.5 L13.25,19.75 C13.25,20.4403559 12.6903559,21 12,21 C11.3096441,21 10.75,20.4403559 10.75,19.75 L10.75,9.5 L9.5,9.5 C8.80964406,9.5 8.25,8.94035594 8.25,8.25 C8.25,7.55964406 8.80964406,7 9.5,7 L10.75,7 Z M4.25,12 L4.25,4.25 C4.25,3.55964406 4.80964406,3 5.5,3 C6.19035594,3 6.75,3.55964406 6.75,4.25 L6.75,12 L8,12 C8.69035594,12 9.25,12.5596441 9.25,13.25 C9.25,13.9403559 8.69035594,14.5 8,14.5 L6.75,14.5 L6.75,19.75 C6.75,20.4403559 6.19035594,21 5.5,21 C4.80964406,21 4.25,20.4403559 4.25,19.75 L4.25,14.5 L3,14.5 C2.30964406,14.5 1.75,13.9403559 1.75,13.25 C1.75,12.5596441 2.30964406,12 3,12 L4.25,12 Z" />
  </SvgIcon>
  );