import { jubcss } from '../../helpers/jub-css';

export const dotStyleJub = jubcss`
  font-size: 11px !important;
  display: inline-block !important;
  border-radius: 40px !important;
  margin: 0 4px !important;
  cursor: pointer !important;
  outline: none !important;
  transition: opacity 0.2s ease-in !important;
  box-sizing: border-box !important;
  text-align: center !important;
  line-height: 1em !important;

  &:focus,
  &:active {
    outline: none !important;
  }
  &.md {
    width: 8px !important;
    height: 8px !important;
    padding: 4px !important;
  }

  &.has-text {
    width: 18px !important;
    height: 18px !important;
    padding: 3px 0 0 0 !important;
    &.is-active {
      color: white !important;
    }    
  }

  label: dotStyle;
`;
