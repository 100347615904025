import { css } from 'emotion';
import { gray3, gray5 } from '../colors/colors';

export const lbStyle = css`
  border-bottom: 1px solid ${gray3};
  width: 100%;
  height: 0px;
  display: block;

  .light-text-wv & {
    border-color: ${gray5};
  }

  label: lbStyle;
`;
