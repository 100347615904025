/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const CarrotDownIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M9.68363094,11.965864 L16.9314755,19.2137085 C17.4196308,19.7018639 17.4196308,20.4933201 16.9314755,20.9814755 C16.4433201,21.4696308 15.6518639,21.4696308 15.1637085,20.9814755 L7.03198052,12.8497475 C6.54382515,12.3615921 6.54382515,11.5701359 7.03198052,11.0819805 L15.1637085,2.95025253 C15.6518639,2.46209717 16.4433201,2.46209717 16.9314755,2.95025253 C17.4196308,3.4384079 17.4196308,4.22986412 16.9314755,4.71801948 L9.68363094,11.965864 Z" transform="translate(11.981728, 11.965864) rotate(-90.000000) translate(-11.981728, -11.965864) " />
  </SvgIcon>
  );