import { css } from 'emotion';
import { modalBackground } from '../../foundation/colors/colors';

export const modalBackdrop = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${modalBackground};
  overflow: hidden;
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 100;
  outline: none;

  & div {
    outline: none;
  }
  label: modal-backdrop;
`;

export const modal = css`
  margin: 30px;
`;

export const modalCard = css`
  background-color: white;
  display: inline-block;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 10px;
  .tablet-width & {
    width: 600px;
  }
  min-height: 150px;
  max-height: 600px;

  border-radius: 10px;
  cursor: initial;
  outline: none;

  &.add-padding {
    padding: 16px;
  }

  label: modal-card;
`;

export const modalHeader = css`
  text-align: center;
  position: relative;
  margin: 0 auto;
  padding: 5px 10px;
  font-size: 1.3em;
  outline: none;

  label: modal-header;
`;

export const modalBody = css`
  border-radius: 10px;
  padding: 10px;
  outline: none;
  label: modal-body;
`;
