/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const MinusIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M4,12.8214286 C3.30964406,12.8214286 2.75,12.2617845 2.75,11.5714286 C2.75,10.8810726 3.30964406,10.3214286 4,10.3214286 L20,10.3214286 C20.6903559,10.3214286 21.25,10.8810726 21.25,11.5714286 C21.25,12.2617845 20.6903559,12.8214286 20,12.8214286 L4,12.8214286 Z" />
  </SvgIcon>
  );