import { css } from 'emotion';
import * as colors from '../../foundation/colors/colors';

const width = '280px';
const cellPadding = '6px';

export const calendar = css`
  width: ${width};
  box-sizing: border-box;
  user-select: none;
  padding-top: ${cellPadding};

  label: calendar;
`;

export const toolbar = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(${width} / 20);
  height: 36px;
  label: toolbar;
`;

export const headerArrow = css`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const buttons = css`
  display: flex;

  label: buttons;
`;

export const button = css`
  margin: ${cellPadding};
  flex-grow: 1;

  label: button;
`;

export const carrot = css`
  margin: 0 calc(${cellPadding} * 2);

  label: carrot;
`;

export const yearMonthDropdownStyle = css`
  display: flex;
  padding: 0 5px 14px;
  > div {
    flex: 1;
  }
  > div:first-child {
    margin-right: 8px;
  }
`;

export const content = css`
  display: grid;
  width: ${width};
  height: ${width};
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-row-gap: ${cellPadding};
  grid-column-gap: 0;
  padding-bottom: ${cellPadding};

  label: content;
`;

const cell = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const header = css`
  ${cell};

  font-weight: 800;

  label: header;
`;

export const day = css`
  ${cell};

  /* First day in week */
  &:nth-child(7n + 1) {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  /* Last day in week */
  &:nth-child(7n) {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  &:not(:empty):hover {
    cursor: pointer;
  }

  &:hover > .value,
  &.only > .value {
    background-color: ${colors.weaveGreen};
    color: ${colors.white};
    font-weight: 500;
  }

  & > .value {
    border-radius: 50%;
    height: 100%;
    width: calc(100% - ${cellPadding});
  }

  label: day;
`;

export const disabledCell = css`
  color: ${colors.gray5} !important;
  pointer-events: none;

  label: disabled-cell;
`;

export const value = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  label: value;
`;

export const today = css`
  & > .value {
    color: ${colors.weaveBlueDark};
    font-weight: 600;
  }

  label: today;
`;

export const selected = css`
  background-color: ${colors.gray1};

  &.start,
  &.first-day {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    margin-left: calc(${cellPadding} / 2);
  }

  &.start > .value {
    width: calc(100% + ${cellPadding} / 2);
    margin-right: calc(${cellPadding} / 2);
    background-color: ${colors.weaveGreen};
    color: ${colors.white};
  }

  &.end,
  &.last-day {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    margin-right: calc(${cellPadding} / 2);
  }

  &.end > .value {
    width: calc(100% + ${cellPadding} / 2);
    margin-left: calc(${cellPadding} / 2);
    background-color: ${colors.weaveGreen};
    color: ${colors.white};
  }

  label: selected;
`;

export const timeInput = css`
  margin: 0 ${cellPadding} ${cellPadding} ${cellPadding};

  label: time-input;
`;
