import React from 'react';
import { cx } from 'emotion';
import { Moment } from 'moment';
import { Calendar, DateRange, SelectionType, DateTimeRange } from '../../../modules/calendar/calendar.component';
import { Modal } from '../../../modules/modal/modal.component';
import { Input } from '../input/input.component';
import { inputStyle } from './date-time-input.styles';
import { dateTimeInputJub } from './date-time-input.styles.jubjub';
import { Group } from '../group/group.component';

interface Props {
  className?: string;
  clearButton?: boolean;
  name?: string;
  onChange?: (value?: DateTimeRange) => void;
  placeholder?: string;
  selectionType?: SelectionType;
  value?: DateTimeRange;
  selectYearMonth?: boolean;
  minDate?: Moment;
  maxDate?: Moment;
  isClearable?: boolean;
}

interface State {
  value?: DateTimeRange;
  showCalendar: boolean;
}

/**
 * @deprecated Replaced by TimeField and DateField with useForm, useFormField in design-system
 */
export class DateTimeInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.createState();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      this.setState(this.createState());
    }
  }

  toggleCalendar = () => {
    this.setState({ showCalendar: !this.state.showCalendar });
  };

  handleCalendarChange = (newMoment: Moment | undefined) => {
    this.setState(
      {
        value: newMoment,
        showCalendar: false,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.value);
        }
      }
    );
  };

  createState = () => {
    return {
      value: this.props.value,
      showCalendar: false,
    };
  };

  formatValue = () => {
    if (this.state.value === undefined) {
      return '';
    }

    switch (this.props.selectionType) {
      case SelectionType.Range:
        const range = this.state.value as DateRange;
        if (range && range.start && range.start.isSame(range.end, 'day')) {
          return range.start.format('M/D/YYYY');
        }
        if (range && range.start && range.end) {
          return `${range.start.format('M/D/YYYY')} - ${range.end.format('M/D/YYYY')}`;
        }
        return '';

      case SelectionType.DateTime:
        const dateTime = this.state.value as Moment;
        return dateTime.format('M/D/YYYY h:mm A');

      default:
        const date = this.state.value as Moment;
        return date.format('M/D/YYYY');
    }
  };

  render() {
    const { name, placeholder, selectionType, isClearable = true } = this.props;
    return (
      <Group className={cx('date-time-input', dateTimeInputJub, this.props.className)}>
        <Input
          onClick={this.toggleCalendar}
          name={name}
          value={this.formatValue()}
          placeholder={placeholder}
          className={inputStyle}
          readOnly={true}
          iconLeft="Calendar"
          iconRight={isClearable && this.state.value ? 'X' : null}
          iconRightColor="red"
          onClickRightIcon={() => this.handleCalendarChange(undefined)}
        />
        <Modal isOpen={this.state.showCalendar} onRequestClose={this.toggleCalendar}>
          <Calendar
            value={this.state.value}
            onChange={this.handleCalendarChange}
            setButton={true}
            selectionType={selectionType}
            clearButton={this.props.clearButton}
            selectYearMonth={this.props.selectYearMonth}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
          />
        </Modal>
      </Group>
    );
  }
}
