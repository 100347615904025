import { css } from 'emotion';
import { gray1, gray3, weaveBlue, weaveGreen } from '../../foundation/colors/colors';
import { weaveBlueDark } from '../../foundation';

export const iconContainerStyle = css`
  display: flex;
  flex-wrap: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  visibility: hidden;

  label: iconContainerStyle;
`;

export const bubbleRowStyle = css`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 12px;

  &.left {
    flex-direction: row;
  }

  &.right {
    flex-direction: row-reverse;
  }

  &:hover .visible {
    visibility: visible;
    opacity: 1;
  }

  label: bubbleRowStyle;
`;

export const bubbleStyle = css`
  padding: 8px 16px;
  background-color: ${gray1};
  border-radius: 20px;
  vertical-align: top;
  word-break: break-word;

  &.blue,
  &.blueDark,
  &.green {
    color: white;

    .body-wv {
      color: white;
    }
  }

  &.blue {
    background-color: ${weaveBlue};
  }

  &.blueDark {
    background-color: ${weaveBlueDark};
  }

  &.green {
    background-color: ${weaveGreen};
  }

  .left & {
    border-top-left-radius: 0;
  }

  .right & {
    border-top-right-radius: 0;
  }

  label: bubbleStyle;
`;

export const imageStyle = css`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  background-color: ${gray3};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  overflow: hidden;

  .right & {
    margin-left: 4px;
  }

  .left & {
    margin-right: 4px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  label: imageStyle;
`;
