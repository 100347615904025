/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const QuickFillIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M18.5,18 L19.5,18 C20.1903559,18 20.75,18.5596441 20.75,19.25 C20.75,19.9403559 20.1903559,20.5 19.5,20.5 L18.5,20.5 L18.5,21.5 C18.5,22.1903559 17.9403559,22.75 17.25,22.75 C16.5596441,22.75 16,22.1903559 16,21.5 L16,20.5 L15,20.5 C14.3096441,20.5 13.75,19.9403559 13.75,19.25 C13.75,18.5596441 14.3096441,18 15,18 L16,18 L16,17 C16,16.3096441 16.5596441,15.75 17.25,15.75 C17.9403559,15.75 18.5,16.3096441 18.5,17 L18.5,18 Z M5.5,18 L10.763916,18 C11.454272,18 12.013916,18.5596441 12.013916,19.25 C12.013916,19.9403559 11.454272,20.5 10.763916,20.5 L5.25,20.5 C4.00735931,20.5 3,19.4926407 3,18.25 L3,3.25 C3,2.00735931 4.00735931,1 5.25,1 L16.25,1 C17.4926407,1 18.5,2.00735931 18.5,3.25 L18.5,12.6860352 C18.5,13.3763911 17.9403559,13.9360352 17.25,13.9360352 C16.5596441,13.9360352 16,13.3763911 16,12.6860352 L16,3.5 L5.5,3.5 L5.5,18 Z M8,5.25 L13.5,5.25 C14.1903559,5.25 14.75,5.80964406 14.75,6.5 C14.75,7.19035594 14.1903559,7.75 13.5,7.75 L8,7.75 C7.30964406,7.75 6.75,7.19035594 6.75,6.5 C6.75,5.80964406 7.30964406,5.25 8,5.25 Z M8,9.25 L12.5,9.25 C13.1903559,9.25 13.75,9.80964406 13.75,10.5 C13.75,11.1903559 13.1903559,11.75 12.5,11.75 L8,11.75 C7.30964406,11.75 6.75,11.1903559 6.75,10.5 C6.75,9.80964406 7.30964406,9.25 8,9.25 Z M8,13.25 L11.5,13.25 C12.1903559,13.25 12.75,13.8096441 12.75,14.5 C12.75,15.1903559 12.1903559,15.75 11.5,15.75 L8,15.75 C7.30964406,15.75 6.75,15.1903559 6.75,14.5 C6.75,13.8096441 7.30964406,13.25 8,13.25 Z" />
  </SvgIcon>
  );