/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const PortingStatusIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M10.8386221,13.2260853 C9.62580556,12.2116924 8.84365333,11.1407675 8.46299705,10.004145 C9.57051462,9.03627026 9.89468176,7.43394697 9.22998239,6.10454824 L7.5832417,2.81106685 C7.02751589,1.69961523 5.67600155,1.24911045 4.56454993,1.80483626 C4.34824639,1.91298804 4.15079323,2.05530422 3.97979026,2.22630719 L2.03768627,4.16842583 C1.01406305,5.19204906 0.458531531,6.59291731 0.502416151,8.03987855 C0.610489838,11.6032782 2.20330886,14.9511835 5.22282919,18.0519452 C8.26959822,21.1806888 11.7895909,22.9481502 15.7433485,23.315477 C17.2983785,23.4599566 18.8370128,22.9046186 19.9413272,21.8003041 L21.7274033,20.0142281 C22.6060829,19.1355484 22.6060829,17.7109272 21.7274033,16.8322476 C21.5461327,16.6509769 21.3352169,16.5020176 21.1037638,16.3918018 L17.4785427,14.6655055 C16.2156148,14.0641111 14.7194114,14.3409539 13.7552734,15.3298344 C12.9726789,14.8943095 11.9939126,14.1923637 10.8386221,13.2260853 Z M5.50229327,4.23933994 L6.99391441,7.22258223 C7.1537186,7.54219059 7.06584587,7.93015714 6.78392262,8.14971617 L5.68210344,9.00780206 L5.82177652,9.75585872 C6.19744253,11.7678387 7.35209246,13.5691467 9.23469951,15.1437485 C11.0044647,16.6239701 12.4567281,17.5862069 13.6411969,18.0357422 L14.4709606,18.3506581 L15.507,17.1173837 C15.7266941,16.8558655 16.0953354,16.7758123 16.4037085,16.9226567 L19.7093333,18.4967642 L18.1735603,20.0325372 C17.5951104,20.610987 16.7891621,20.9018773 15.9746235,20.8261976 C12.6311189,20.5155668 9.65736746,19.0223845 7.01390391,16.3077975 C4.4073058,13.6310678 3.08910822,10.8603822 3.00126715,7.96409136 C2.97828001,7.20616065 3.26927218,6.47237383 3.80545656,5.93618945 L5.50229327,4.23933994 Z" />
  </SvgIcon>
  );