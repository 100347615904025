export type Browsers = 'chrome' | 'firefox' | 'safari' | 'edge' | 'ie' | 'unknown';

export const isSafari = (): boolean => {
  const { userAgent } = window.navigator;
  return userAgent.includes('AppleWebKit') && !userAgent.includes('Edge') && !userAgent.includes('Chrome');
};

export const isEdge = (): boolean => {
  const { userAgent } = window.navigator;
  return userAgent.includes('Edge');
};

export const isIE = (): boolean => {
  const { userAgent } = window.navigator;
  return userAgent.includes('MSIE');
};

export const isChrome = (): boolean => {
  const { userAgent } = window.navigator;
  return userAgent.includes('Chrome') && !userAgent.includes('Edge');
};

export const isFirefox = (): boolean => {
  const { userAgent } = window.navigator;
  return userAgent.includes('Firefox');
};

export const userFriendlyName = (name: Browsers): string => {
  switch (name) {
    case 'ie':
      return 'Internet Explorer';
    case 'chrome':
      return 'Google Chrome';
    case 'firefox':
      return 'Firefox';
    case 'edge':
      return 'Microsoft Edge';
    case 'safari':
      return 'Apple Safari';
    default:
      return 'Unknown';
  }
};

export const getBrowser = (): Browsers => {
  if (isIE()) {
    return 'ie';
  } else if (isChrome()) {
    return 'chrome';
  } else if (isFirefox()) {
    return 'firefox';
  } else if (isEdge()) {
    return 'edge';
  } else if (isSafari()) {
    return 'safari';
  } else {
    return 'unknown';
  }
};
