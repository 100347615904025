import { css } from 'emotion';
import {
  weaveGreen,
  weavePink,
  weavePurple,
  weaveBlue,
  weaveBlueLight,
  weaveYellow,
  gray1,
  gray3,
  gray5,
  gray7,
} from '../../foundation/colors/colors';

const iconWidth = 80;
const buttonWidth = 105;

export const callMessage = css`
  height: 80px;
  min-width: 500px;
  width: 100%;
  box-shadow: 0 0 10px ${gray3};
  overflow: hidden;
  border-radius: 10px;
  position: relative;

  label: callMessage;
`;

export const iconWrapper = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${iconWidth}px;
  height: 100%;
  background-color: ${weaveYellow};

  & .icon-div {
    margin: 20px auto;
    text-align: center;
    overflow: hidden;
  }
  .yellow & {
    background-color: ${weaveYellow};
  }
  .blue & {
    background-color: ${weaveBlue};
  }
  .purple & {
    background-color: ${weavePurple};
  }
  .pink & {
    background-color: ${weavePink};
  }
  .green & {
    background-color: ${weaveGreen};
  }
`;

export const content = css`
  margin-left: ${iconWidth + 65 + 15}px;
  margin-right: ${buttonWidth + 15}px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;

  label: content;
`;

export const buttonWrapper = css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: ${buttonWidth}px;
  border-left: 1px solid ${gray3};
  overflow: hidden;
  text-overflow: hidden;
  text-align: center;
  & > div {
    white-space: nowrap;
    cursor: pointer;
    padding: 12px 10px;
    height: 50%;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
  }

  & .cancel-button-style {
    /* This must match Button.component .cancel styles */
    background-color: white;
    color: ${gray5};
    &.hover,
    &:hover {
      background-color: ${gray3};
      color: ${gray7};
    }
  }
  & .default-button-style {
    /* This must match Button.component default styles */
    color: white;
    background-color: ${weaveBlue};
    &.hover,
    &:hover {
      color: white;
      background-color: ${weaveBlueLight};
    }
  }

  label: buttonWrapper;
`;

export const imageStyle = css`
  height: 60px;
  width: 60px;
  top: 10px;
  left: 90px;
  position: absolute;
  background-color: ${gray1};
  border-radius: 50px;
  overflow: hidden;

  label: imageStyle;
`;

export const bodyText = css`
  max-height: 40px;
  overflow-x: hidden;
  text-overflow: ellipsis;

  label: bodyText;
`;
