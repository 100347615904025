import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme';

import { weaveTheme } from './weaveTheme';

declare module '@material-ui/core/styles/zIndex' {
  interface ZIndex {
    fab: number;
  }
}

/**
 * @deprecated
 */
const muiTheme = createMuiTheme({
  // kill that ripple for all the things
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  /**
   * Color Palette
   */
  palette: {
    primary: {
      light: weaveTheme.colors.hoverBlue, //'#4ADAFF',
      main: weaveTheme.colors.weaveBlue, //'#28caf4',
      dark: weaveTheme.colors.pressedBlue, //'#25BBE2',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#ff6b59',
      contrastText: '#FFFFFF',
    },
    error: {
      light: '#fe887a',
      main: weaveTheme.colors.error, //'#FE6B59',
      dark: '#b14a3e',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#FFFFFF',
    },
    grey: {
      50: '#000', // Not used in our design system
      100: weaveTheme.colors.gray[100], //'#f9fcfd',
      200: weaveTheme.colors.gray[200], //'#f4f7f8',
      300: weaveTheme.colors.gray[300], //'#E4e9eb',
      400: weaveTheme.colors.gray[400], //'#C4C8D3',
      500: weaveTheme.colors.gray[500], //'#8D919C',
      600: weaveTheme.colors.gray[600], //'#333B3D',
      700: '#000', // Not used in our design system
      800: '#000', // Not used in our design system
      900: '#000', // Not used in our design system
      A100: '#000', // Not used in our design system
      A200: '#000', // Not used in our design system
      A400: '#000', // Not used in our design system
      A700: '#000', // Not used in our design system
    },
    action: {
      disabled: '#cbc2d4', //TODO: Where are these from?
      disabledBackground: '#E4E9EB', //Todo: where are these from?
    },
    common: {
      black: '#000',
      white: '#fff',
    },
  },

  /**
   * Breakpoint Values
   */
  breakpoints: {
    values: {
      xs: 0,
      sm: 415,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },

  /**
   * Typography Base Settings
   */
  typography: {
    fontFamily: weaveTheme.fontFamily,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
  },
});

muiTheme.shadows[1] = weaveTheme.shadows.light;
muiTheme.shadows[2] = weaveTheme.shadows.heavy;

for (let i = 3; i < muiTheme.shadows.length; i++) {
  muiTheme.shadows[i] = 'none';
}

/**
 * Component styles overrides go here.
 *
 * You'll need to find the corresponding element name in the the MUI documentation
 * for example the button styles are located at https://material-ui.com/api/button/
 */
muiTheme.overrides = {
  /**
   * Button Styles
   */
  MuiButton: {
    root: {
      fontWeight: 'bold',
      height: '40px',
      fontSize: '14px',
      borderRadius: '24px',
      paddingLeft: '16px',
      paddingRight: '16px',
      '&$contained': {
        boxShadow: muiTheme.shadows[0],
        '&$focusVisible': {
          boxShadow: muiTheme.shadows[0],
        },
        '&:hover': {
          boxShadow: muiTheme.shadows[0],
        },
        '&:active': {
          boxShadow: muiTheme.shadows[0],
        },
        '&$disabled': {
          color: muiTheme.palette.action.disabled,
          boxShadow: muiTheme.shadows[0],
          backgroundColor: muiTheme.palette.action.disabledBackground,
        },
      },
      '&$outlinedDefault': {
        color: 'blue',
      },
    },
    outlined: {
      color: muiTheme.palette.grey[500],
    },
    sizeSmall: {
      height: '24px',
      borderRadius: '20px',
      paddingLeft: '16px',
      paddingRight: '16px',
      lineHeight: '14px',
    },
    sizeLarge: {
      height: '48px',
      fontSize: '16px',
      borderRadius: '24px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    label: {
      textTransform: 'capitalize',
    },
  },

  MuiOutlinedInput: {
    root: {
      borderColor: '#C3C8D2',
      '&:hover $notchedOutline': {
        borderColor: muiTheme.palette.grey[500],
      },
      '&$focused $notchedOutline': {
        borderWidth: '1px',
      },
      '&.Mui-disabled $notchedOutline': {
        // needed, normal disabled api doesn't work
        borderColor: weaveTheme.colors.disabled,
      },
    },
  },

  MuiFormLabel: {
    root: {
      color: '#8D919B',
      '&$focused': {
        color: '#8D919B',
      },
      '&.Mui-disabled': {
        //needed to apply disabled, Select text
        color: weaveTheme.colors.disabled,
      },
    },
  },

  MuiInputBase: {
    root: {
      '&.Mui-disabled': {
        color: weaveTheme.colors.disabled, // text color on textfield
      },
    },
  },

  /**
   * MuiFormHelperText styles
   */
  MuiFormHelperText: {
    root: {
      margin: '8px 0 0 !important',
    },
  },

  /**
   * MuiSwitch styles
   */
  MuiSwitch: {
    root: {
      padding: '0px',
      width: '40px',
      height: '24px',
    },
    thumb: {
      boxShadow: 'none',
      width: '18px',
      height: '18px',
    },
    switchBase: {
      padding: '3px',
      color: muiTheme.palette.grey[400],
    },
    track: {
      width: '39px',
      height: '24px',
      borderRadius: '20px',
      border: `1px solid ${muiTheme.palette.grey[400]}`,
      opacity: 1,
      backgroundColor: 'transparent',
    },
    colorPrimary: {
      '&$checked + $track': {
        backgroundColor: 'transparent',
        border: `1px solid ${muiTheme.palette.primary.main}`,
        opacity: 1,
      },
      '&$disabled + $track': {
        border: `1px solid ${muiTheme.palette.grey[400]}`,
        opacity: 0.12,
      },
    },
  },

  /**
   * MuiFormControlLabel styles
   */
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
      marginRight: 0,
      minHeight: '42px',
    },
    labelPlacementStart: {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  /**
   * MuiLink Styles
   */
  MuiLink: {
    underlineHover: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },
  },

  /**
   * MuiSelect Styles
   */
  MuiSelect: {
    select: {
      '&&:focus': {
        backgroundColor: 'unset',
      },
    },
  },

  /**
   * MuiBackdrop
   */
  MuiBackdrop: {
    root: {
      zIndex: -1,
      position: 'fixed',
      right: '0px',
      bottom: '0px',
      top: '0px',
      left: '0px',
      backgroundColor: 'rgba(49,53,61,0.5)',
      backdropFilter: 'blur(8px)',
    },
  },
};

export { muiTheme, MuiTheme };
