import React, { createContext, useState, useMemo, useRef } from 'react';
import { Slate, withReact } from 'slate-react';
import { Node, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { TagType, AddTagToTemplateFun } from './message-template.models';
import { TemplateEditor } from './editor';
import { TagList } from './tags/tag-list';
import { TemplatePreview } from './preview';
import { parseTemplate } from './parse-template/parse-template';
import { withTags } from './editor/slate-with-tags';
import { serializePlainText, serializePlainTextValues } from './serialize-template';

type TagContextState = {
  tags: TagType[];
  addTagToTemplate: AddTagToTemplateFun;
  handleAddTagToTemplate: (fn: AddTagToTemplateFun) => void;
};

const TagContext = createContext<TagContextState | undefined>(undefined);
const EditorValueContext = createContext<Node[]>([]);

type MessageTemplateProps = {
  children: React.ReactNode;
  initialTemplate?: string;
  tags: TagType[];
  onChange?: (plainTextTemplate: string, plainTextValues: string) => void;
};

/**
 * For editing message templates with drag and drop tags.
 * @prop onChange - Returns plain text with tags and values of editor
 * @prop initialTemplate - Plain text message with template tags
 * @prop tags - an array of tags that can be used in the editor
 * @deprecated import from design-system
 */
export const MessageTemplate = ({
  children,
  initialTemplate,
  tags,
  onChange,
}: MessageTemplateProps) => {
  const addTagToTemplateListeners = useRef<AddTagToTemplateFun[]>([]);

  const [editorValue, setEditorValue] = useState<Node[]>(
    !!initialTemplate
      ? parseTemplate(initialTemplate, tags)
      : [{ children: [{ text: '' }] }]
  );

  const tagProviderValue = useMemo(
    () => ({
      tags,
      addTagToTemplate: (e, tag) =>
        addTagToTemplateListeners.current.forEach((fn) => fn(e, tag)),
      handleAddTagToTemplate: (fn: AddTagToTemplateFun) =>
        addTagToTemplateListeners.current.push(fn),
    }),
    []
  );

  const editor = useMemo(
    () => withReact(withHistory(withTags(tags, createEditor()))),
    []
  );

  if (editorValue && typeof onChange === 'function') {
    onChange(serializePlainText(editorValue), serializePlainTextValues(editorValue));
  }

  return (
    <TagContext.Provider value={tagProviderValue}>
      <EditorValueContext.Provider value={editorValue}>
        <Slate
          editor={editor}
          value={editorValue}
          onChange={(value) => setEditorValue(value)}
        >
          {children}
        </Slate>
      </EditorValueContext.Provider>
    </TagContext.Provider>
  );
};

export const useTemplateEditorValue = () => {
  const context = React.useContext(EditorValueContext);
  if (context === undefined) {
    throw new Error('useTemplateEditorValue must be used within a MessageTemplate');
  }
  return context;
};

export const useTemplateTags = () => {
  const context = React.useContext(TagContext);
  if (context === undefined) {
    throw new Error('useTemplateTags must be used within a MessageTemplate');
  }
  return context;
};

MessageTemplate.Editor = TemplateEditor;
MessageTemplate.TagList = TagList;
MessageTemplate.Preview = TemplatePreview;
