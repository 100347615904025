import { cx } from 'emotion';
import React from 'react';
import { svgStyle } from './svg.styles';
import { SvgList } from './svg-list';

export type Icons = keyof typeof SvgList;

interface Props extends React.SVGProps<SVGSVGElement> {
  icon: Icons;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: 'white' | 'gray' | 'gray3' | 'gray5' | 'gray9' | 'green' | 'red' | 'blue' | 'yellow' | 'pink' | 'purple';
  interactive?: boolean;
}

/**
 * @deprecated Icons are not imported and used directly in design-system
 */
export const Svg = ({ icon, className, size, color, interactive, viewBox = '0 0 24 24', ...props }: Props) => {
  const SvgIcon = SvgList[icon];

  return (
    <SvgIcon
      className={cx(icon, svgStyle, { interactive: !!interactive }, size, color, className)}
      viewBox={viewBox}
      {...props}
    />
  );
};
