import { cx } from 'emotion';
import React from 'react';
import { Svg } from './svg.component';
import { weaveLogoStyle } from './weave-logo.styles';

interface Props {
  className?: '';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: 'white' | 'gray' | 'gray3' | 'gray5' | 'gray9' | 'green' | 'red' | 'blue' | 'yellow' | 'pink' | 'purple';
  interactive?: boolean;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  viewBox?: string;
}

/**
 * @deprecated Replaced by WeaveLogoIcon in design-system
 */
export const WeaveLogo = ({ className, size = 'md', color = 'gray', ...props }: Props) => {
  return (
    <Svg
      icon="WeaveLogo"
      className={cx('weave-logo', weaveLogoStyle, className)}
      size={size}
      color={color}
      {...props}
    />
  );
};
