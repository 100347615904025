/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const ListIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M2.75,6.25 C2.05964406,6.25 1.5,5.69035594 1.5,5 C1.5,4.30964406 2.05964406,3.75 2.75,3.75 C3.44035594,3.75 4,4.30964406 4,5 C4,5.69035594 3.44035594,6.25 2.75,6.25 Z M2.75,13.25 C2.05964406,13.25 1.5,12.6903559 1.5,12 C1.5,11.3096441 2.05964406,10.75 2.75,10.75 C3.44035594,10.75 4,11.3096441 4,12 C4,12.6903559 3.44035594,13.25 2.75,13.25 Z M2.75,20.25 C2.05964406,20.25 1.5,19.6903559 1.5,19 C1.5,18.3096441 2.05964406,17.75 2.75,17.75 C3.44035594,17.75 4,18.3096441 4,19 C4,19.6903559 3.44035594,20.25 2.75,20.25 Z M7.75,3.75 L21.25,3.75 C21.9403559,3.75 22.5,4.30964406 22.5,5 C22.5,5.69035594 21.9403559,6.25 21.25,6.25 L7.75,6.25 C7.05964406,6.25 6.5,5.69035594 6.5,5 C6.5,4.30964406 7.05964406,3.75 7.75,3.75 Z M7.75,10.75 L21.25,10.75 C21.9403559,10.75 22.5,11.3096441 22.5,12 C22.5,12.6903559 21.9403559,13.25 21.25,13.25 L7.75,13.25 C7.05964406,13.25 6.5,12.6903559 6.5,12 C6.5,11.3096441 7.05964406,10.75 7.75,10.75 Z M7.75,17.75 L21.25,17.75 C21.9403559,17.75 22.5,18.3096441 22.5,19 C22.5,19.6903559 21.9403559,20.25 21.25,20.25 L7.75,20.25 C7.05964406,20.25 6.5,19.6903559 6.5,19 C6.5,18.3096441 7.05964406,17.75 7.75,17.75 Z" />
  </SvgIcon>
  );