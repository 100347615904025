import React from 'react';
import { Text, WeaveTheme } from 'dsm/index';
import { FlyoutMenuAction } from './use-flyout-menu';
import { css } from '@emotion/core';

type Props = FlyoutMenuAction & React.HTMLAttributes<HTMLLIElement>;

export const FlyoutMenuItem = ({ Icon, onClick, onKeyDown, label }: Props) => (
  <Text
    as="li"
    size="large"
    color="default"
    css={(theme: WeaveTheme) => css`
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: ${theme.spacing(2)};
      text-transform: capitalize;
      transition: background-color 250ms ease-out;

      :hover,
      :focus {
        background-color: ${theme.colors.gray[200]};
        outline: none;
      }

      :not(:last-of-type) {
        border-bottom: 1px solid ${theme.colors.gray[300]};
      }
    `}
    onClick={onClick}
    onKeyDown={onKeyDown}
    role="menuitem"
    tabIndex={-1}
  >
    {!!Icon && (
      <Icon
        css={(theme: WeaveTheme) => css`
          line-height: 1;
          margin-right: ${theme.spacing(1)};
        `}
      />
    )}
    {label}
  </Text>
);
