import React from 'react';
import { InfoRoundIconSmall } from '../icon';
import { NakedButton } from '../../naked';
import { useTooltip } from '../../hooks';
import { WeaveTheme } from '../theme';
import { css } from '@emotion/core';
import { Placement } from '../../hooks/use-hint/get-position';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  hasInteractiveContent?: boolean;
  placement?: Placement;
}

/**
 * @deprecated import from design-system
 */
export const Info = ({ children, hasInteractiveContent, placement, ...rest }: Props) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement });
  return (
    <>
      <NakedButton
        css={(theme: WeaveTheme) => css`
          :focus {
            outline: none;
          }

          svg {
            transition: fill 250ms ease-in-out;
          }

          :hover svg,
          :focus svg {
            fill: ${theme.colors.weaveBlue};
          }
        `}
        {...rest}
        {...triggerProps}
      >
        <InfoRoundIconSmall />
      </NakedButton>
      <Tooltip hasInteractiveContent={hasInteractiveContent} {...tooltipProps}>
        {children}
      </Tooltip>
    </>
  );
};
