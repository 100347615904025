import { jubcss } from '../../../helpers/jub-css';
import { gray3, gray7, weaveBlue, weaveRed } from '../../colors/colors';

export const textareaStyleJub = jubcss(`
  font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
  color: ${gray7} !important;
  min-height: 40px !important;
  width: 100% !important;
  font-size: 13px !important;
  border: 1px solid ${gray3} !important;
  padding: 10px 10px 12px !important;
  border-radius: 10px !important;
  display: block !important;
  outline: none !important;
  margin: 10px 0 0 0 !important;
  transition: border-color 0.2s ease-in !important;

  &::placeholder {
    color: ${gray3} !important;
  }

  &.error-style {
    border-color: ${weaveRed} !important;
  }

  &.active, &:active,
  &.focus, &:focus {
    border-color: ${weaveBlue} !important;
  }

  label: textareaStyle;
`);
