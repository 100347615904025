/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const PauseIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M6,2 L10,2 C10.5522847,2 11,2.44771525 11,3 L11,21 C11,21.5522847 10.5522847,22 10,22 L6,22 C5.44771525,22 5,21.5522847 5,21 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M14,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,21 C19,21.5522847 18.5522847,22 18,22 L14,22 C13.4477153,22 13,21.5522847 13,21 L13,3 C13,2.44771525 13.4477153,2 14,2 Z" />
  </SvgIcon>
  );