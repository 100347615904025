/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const HourglassIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M8.68043946,17 L15.3400856,17 C14.1814391,14.9416849 13.2023039,13.5874638 12.4459204,12.965504 L12.0102626,12.6072708 L11.5746047,12.965504 C10.818221,13.587464 9.83908537,14.9416859 8.68043946,17 Z M18.7204232,18.030301 C18.9513687,18.4857951 19.0717208,18.9893037 19.0717208,19.5 C19.0717208,21.2949119 17.6166618,22.75 15.8217345,22.75 L8.19880348,22.75 C7.68810576,22.75 7.18459586,22.6296476 6.7291005,22.3987014 C5.12819662,21.5870078 4.48841374,19.6312123 5.30010263,18.0302997 C6.69437967,15.280366 7.9194731,13.2799405 9.0199374,12 C7.91947298,10.7200593 6.69437909,8.7196329 5.3001001,5.96969526 C4.48841374,4.36878775 5.12819662,2.41299218 6.7291005,1.60129862 C7.18459586,1.37035244 7.68810576,1.25 8.19880348,1.25 L15.8217499,1.25 C17.6166618,1.25 19.0717208,2.70508809 19.0717208,4.5 C19.0717208,5.01069633 18.9513687,5.51420487 18.7204232,5.96969899 C17.3261459,8.71963342 16.1010523,10.7200593 15.0005878,12 C16.1010523,13.2799407 17.3261459,15.2803666 18.7204232,18.030301 Z M12.4459204,11.034496 C13.4412732,10.2160362 14.8223629,8.12952728 16.4906533,4.83915656 C16.5439477,4.734044 16.5717208,4.61785135 16.5717208,4.5 C16.5717208,4.08579219 16.2359353,3.75 15.8217345,3.75 L8.19880348,3.75 C8.08095076,3.75 7.96475675,3.7777735 7.85964298,3.8310685 C7.49020447,4.01838197 7.34256185,4.46972069 7.52987251,4.83915779 C9.19816263,8.1295278 10.5792521,10.2160363 11.5746047,11.034496 L12.0102626,11.3927292 L12.4459204,11.034496 Z" />
  </SvgIcon>
  );