/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const UserIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M22.25,22.25 C22.25,22.9403559 21.6903559,23.5 21,23.5 C20.3096441,23.5 19.75,22.9403559 19.75,22.25 L19.75,20 C19.75,18.2050746 18.2949254,16.75 16.5,16.75 L7.5,16.75 C5.70507456,16.75 4.25,18.2050746 4.25,20 L4.25,22.25 C4.25,22.9403559 3.69035594,23.5 3,23.5 C2.30964406,23.5 1.75,22.9403559 1.75,22.25 L1.75,20 C1.75,16.8243627 4.32436269,14.25 7.5,14.25 L16.5,14.25 C19.6756373,14.25 22.25,16.8243627 22.25,20 L22.25,22.25 Z M12,12.25 C8.82436269,12.25 6.25,9.67563731 6.25,6.5 C6.25,3.32436269 8.82436269,0.75 12,0.75 C15.1756373,0.75 17.75,3.32436269 17.75,6.5 C17.75,9.67563731 15.1756373,12.25 12,12.25 Z M12,9.75 C13.7949254,9.75 15.25,8.29492544 15.25,6.5 C15.25,4.70507456 13.7949254,3.25 12,3.25 C10.2050746,3.25 8.75,4.70507456 8.75,6.5 C8.75,8.29492544 10.2050746,9.75 12,9.75 Z" />
  </SvgIcon>
  );