export type DefaultStyle = {
  pointerEvents?: string;
  position: string;
  top: string;
  left: string;
  zIndex: number;
};

export type DimensionProps = {
  width: number;
  height: number;
};

export type PositionProps = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export type ElementProps = DimensionProps & PositionProps;

export type OverflowArgs = {
  buffer: number;
  tip: DimensionProps;
  trigger: ElementProps;
};

export type PositionArgs = OverflowArgs & {
  axis: 'x' | 'y';
};

export type XOverflow = {
  left: number;
  right: number;
};

export type YOverflow = {
  top: number;
  bottom: number;
};

export type OverflowResult = XOverflow | YOverflow;

type AxisPositionResult = {
  overflows: OverflowResult;
  position: number;
};

export type Placement = 'top' | 'right' | 'bottom' | 'left';

export type PlacementArgs = {
  buffer?: number;
  placement?: Placement;
  offset?: number;
  trigger?: ElementProps;
  tip?: DimensionProps;
};

export type PlacementResult = {
  style: DefaultStyle & { transform: string };
  // overflows: OverflowResult;
};

export type GetPositionArgs = {
  buffer?: number;
  offset?: number;
  placement?: Placement;
  tip?: HTMLElement;
  trigger?: HTMLElement;
};

type CompleteClientRect = ClientRect & {
  x: number;
  y: number;
};

export type PositionResult = PlacementResult & {
  placement?: Placement;
};

export const getDefaultStyle = (): DefaultStyle => ({
  position: 'absolute',
  top: '0px',
  left: '0px',
  zIndex: 11000,
});

const overflowChecks = {
  top: ({ buffer, trigger, tip }: OverflowArgs): number =>
    trigger.top - buffer - (tip.height / 2 - trigger.height / 2),
  right: ({ buffer, trigger, tip }: OverflowArgs): number =>
    window.innerWidth - buffer - (trigger.right + (tip.width / 2 - trigger.width / 2)),
  bottom: ({ buffer, trigger, tip }: OverflowArgs): number =>
    window.innerHeight -
    buffer -
    (trigger.bottom + (tip.height / 2 - trigger.height / 2)),
  left: ({ buffer, trigger, tip }: OverflowArgs): number =>
    trigger.left - buffer - (tip.width / 2 - trigger.width / 2),
};

const overflowsByAxis = { x: ['left', 'right'], y: ['top', 'bottom'] };

const getOverflow = ({ axis, buffer, trigger, tip }: PositionArgs): OverflowResult =>
  overflowsByAxis[axis].reduce((obj, key) => {
    const overage = overflowChecks[key]({ buffer, trigger, tip });
    return { ...obj, [key]: overage < 0 ? Math.abs(overage) : 0 };
  }, {} as OverflowResult);

const getCenteredPosition = ({ axis, trigger, tip }: PositionArgs): number =>
  window[`scroll${axis === 'x' ? 'Y' : 'X'}`] +
  trigger[axis === 'x' ? 'top' : 'left'] +
  trigger[axis === 'x' ? 'height' : 'width'] / 2 -
  tip[axis === 'x' ? 'height' : 'width'] / 2;

const positionsByAxis = {
  x: (args: OverflowArgs): AxisPositionResult => {
    const overflows = getOverflow({ axis: 'y', ...args }) as YOverflow;
    let position = getCenteredPosition({ axis: 'x', ...args });
    if (overflows.top) {
      position += overflows.top;
    } else if (overflows.bottom) {
      position -= overflows.bottom;
    }
    return { overflows, position };
  },
  y: (args: OverflowArgs): AxisPositionResult => {
    const overflows = getOverflow({ axis: 'x', ...args }) as XOverflow;
    let position = getCenteredPosition({ axis: 'y', ...args });
    if (overflows.left) {
      position += overflows.left;
    } else if (overflows.right) {
      position -= overflows.right;
    }
    return { overflows, position };
  },
};

export function getPlacement({
  placement = 'top',
  buffer = 8,
  offset = 8,
  trigger = {
    width: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  tip = { width: 0, height: 0 },
}: PlacementArgs = {}): PlacementResult {
  const axis = placement === 'top' || placement === 'bottom' ? 'y' : 'x';
  const { position } = positionsByAxis[axis]({
    buffer,
    trigger,
    tip,
  });
  let transform = '';

  switch (placement) {
    case 'top': {
      const y = window.scrollY + trigger.top - (tip.height + offset);
      transform = `translate3d(${position}px, ${y}px, 0px)`;
      break;
    }
    case 'right': {
      const x = window.scrollX + trigger.right + offset;
      transform = `translate3d(${x}px, ${position}px, 0px)`;
      break;
    }
    case 'bottom': {
      const y = window.scrollY + trigger.bottom + offset;
      transform = `translate3d(${position}px, ${y}px, 0px)`;
      break;
    }
    case 'left': {
      const x = window.scrollX + trigger.left - (tip.width + offset);
      transform = `translate3d(${x}px, ${position}px, 0px)`;
      break;
    }
  }

  return {
    style: { ...getDefaultStyle(), transform },
    // we only need to return this if showing arrows, so removing for now
    // overflows,
  };
}

const cascade = {
  bottom: ['bottom', 'top', 'right', 'left'],
  top: ['top', 'bottom', 'right', 'left'],
  right: ['right', 'left', 'top', 'bottom'],
  left: ['left', 'right', 'top', 'bottom'],
};

export function getPosition({
  tip,
  trigger,
  placement = 'top',
  buffer = 16,
  offset = 8,
  ...rest
}: GetPositionArgs): PositionResult {
  if (!trigger || !tip) {
    return getPlacement({ buffer, placement, offset });
  }
  const triggerRect = trigger.getBoundingClientRect() as CompleteClientRect;
  const tipRect = tip.getBoundingClientRect() as CompleteClientRect;
  const spaceChecks = {
    top: (t) => t.height + offset + buffer < triggerRect.y,
    right: (t) =>
      t.width + offset + buffer < window.innerWidth - (triggerRect.x + triggerRect.width),
    bottom: (t) =>
      t.height + offset + buffer <
      window.innerHeight - (triggerRect.y + triggerRect.height),
    left: (t) => t.width + offset + buffer < triggerRect.x,
  };
  const firstFit = cascade[placement].find((side) =>
    spaceChecks[side](tipRect)
  ) as Placement;

  return {
    placement: firstFit,
    ...getPlacement({
      ...rest,
      buffer,
      placement: firstFit,
      offset,
      tip: tipRect,
      trigger: triggerRect,
    }),
  };
}
