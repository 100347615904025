import React from 'react';
import { cx } from 'emotion';

import { radioGroup } from './radio-group.styles';
import { radioGroupJub } from './radio-group.styles.jubjub';

export interface Props {
  className?: string;
  horizontal?: boolean;
  children: React.ReactNode;
}

/**
 * @deprecated Use RadioField from design-system
 */
export const RadioGroup = ({ className = '', horizontal = false, children, ...props }: Props) => {
  return (
    <div className={cx(className, radioGroupJub, radioGroup, horizontal && 'horizontal')} {...props}>
      {children}
    </div>
  );
};
