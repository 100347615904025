import { jubcss } from '../../../helpers/jub-css';
import { gray3, gray7, weaveBlue, weaveRed, weaveBlueLight } from '../../colors/colors';
import { css } from 'emotion';

export const inputStyleJub = jubcss(`
  font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
  height: 40px !important;
  width: 100% !important;

  padding: 0 0 0 15px !important;
  margin: 15px 0 0 0 !important;
  
  font-size: 13px !important;
  
  color: ${gray7} !important;
  background-color: white !important;
  border: 1px solid ${gray3} !important;
  
  border-radius: 5px !important;
  display: block !important;
  outline: none !important;
  position: relative !important;
  transition: border-color 0.3s ease-in !important;

  &::placeholder {
    color: white !important;
  }

  .error-style & {
    border-color: ${weaveRed} !important;
  }

  &.active, &:active,
  &.focus, &:focus {
    border-color: ${weaveBlue} !important;
  }

  label: inputStyle;
`);

export const inputIconLeftStyleJub = jubcss(`
  padding-left: 38px !important;
`);

export const iconLeftStyleJub = jubcss(`
  pointer-events: none !important;
  position: absolute !important;
  left: 10px !important;
  top: 18px !important;
  transform: translate(0, -50%) !important;
`);

export const inputIconRightStyleJub = css`
  padding-right: 30px !important;
`;

export const actionTextStyleJub = css`
  color: ${weaveBlue} !important;
  cursor: pointer !important;
  position: absolute !important;
  right: 10px !important;
  top: 12px !important;
  transition: color 300ms !important;

  :hover {
    color: ${weaveBlueLight} !important;
  }

  label: actionTextStyleJub;
`;
