import { useSpring } from 'react-spring';

type Props = {
  show?: boolean;
  remThreshold?: number;
};

export type AnimatedHeightProps = {
  style: { [key: string]: any };
};

/**
 * @deprecated import from design-system
 */
export function useAnimatedHeight({
  show = false,
  remThreshold = 40,
}: Props): AnimatedHeightProps {
  const props = useSpring({
    maxHeight: show ? `${remThreshold}rem` : '0rem',
    config: { mass: 1, tension: 200, friction: 26 },
  });
  return {
    style: {
      overflow: 'hidden',
      position: 'relative',
      ...props,
    },
  };
}
