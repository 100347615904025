import React from 'react';
import { cx } from 'emotion';

import { radioStyle } from './radio.styles';
import { radioStyleJub } from './radio.styles.jubjub';

export interface RadioProps {
  className?: string;
}

/**
 * @deprecated Replaced by RadioField with useForm, useFormField in design-system
 */
export const Radio = ({ className = '', ...props }: React.InputHTMLAttributes<HTMLInputElement> & RadioProps) => {
  return <input type="radio" className={cx(radioStyle, radioStyleJub, className)} {...props} />;
};
