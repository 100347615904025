import { jubcss } from '../../../helpers/jub-css';

export const labelJub = jubcss(`
  font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
  text-transform: inherit;
  font-weight: normal;
  vertical-align: sub;

  label: label;
`);
