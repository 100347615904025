import React from 'react';
import { cx } from 'emotion';

import { Body, Subtitle } from '../../foundation/typography/typography.component';
import { Svg } from '../../svgs/svg.component';

import { SvgList } from '../../svgs/svg-list';
import { listItemWrapper, iconStyle } from './list.styles';

type Icons = keyof typeof SvgList;

interface Props {
  title?: string;
  subtitle?: React.ReactNode;
  icon?: Icons | '';
  leftSide?: React.ReactNode;
  middle?: React.ReactNode;
  rightSide?: React.ReactNode;
  className?: any;
  iconClassName?: any;
  textClassName?: any;
  props?: any;
  onClick?: () => void;
}

export const ListItem = ({ title, icon, subtitle, leftSide, middle, rightSide, className = '', ...props }: Props) => {
  let svgClassName = '';
  if (icon === '') {
    svgClassName = 'svg-left-pad';
  } else if (!icon && !leftSide) {
    svgClassName = 'no-svg';
  }

  const classNames = cx('list-item-wv', listItemWrapper, !subtitle && 'no-subtitle', svgClassName, className);
  const leftSideOrIcon = !!icon || !!leftSide;
  return (
    <div className={classNames} {...props}>
      {leftSideOrIcon && (
        <div className="left-side">
          {icon && (
            <div className={iconStyle}>
              <Svg className="gray" icon={icon} />
            </div>
          )}
          {!icon && leftSide && <div className={iconStyle}>{leftSide}</div>}
        </div>
      )}
      {middle || (
        <div className="center-side">
          <Subtitle title={title}>{title}</Subtitle>
          {subtitle && <Body>{subtitle}</Body>}
        </div>
      )}
      {rightSide && <div className="right-side">{rightSide}</div>}
    </div>
  );
};
