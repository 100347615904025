import React from 'react';
import moment, { Moment } from 'moment';
import { cx } from 'emotion';
import { Label, Radio } from '../../foundation/form-elements';
import { getMeridiem, setMeridiem } from '../../helpers/moment-helper/moment-helper';
import * as styles from './time-input.styles';
import { inputStyle } from '../../foundation/form-elements/input/input.styles';
import { inputStyleJub } from '../../foundation/form-elements/input/input.styles.jubjub';

interface Props {
  className?: string;
  time?: Moment;
  onChange?: (time: Moment) => void;
}
interface State {
  hour: string;
  minute: string;
  meridiem?: string;
}

// if #locale() is not 'en', this will still be valid as other
// locales such as 'es' spanish will pull the proper meridiem abbreviation.
const AM = getMeridiem(moment().hour(1));
const PM = getMeridiem(moment().hour(13));

const isNumericKey = (code: number) => (code >= 48 && code <= 57) || (code >= 96 && code <= 105);
const allowedKeys: number[] = [8, 9, 13, 16, 17, 18, 37, 38, 39, 40, 46, 91, 93];
const isAllowedKey = (code: number): boolean => allowedKeys.includes(code) || isNumericKey(code);

const formatMinutes = (minute: number): string => {
  if (!minute) {
    return '00';
  } else if (minute < 10) {
    return `0${minute}`;
  } else {
    return `${minute}`;
  }
};

const createState = (date?: Moment) => ({
  hour: date ? date.format('h') : '',
  minute: date ? formatMinutes(date.minute()) : '',
  meridiem: date ? getMeridiem(date) : undefined,
});

const stripLeadingZero = (str: string): string => (str.length > 1 ? str.replace(/^0/, '') : str);

const statesAreEqual = (a: State, b: State) => {
  const keys = ['hour', 'minute', 'meridiem'];
  for (var i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (stripLeadingZero(a[key]) !== stripLeadingZero(b[key])) {
      return false;
    }
  }
  return true;
};

/**
 * @deprecated Replaced by TimeField in design-system
 */
export class TimeInput extends React.Component<Props, State> {
  private randomId: number = Math.random();

  constructor(props: Props) {
    super(props);
    this.state = createState(props.time);
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.time !== prevProps.time) {
      const nextState = createState(this.props.time);

      if (!statesAreEqual(this.state, nextState)) {
        this.setState(nextState);
      }
    }
  }

  public render() {
    return (
      <div className={cx(styles.timeInput, this.props.className)}>
        <Label className={styles.time} htmlFor="hour">
          Hour
        </Label>
        <Label className={styles.time} htmlFor="minute">
          Minute
        </Label>
        <Label className={styles.meridiem} htmlFor="am">
          AM
        </Label>
        <Label className={styles.meridiem} htmlFor="pm">
          PM
        </Label>
        <input
          type="text"
          name="hour"
          className={cx(inputStyle, inputStyleJub, styles.input)}
          onBlur={this.handleHourBlur}
          onChange={this.handleHourChange}
          onKeyDown={this.handleKeyDown}
          value={this.state.hour !== undefined ? this.state.hour : ''}
        />
        <input
          type="text"
          name="minute"
          className={cx(inputStyle, inputStyleJub, styles.input)}
          value={this.state.minute}
          onBlur={this.handleMinuteBlur}
          onChange={this.handleMinuteChange}
          onKeyDown={this.handleKeyDown}
        />
        <Radio
          id={`am_${this.randomId}`}
          value={AM}
          name={`am_${this.randomId}`}
          checked={this.state.meridiem === AM}
          onChange={this.handleMeridiemChange}
        />
        <Radio
          id={`pm_${this.randomId}`}
          value={PM}
          name={`pm_${this.randomId}`}
          checked={this.state.meridiem === PM}
          onChange={this.handleMeridiemChange}
        />
      </div>
    );
  }

  private handleKeyDown = (evt) => {
    if (!evt.metaKey && !evt.ctrlKey && !isAllowedKey(evt.keyCode)) {
      evt.preventDefault();
      return;
    }
  };

  private handleHourChange = (evt: any) => {
    const value = evt.target.value;
    if (value === '') {
      return this.setState({ hour: value });
    }

    if (value.length > 2) {
      return;
    }

    let hour = +value;
    if (hour > 12) {
      hour = 12;
    } else if (hour < 1) {
      hour = 1;
    }
    let nextState: { hour: string; meridiem?: string } = { hour: `${hour}` };

    if (!this.state.meridiem) {
      if (hour > 7 && hour < 12) {
        // 8-11 is AM
        nextState.meridiem = 'am';
      } else {
        // 1-7 is PM
        nextState.meridiem = 'pm';
      }
    }

    this.setState(nextState, this.handleOnChange);
  };

  private handleMinuteChange = (evt: any) => {
    const value = evt.target.value;
    if (value === '') {
      return this.setState({ minute: value });
    }

    if (value.length > 2) {
      return;
    }

    let minute = +value;
    if (minute > 59) {
      minute = 59;
    } else if (minute < 0) {
      minute = 0;
    }

    this.setState({ minute: `${value.length > 1 && minute < 10 ? '0' : ''}${minute}` }, this.handleOnChange);
  };

  private handleHourBlur = (evt) => {
    const value = evt.target.value;
    if (!value.trim()) {
      this.setState({ hour: '1' }, this.handleOnChange);
    }
  };

  private handleMinuteBlur = (evt) => {
    const value = evt.target.value;
    if (!value) {
      this.setState({ minute: '00' }, this.handleOnChange);
    } else if (value.length < 2 && +value < 10) {
      this.setState({ minute: `0${value}` });
    }
  };

  private getMomentTime = () => {
    let time = this.props.time ? this.props.time.clone() : moment();
    time.hour(+this.state.hour || moment().hour());
    time.minute(+this.state.minute);
    time = setMeridiem(time, this.state.meridiem || 'am');
    return time.clone();
  };

  private handleMeridiemChange = (evt: any) => {
    const meridiem = evt.target.value;
    this.setState({ meridiem: meridiem }, this.handleOnChange);
  };

  private handleOnChange = () => {
    if (this.props.onChange && this.state.hour && this.state.meridiem !== undefined) {
      this.props.onChange(this.getMomentTime());
    }
  };
}
