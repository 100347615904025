import { jubcss } from '../../helpers/jub-css';
import {
  gray1,
  gray5,
  gray7,
  gray9,
  weaveBlue,
  weaveBlueDark,
  weaveBlueLight,
  weaveRed,
  weaveGreen,
  gray3,
  gray4,
} from '../colors/colors';

export const buttonFont = jubcss(`
  font-family: 'Proxima Nova', sans-serif !important;
  font-weight: 400!important;
`);

const colorMap = {
  blue: weaveBlue,
  green: weaveGreen,
  gray: gray3,
  red: weaveRed,
};

const fillOptions = (fill, color, disabled) => {
  if (fill === 'outline') {
    return jubcss(`
      border: 2px solid ${color === 'gray' ? colorMap[color] + '99' : colorMap[color]} !important;
      background: transparent !important;
      color: ${color === 'gray' ? gray4 : colorMap[color]} !important;
      ${
        disabled
          ? `cursor: not-allowed !important;
        border-color: ${colorMap[color]}99 !important;
        
        `
          : `&:hover {
          color: white !important;
          background-color: ${colorMap[color]} !important;
        }
        `
      }
    `);
  } else {
    return jubcss(`
      border: none!important;
      background-color: ${colorMap[color]} !important;
      color:white !important;
      ${
        disabled
          ? `cursor: not-allowed !important;
          background-color: ${colorMap[color]}99 !important;  
        `
          : `&:hover {
          background-color: ${colorMap[color]}99 !important;
        }`
      }
    `);
  }
};

const sizeOptions = (size) => {
  if (size === 'small') {
    return jubcss(`
      height: 28px !important;
      padding: 0 16px !important;
      font-size: 15px !important;
    `);
  } else if (size === 'large') {
    return jubcss(`
      height: 56px !important;
      padding: 10px 24px !important; 
      font-size: 17px !important;
    `);
  } else {
    return jubcss(`
      height: 36px !important;
      padding: 0 24px !important; 
      font-size: 17px !important;
    `);
  }
};

export const buttonStyleJub = (props) => {
  const { color, fill, variant, fullWidth, size, disabled } = props;
  return jubcss(`
    ${buttonFont};
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    border-radius: ${variant === 'rounded' ? '60px' : '0px'} !important;
    cursor: pointer !important;
    transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in !important;
    ${fullWidth ? `width:100% !important;` : `width:inherit !important;`}
    ${sizeOptions(size)}
    ${fillOptions(fill, color, disabled)}

    &:active, &:focus {
      outline: 0 !important;
    }

    label: buttonStyleJub;
  `);
};

export const wizardButtonStyleJub = jubcss(`
  font-family: 'Proxima Nova', sans-serif !important;  
  width: 100% !important;
  box-sizing: border-box !important;
  height: 56px !important;
  border: none !important;
  color: white !important;
  font-size: 17px !important;
  cursor: pointer !important;
  text-align: center !important;
  transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
 
  background-color: ${weaveBlue};
  &.hover, &:hover {
    background-color: ${weaveBlueLight} !important;
  }
  &.active, &:active {
    background-color: ${weaveBlueDark} !important;
  }
  &.red {
    background-color: ${weaveRed} !important;
    &.hover, &:hover {
      background-color: ${gray5} !important;
    }
    &.active, &:active {
      background-color: ${gray7} !important;
    }
  }
  &.gray5 {
    background-color: ${gray5} !important;
    &.hover, &:hover {
      background-color: ${gray7} !important;
    }
    &.active, &:active {
      background-color: ${gray9} !important;
    }
  }
  &:disabled,
  &[disabled] {
    cursor: not-allowed !important;
    background-color: ${gray1} !important;
  }
  label: next-step-button !important;
`);
