import { css } from 'emotion';
import { weaveBlue, weaveBlueDark } from '../../foundation/colors/colors';

export const loading = css`
  display: flex;
  justify-content: center;
  align-items: center;

  label: loading;
`;

export const defaultPosition = css`
  display: block;
  text-align: center;

  label: defaultPosition;
`;

export const positionFullPageClass = css`
  position: absolute;
  background: rgb(206, 212, 214, 0.6);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  label: positionFullPageClass;
`;

// https://loading.io/css/
export const ldsRing = css`
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 6px;
    border: 6px solid ${weaveBlue};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${weaveBlue} transparent transparent transparent;
  }
  &.sm {
    .lds-ring div {
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      display: block;
      position: absolute;
      margin: 4px;
      border: 2px solid ${weaveBlue};
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: ${weaveBlue} transparent transparent transparent;
    }
  }
  &.full-page {
    .lds-ring div {
      border: 6px solid ${weaveBlueDark};
      border-color: ${weaveBlueDark} transparent transparent transparent;
    }
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  label: ldsRing;
`;
