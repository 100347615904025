/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const LogOutIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M17.7784271,11 L15.2604076,8.48198052 C14.7722523,7.99382515 14.7722523,7.20236893 15.2604076,6.71421356 C15.748563,6.2260582 16.5400192,6.2260582 17.0281746,6.71421356 L21.6243687,11.3104076 C21.8684464,11.5544853 21.9904852,11.8743882 21.9904852,12.1942911 C21.9904852,12.514194 21.8684464,12.8340969 21.6243687,13.0781746 L17.0281746,17.6743687 C16.5400192,18.162524 15.748563,18.162524 15.2604076,17.6743687 C14.7722523,17.1862133 14.7722523,16.3947571 15.2604076,15.9066017 L17.6670094,13.5 L9.5,13.5 C8.80964406,13.5 8.25,12.9403559 8.25,12.25 C8.25,11.5596441 8.80964406,11 9.5,11 L17.7784271,11 Z M4.5,19.75 L9.25,19.75 C9.94035594,19.75 10.5,20.3096441 10.5,21 C10.5,21.6903559 9.94035594,22.25 9.25,22.25 L4.25,22.25 C3.00735931,22.25 2,21.2426407 2,20 L2,4 C2,2.75735931 3.00735931,1.75 4.25,1.75 L9.25,1.75 C9.94035594,1.75 10.5,2.30964406 10.5,3 C10.5,3.69035594 9.94035594,4.25 9.25,4.25 L4.5,4.25 L4.5,19.75 Z" />
  </SvgIcon>
  );