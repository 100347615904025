/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const SupportIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M5,16.9342105 L5,16.94 C2.790861,16.94 1,15.2610678 1,13.19 C1,11.496027 2.19808104,10.0643917 3.84318232,9.5992135 C4.44082646,5.31504737 7.84274286,2 12,2 C16.1572571,2 19.5591735,5.31504737 20.1568177,9.5992135 C21.801919,10.0643917 23,11.496027 23,13.19 C23,14.8518219 21.8469658,16.2611698 20.25,16.7532601 L20.25,16.9276316 L20.2422302,17.0667871 C19.9337398,19.8206563 18.1449696,21.2880742 15.5229172,21.7248587 C14.611372,21.876705 13.8038668,21.8961478 12.6096649,21.8538238 C12.1115787,21.8361709 12.1153457,21.8362891 12,21.8362891 C11.3096441,21.8362891 10.75,21.276645 10.75,20.5862891 C10.75,19.8959331 11.3096441,19.3362891 12,19.3362891 C12.1611742,19.3362891 12.1440371,19.3357517 12.6982126,19.3553924 C13.7361866,19.3921796 14.4089111,19.3759819 15.1121249,19.2588398 C16.7083869,18.9929326 17.5649504,18.3054596 17.75,16.8534035 L17.75,10.9473684 C17.75,7.36149201 15.1477543,4.5 12,4.5 C8.85224573,4.5 6.25,7.36149201 6.25,10.9473684 L6.25,15.6842105 C6.25,16.3745665 5.69035594,16.9342105 5,16.9342105 Z" />
  </SvgIcon>
  );