import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { muiTheme } from './muiTheme';
import { weaveTheme } from './weaveTheme';
import { CssBaseline } from '@material-ui/core';

/**
 * @deprecated import from design-system
 */
export const WeaveThemeProvider = ({ children }) => (
  <ThemeProvider theme={muiTheme}>
    <StylesProvider injectFirst>
      <CssBaseline />
      <EmotionThemeProvider theme={weaveTheme}>{children}</EmotionThemeProvider>
    </StylesProvider>
  </ThemeProvider>
);
