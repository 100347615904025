/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const ReviewsDashIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M9.23431211,17.8662278 C8.57437192,18.2131789 7.75812526,17.9594511 7.41117417,17.2995109 C7.27301642,17.0367193 7.22534161,16.7357124 7.27553035,16.4430896 L7.80372974,13.3634509 L5.56624123,11.1824386 C5.0323384,10.662012 5.02141379,9.80730906 5.54184043,9.27340623 C5.74907704,9.06080332 6.0206193,8.92244563 6.31442929,8.87975254 L9.40656235,8.43043931 L10.7894063,5.62849144 C11.1193764,4.959899 11.9288713,4.68539135 12.5974637,5.01536144 C12.8637007,5.14675727 13.0791979,5.36225442 13.2105937,5.62849144 L14.5934376,8.43043931 L17.6855707,8.87975254 C18.4234063,8.98696633 18.9346262,9.67201418 18.8274125,10.4098497 C18.7847194,10.7036597 18.6463617,10.975202 18.4337588,11.1824386 L16.1962703,13.3634509 L16.7244696,16.4430896 C16.850507,17.1779438 16.3569634,17.8758343 15.6221092,18.0018717 C15.3294864,18.0520604 15.0284795,18.0043856 14.7656879,17.8662278 L12,16.4122197 L9.23431211,17.8662278 Z M10.1334523,14.5690826 L12,13.5877803 L13.8665477,14.5690826 L13.5100688,12.4906511 L15.0201377,11.0186978 L12.9332739,10.7154587 L12,8.82443933 L11.0667261,10.7154587 L8.97986233,11.0186978 L10.4899312,12.4906511 L10.1334523,14.5690826 Z M4,0.75 L20,0.75 C21.7949254,0.75 23.25,2.20507456 23.25,4 L23.25,20 C23.25,21.7949254 21.7949254,23.25 20,23.25 L4,23.25 C2.20507456,23.25 0.75,21.7949254 0.75,20 L0.75,4 C0.75,2.20507456 2.20507456,0.75 4,0.75 Z M4,3.25 C3.58578644,3.25 3.25,3.58578644 3.25,4 L3.25,20 C3.25,20.4142136 3.58578644,20.75 4,20.75 L20,20.75 C20.4142136,20.75 20.75,20.4142136 20.75,20 L20.75,4 C20.75,3.58578644 20.4142136,3.25 20,3.25 L4,3.25 Z" />
  </SvgIcon>
  );