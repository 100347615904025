import React from 'react';
import { cx } from 'emotion';

import { isSafari, isIE, isChrome, isFirefox, isEdge, getBrowser } from '../../helpers/browser-detection';
import { Title, Subtitle, Strong, Body } from '../../foundation';
import { Browsers, userFriendlyName } from '../../helpers/browser-detection';
import { Svg } from '../../svgs';
import { browserStyle, logoXL } from './browser-detection.styles';

export const supportedBrowsers = ['chrome', 'firefox', 'safari', 'edge'];

interface Props {
  allowedBrowsers?: Browsers[];
  logBrowserInfo?: boolean;
}

interface State {
  browser: 'chrome' | 'firefox' | 'safari' | 'edge' | 'ie' | 'unknown';
}

/**
 * @deprecated Not replaced in design-system
 */
export class BrowserDetection extends React.Component<Props, State> {
  componentWillMount() {
    this.setBrowser();

    if (this.props.logBrowserInfo) {
      this.logBrowserInfo();
    }
  }

  logBrowserInfo() {
    const { userAgent } = window.navigator;
    window.setTimeout(() => {
      console.log('UA', userAgent);
      console.log('IE:', isIE());
      console.log('chrome:', isChrome());
      console.log('firefox:', isFirefox());
      console.log('edge:', isEdge());
      console.log('safari:', isSafari());
    }, 1000);
  }

  setBrowser() {
    let browser: State['browser'] = getBrowser();
    this.setState({ browser });
  }

  render() {
    const browsers = this.props.allowedBrowsers || supportedBrowsers;
    // @ts-ignore: string[] does have #includes()
    if (browsers.includes(this.state.browser)) {
      return this.props.children;
    } // else (browser not supported)

    return (
      <div className={cx('browser-detection', browserStyle)}>
        <div className="wrapper">
          <Svg icon="WeaveLogo" className={cx(logoXL, 'gray')} viewBox="0 0 90 90" />
          <Title className="mb-sm">Oops! You are using a browser we don't support.</Title>
          <Subtitle>
            It looks like you are using <Strong className="uppercase">{userFriendlyName(this.state.browser)}</Strong>{' '}
            which isn't one of our supported browsers.
          </Subtitle>
          <br />
          <Body>We restrict certain browsers to ensure your experience with Weave is optimal.</Body>
          <br />
          {/*
            // @ts-ignore: b is proper type */}
          <Body>
            We currently support the following browser(s):
            <Strong className="capitalize">{browsers.map((b: Browsers) => ` ${userFriendlyName(b)}`)}</Strong>.
          </Body>
        </div>
      </div>
    );
  }
}
