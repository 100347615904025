/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const CarrotLeftIconSmall: React.FC<SvgIconProps> = props => (
  <SvgIcon size={16} {...props} viewBox="0 0 16 16">
    <path d="M7.07106781,11.0710678 L14.0710678,11.0710678 C14.6233526,11.0710678 15.0710678,11.5187831 15.0710678,12.0710678 C15.0710678,12.6233526 14.6233526,13.0710678 14.0710678,13.0710678 L6.07106781,13.0710678 C5.79492544,13.0710678 5.54492544,12.959139 5.36396103,12.7781746 C5.18299662,12.5972102 5.07106781,12.3472102 5.07106781,12.0710678 L5.07106781,4.07106781 C5.07106781,3.51878306 5.51878306,3.07106781 6.07106781,3.07106781 C6.62335256,3.07106781 7.07106781,3.51878306 7.07106781,4.07106781 L7.07106781,11.0710678 Z" transform="translate(10.071068, 8.071068) scale(-1, 1) rotate(-135.000000) translate(-10.071068, -8.071068) " />
  </SvgIcon>
  );