import { css } from 'emotion';

export const weaveLogoStyle = css`
  label: weaveLogoStyles;

  &.sm {
    width: 20px;
    height: 20px;
  }

  &.md {
    width: 40px;
    height: 40px;
  }

  &.lg {
    width: 120px;
    height: 120px;
  }

  &.xl {
    width: 160px;
    height: 160px;
  }
`;
