/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const VoicemailIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M10.2230013,14.75 L13.7769987,14.75 C13.2842538,13.9502329 13,13.0083119 13,12 C13,9.10050506 15.3505051,6.75 18.25,6.75 C21.1494949,6.75 23.5,9.10050506 23.5,12 C23.5,14.8994949 21.1494949,17.25 18.25,17.25 C18.1684349,17.25 18.0873042,17.2481399 18.0066481,17.2444599 L18,17.25 L5.75,17.25 C2.85050506,17.25 0.5,14.8994949 0.5,12 C0.5,9.10050506 2.85050506,6.75 5.75,6.75 C8.64949494,6.75 11,9.10050506 11,12 C11,13.0083119 10.7157462,13.9502329 10.2230013,14.75 Z M5.75,14.75 C7.26878306,14.75 8.5,13.5187831 8.5,12 C8.5,10.4812169 7.26878306,9.25 5.75,9.25 C4.23121694,9.25 3,10.4812169 3,12 C3,13.5187831 4.23121694,14.75 5.75,14.75 Z M18.25,14.75 C19.7687831,14.75 21,13.5187831 21,12 C21,10.4812169 19.7687831,9.25 18.25,9.25 C16.7312169,9.25 15.5,10.4812169 15.5,12 C15.5,13.5187831 16.7312169,14.75 18.25,14.75 Z" />
  </SvgIcon>
  );