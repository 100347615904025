/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const CarrotDownIconSmall: React.FC<SvgIconProps> = props => (
  <SvgIcon size={16} {...props} viewBox="0 0 16 16">
    <path d="M5.07106781,9.07106781 L12.0710678,9.07106781 C12.6233526,9.07106781 13.0710678,9.51878306 13.0710678,10.0710678 C13.0710678,10.6233526 12.6233526,11.0710678 12.0710678,11.0710678 L4.07106781,11.0710678 C3.79492544,11.0710678 3.54492544,10.959139 3.36396103,10.7781746 C3.18299662,10.5972102 3.07106781,10.3472102 3.07106781,10.0710678 L3.07106781,2.07106781 C3.07106781,1.51878306 3.51878306,1.07106781 4.07106781,1.07106781 C4.62335256,1.07106781 5.07106781,1.51878306 5.07106781,2.07106781 L5.07106781,9.07106781 Z" transform="translate(8.071068, 6.071068) rotate(-45.000000) translate(-8.071068, -6.071068) " />
  </SvgIcon>
  );