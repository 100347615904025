import React, { HTMLAttributes, Children } from 'react';
import { WeaveTheme, Text } from '../..';
import { css } from '@emotion/core';
import { Node, Text as SlateText } from 'slate';
import urlRegex from 'url-regex';

import { useTemplateEditorValue } from '../message-template';
import { TAG_ELEMENT_TYPE, TagType } from '../message-template.models';

const renderNode = (node: Node): React.ReactNode => {
  if (SlateText.isText(node)) {
    return Node.string(node);
  } else {
    switch (node.type) {
      case TAG_ELEMENT_TYPE:
        if (node.invalid) {
          // don't render invalid tags
          return null;
        }
        const tag = node.tag as TagType;
        const isUrl = urlRegex({ exact: true, strict: false }).test(tag.value);
        return (
          <Text
            css={(theme: WeaveTheme) =>
              isUrl && {
                color: theme.colors.weaveBlue,
              }
            }
            as="span"
          >
            {tag.value}
          </Text>
        );
      default:
        // do nothing - unexpected node type
        return '';
    }
  }
};

const renderLine = (nodes: Node[]): React.ReactElement[] => {
  if (!nodes.map) {
    console.error('Rendering line is not an array');
    return [<></>];
  }
  return nodes.map((node, idx) =>
    node.children ? (
      <React.Fragment key={idx}>
        {renderNode(node)}
        {renderLine(node.children as Node[])}
      </React.Fragment>
    ) : (
      <React.Fragment key={idx}>{renderNode(node)}</React.Fragment>
    )
  );
};

const isBlankRow = (node: Node) => {
  const children = node.children as Node[];

  return (
    children.length === 1 &&
    SlateText.isText(children[0]) &&
    children[0].text.trim() === ''
  );
};

const trimEmptyRowsEnd = (nodes: Node[]): Node[] => {
  // find last non blank node
  for (let i = nodes.length - 1; i > -1; i--) {
    if (!isBlankRow(nodes[i])) {
      return nodes.slice(0, i + 1);
    }
  }

  //all blank
  return [];
};

/**
 * Serializes the editor nodes into a plain text template with the tag keys.
 * @param nodes
 */
const renderPreview = (nodes: Node[]): React.ReactNode => {
  // map the block elements to be lines
  return trimEmptyRowsEnd(nodes).map((node, idx) => {
    const line = renderLine(node.children as Node[]);
    const isBlankLine = line.length === 1 && line[0]?.props?.children === '';
    return isBlankLine ? (
      <br key={idx} />
    ) : (
      <Text key={idx} css={{ margin: 0 }}>
        {line}
      </Text>
    );
  });
};

type TemplatePreviewProps = HTMLAttributes<HTMLDivElement> & {
  noPreviewText?: string;
};

export const TemplatePreview = ({
  noPreviewText = '',
  ...props
}: TemplatePreviewProps) => {
  const nodes = useTemplateEditorValue();

  const preview = renderPreview(nodes);
  const numberOfPreviewComponents = Children.count(preview);

  return (
    <div
      css={(theme: WeaveTheme) => css`
        background: ${theme.colors.gray[200]};
        border-radius: ${theme.borderRadius.small};
        padding: ${theme.spacing(2)};
      `}
      {...props}
    >
      {numberOfPreviewComponents > 0 && preview}
      {numberOfPreviewComponents < 1 && !!noPreviewText && (
        <Text color="light" css={{ margin: 0 }}>
          {noPreviewText}
        </Text>
      )}
    </div>
  );
};
