/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const DocumentIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M12.25,4.25 L6.25,4.25 L6.25,19.75 L17.75,19.75 L17.75,9.75 L15.5,9.75 C13.7050746,9.75 12.25,8.29492544 12.25,6.5 L12.25,4.25 Z M14.75,4.52395872 L14.75,6.5 C14.75,6.91421356 15.0857864,7.25 15.5,7.25 L17.4497588,7.25 L14.75,4.52395872 Z M6,1.75 L14.1658921,1.75 C15.0333023,1.75 15.8647206,2.0967463 16.475094,2.71306172 L19.3092019,5.57475992 C19.9118978,6.1833232 20.25,7.00519853 20.25,7.86169821 L20.25,20 C20.25,21.2426407 19.2426407,22.25 18,22.25 L6,22.25 C4.75735931,22.25 3.75,21.2426407 3.75,20 L3.75,4 C3.75,2.75735931 4.75735931,1.75 6,1.75 Z" />
  </SvgIcon>
  );