import { cx } from 'emotion';
import React from 'react';
import moment, { Moment } from 'moment';
import { CalendarDay, Selection } from './calendar-day.component';
import * as styles from './calendar.styles';
import { Button } from '../../foundation/buttons/button.component';
import { Title, Subtitle, Option } from '../../foundation/';
import { Select } from '../../foundation/form-elements/select/select.component';
import { Svg } from '../../svgs/svg.component';
import { TimeInput } from '../time-input/time-input.component';
import { months, sequenceGenerator } from '../../helpers/moment-helper/moment-helper';

export interface DateRange {
  start?: Moment;
  end?: Moment;
}

export type DateTimeRange = Moment | DateRange;

interface Props {
  clearButton?: boolean;
  className?: string;
  onChange?: (value?: DateTimeRange) => void;
  selectionType?: SelectionType;
  setButton?: boolean;
  value?: DateTimeRange;
  visibleMonth?: Moment;
  disableMonthClick?: boolean;
  selectYearMonth?: boolean;
  minDate?: Moment;
  maxDate?: Moment;
}

interface State {
  selectedStart: Moment | undefined;
  selectedEnd: Moment | undefined;
  visibleMonth: Moment;
  minDateValue: Moment | undefined;
  maxDateValue: Moment | undefined;
}

export enum SelectionType {
  Date,
  DateTime,
  Range,
}

/**
 * @deprecated Replaced by useCalendar, DatePickerField, RangePickerField, and other Calendar layouts in design-system
 */
export class Calendar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.createState();
  }

  public componentDidUpdate(prevProps: Props) {
    // Should change this to actually compare value changes, this will probably always be true
    if (this.props.value !== prevProps.value) {
      this.setState(this.createState());
    }
  }

  public render() {
    const { visibleMonth, minDateValue, maxDateValue } = this.state;
    let yearSelectOptions: string[] = [];
    let disableNextMonthNavigate = this.props.disableMonthClick;
    let disablePrevMonthNavigate = this.props.disableMonthClick;

    if (this.props.selectYearMonth && maxDateValue && minDateValue) {
      disableNextMonthNavigate = this.props.disableMonthClick || visibleMonth.isSameOrAfter(maxDateValue, 'month');
      disablePrevMonthNavigate = this.props.disableMonthClick || visibleMonth.isSameOrBefore(minDateValue, 'month');
      yearSelectOptions = sequenceGenerator(maxDateValue.year(), minDateValue.year(), -1);
    }

    return (
      <div className={cx(styles.calendar, this.props.className)}>
        <div className={styles.toolbar}>
          <div className={styles.headerArrow}>
            {!disablePrevMonthNavigate && (
              <Svg
                icon="CarrotLeft"
                className={cx(styles.carrot, 'interactive gray lg')}
                onClick={this.handlePreviousMonthClick}
              />
            )}
          </div>
          <Title>{visibleMonth.format('MMMM YYYY')}</Title>
          <div className={styles.headerArrow}>
            {!disableNextMonthNavigate && (
              <Svg
                icon="CarrotRight"
                className={cx(styles.carrot, 'interactive gray lg')}
                onClick={this.handleNextMonthClick}
              />
            )}
          </div>
        </div>
        {this.props.selectYearMonth && (
          <div className={styles.yearMonthDropdownStyle}>
            <div>
              <Select
                placeholder="Year"
                value={visibleMonth.year()}
                onChange={(value) => this.handleYearSelect(value)}
                name="calendar-year"
              >
                {yearSelectOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <Select
                placeholder="Month"
                value={months[visibleMonth.month()]}
                onChange={(value) => this.handleMonthSelect(months.indexOf(value))}
                name="calendar-month"
              >
                {months.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        )}

        <div className={styles.content}>
          {this.getCalendarHeader()}
          {this.getCalendarDays()}
        </div>

        {this.props.selectionType === SelectionType.DateTime && (
          <TimeInput className={styles.timeInput} onChange={this.handleTimeChange} time={this.state.selectedStart} />
        )}

        <div className={styles.buttons}>
          {this.props.clearButton && (
            <Button className={cx(styles.button, 'cancel')} onClick={this.handleClearClick}>
              Clear
            </Button>
          )}
          {this.props.setButton && (
            <Button className={styles.button} disabled={!this.isStateValid()} onClick={this.handleSetClick}>
              Set
            </Button>
          )}
        </div>
      </div>
    );
  }

  private createState = () => {
    let start: Moment | undefined = undefined;
    let end: Moment | undefined = undefined;
    let visibleMonth: Moment | undefined = undefined;
    let minDateValue: Moment | undefined = undefined;
    let maxDateValue: Moment | undefined = undefined;

    if (this.props.value) {
      if (this.isDateRange(this.props.value)) {
        start = this.props.value.start ? this.props.value.start.clone() : undefined;
        end = this.props.value.end ? this.props.value.end.clone() : undefined;
      }

      if (this.isMoment(this.props.value)) {
        start = this.props.value.clone();
      }
    }
    if (this.props.visibleMonth) {
      visibleMonth = this.props.visibleMonth.clone();
    } else {
      visibleMonth = start ? start.clone() : moment();
    }

    if (this.props.selectYearMonth) {
      minDateValue = this.props.minDate ? this.props.minDate : moment('1920');
      maxDateValue = this.props.maxDate ? this.props.maxDate : moment().add(5, 'years');
    }

    return {
      selectedStart: start,
      selectedEnd: end,
      visibleMonth,
      minDateValue,
      maxDateValue,
    };
  };

  private isDateRange = (item: any): item is DateRange => {
    let test = item as DateRange;
    if (test.start || test.end) {
      return true;
    }
    return false;
  };

  private isMoment = (item: any): item is Moment => {
    let test = item as Moment;
    if (!!test.isValid) {
      return true;
    }
    return false;
  };

  private getCalendarHeader = () => {
    return moment.weekdaysMin().map((dayName, i) => (
      <Subtitle className={styles.header} key={i}>
        <span className={cx(styles.value, 'value')}>{dayName.charAt(0)}</span>
      </Subtitle>
    ));
  };

  private getCalendarDays = () => {
    const DAYS_IN_CALENDAR = 42; // 6 weeks are always visible.
    const calendarDays: Array<JSX.Element> = [];
    const startingMoment = this.getStartingMoment();

    for (let i = 0; i < DAYS_IN_CALENDAR; i++) {
      const date = startingMoment.clone().add(i, 'day');

      calendarDays.push(
        <CalendarDay
          date={date}
          selection={this.getCalendarDaySelection(date)}
          isDateVisible={date.month() === this.state.visibleMonth.month()}
          isDayBetweenMinMaxDate={
            !this.props.selectYearMonth
              ? null
              : date.isBetween(this.state.minDateValue, this.state.maxDateValue, 'day', '[]')
          }
          dateClicked={this.handleCalendarDayClick}
          key={i}
        />
      );
    }

    return calendarDays;
  };

  private getStartingMoment = () => {
    return this.state.visibleMonth
      .clone()
      .startOf('month')
      .startOf('week');
  };

  private getCalendarDaySelection = (date: Moment) => {
    const start = this.state.selectedStart;
    const end = this.state.selectedEnd;

    const dateIsSameAs = (otherDate: Moment | undefined) => {
      return otherDate ? date.isSame(otherDate, 'day') : false;
    };

    const dateIsBetween = (startDate: Moment | undefined, endDate: Moment | undefined) => {
      return startDate && endDate ? date.isBetween(startDate, endDate) : false;
    };

    if (this.props.selectionType === SelectionType.Range) {
      if (dateIsSameAs(start) && dateIsSameAs(end)) {
        return Selection.Only;
      }

      if (dateIsSameAs(start) && end === undefined) {
        return Selection.Only;
      }

      if (dateIsSameAs(end) && start === undefined) {
        return Selection.Only;
      }

      if (dateIsSameAs(start)) {
        return Selection.Start;
      }

      if (dateIsSameAs(end)) {
        return Selection.End;
      }

      if (dateIsBetween(start, end)) {
        return Selection.Range;
      }

      return Selection.None;
    }

    if (dateIsSameAs(start)) {
      return Selection.Only;
    }

    return Selection.None;
  };

  private setDate = (date: Moment | undefined) => {
    this.setState({
      selectedStart: date,
      selectedEnd: undefined,
    });
    if (date && this.props.onChange && !this.props.setButton) {
      this.props.onChange(date);
    }
  };

  private setDateRange = (start: Moment | undefined, end: Moment | undefined) => {
    const save = (startMoment: Moment | undefined, endMoment: Moment | undefined) => {
      this.setState({
        selectedStart: startMoment,
        selectedEnd: endMoment,
      });

      if (this.props.onChange && !this.props.setButton) {
        this.props.onChange({
          start: startMoment,
          end: endMoment,
        });
      }
    };

    if (start && end) {
      if (start.isAfter(end, 'day')) {
        save(end, start);
      } else if (start.isSame(end, 'day')) {
        save(start, undefined); // Since the days are the same, a range wasn't selected.
      } else {
        save(start, end);
      }
    } else if (start === undefined && end) {
      save(end, undefined);
    } else {
      save(start, end);
    }
  };

  private isStateValid = () => {
    if (this.props.selectionType === SelectionType.Range) {
      return this.state.selectedStart !== undefined && this.state.selectedEnd !== undefined;
    }
    return true;
  };

  private handleClearClick = () => {
    this.setState(
      {
        selectedStart: undefined,
        selectedEnd: undefined,
      },
      () => {
        if (!this.props.setButton && this.props.onChange) {
          this.props.onChange();
        }
      }
    );
  };

  private handleSetClick = () => {
    if (this.props.onChange === undefined) {
      return;
    }

    if (this.props.selectionType === SelectionType.Range && this.state.selectedStart && this.state.selectedEnd) {
      this.props.onChange({
        start: this.state.selectedStart,
        end: this.state.selectedEnd,
      });
    }

    if (this.props.selectionType !== SelectionType.Range) {
      this.props.onChange(this.state.selectedStart);
    }
  };

  private handleCalendarDayClick = (date: Moment) => {
    if (this.state.selectedStart !== undefined) {
      date.hour(this.state.selectedStart.hour());
      date.minute(this.state.selectedStart.minute());
    }

    if (this.props.selectionType === SelectionType.Range) {
      const start = this.state.selectedStart;
      const end = this.state.selectedEnd;

      if (start && end === undefined) {
        // The start has been picked, but the end hasn't.
        this.setDateRange(start, date);
      } else {
        // In all other cases, reset the range.
        this.setDateRange(date, undefined);
      }
    } else {
      this.setDate(date);
    }
  };

  private handleTimeChange = (time: Moment | undefined) => {
    this.setState({
      selectedStart: time,
    });

    if (this.props.onChange && !this.props.setButton) {
      this.props.onChange(this.state.selectedStart);
    }
  };

  private handlePreviousMonthClick = () => {
    this.setState({
      visibleMonth: this.state.visibleMonth.clone().subtract(1, 'month'),
    });
  };

  private handleNextMonthClick = () => {
    this.setState({
      visibleMonth: this.state.visibleMonth.clone().add(1, 'month'),
    });
  };

  private handleYearSelect = (value) => {
    this.setState({
      visibleMonth: this.state.visibleMonth.clone().year(value),
    });
  };

  private handleMonthSelect = (value) => {
    this.setState({
      visibleMonth: this.state.visibleMonth.clone().month(value),
    });
  };
}
