import React from 'react';
import MUIMenuItem from '@material-ui/core/MenuItem';

interface SelectItemProps {
  value?: string | number;
}

export const SelectItem: React.FC<SelectItemProps> = React.forwardRef<
  HTMLLIElement,
  SelectItemProps
>((props, ref) => {
  return <MUIMenuItem ref={ref} {...props} />;
});
