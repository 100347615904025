/**
 * @deprecated import from design-system
 */
export enum KeyCodes {
  Down = 40,
  End = 35,
  Enter = 13,
  Escape = 27,
  Home = 36,
  Left = 37,
  PageDown = 34,
  PageUp = 33,
  Right = 39,
  Slash = 191,
  Space = 32,
  Up = 38,
}
