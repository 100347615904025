import { createContext, useCallback, useContext, useState } from 'react';

export type TabSetter = (id: string) => void;

export const ActiveTabContext = createContext<string>('');
export const TabSetterContext = createContext<TabSetter>(() => {});

export type TabsProviderProps = {
  children: React.ReactNode;
  initialTab: string;
  /** This needs to be a stable function, so either a setter from useState or a useCallback function. */
  onChange?: TabSetter;
};

export const TabsProvider = ({ children, initialTab, onChange }: TabsProviderProps) => {
  const [activeTab, setActiveTab] = useState(initialTab);
  const handleChange = useCallback(
    (id: string) => {
      setActiveTab(id);
      if (typeof onChange === 'function') {
        onChange(id);
      }
    },
    [onChange]
  );

  return (
    <TabSetterContext.Provider value={handleChange}>
      <ActiveTabContext.Provider value={activeTab}>{children}</ActiveTabContext.Provider>
    </TabSetterContext.Provider>
  );
};

export function useActiveTab() {
  const context = useContext(ActiveTabContext);
  if (!context) {
    throw new Error('useActiveContext needs to be a descendant of a TabsProvider');
  }
  return context;
}

export function useTabSetter() {
  const context = useContext(TabSetterContext);
  if (!context) {
    throw new Error('useTabSetter needs to be a descendant of a TabsProvider');
  }
  return context;
}
