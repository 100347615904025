import { css } from 'emotion';
import { gray3 } from '../../foundation/colors/colors';

export const relative = css`
  position: relative;
  label: relative;
`;

export const tooltip = css`
  max-width: 200px;
  max-height: 150px;
  background: white;
  overflow: hidden;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px ${gray3};
  opacity: 1;
  position: absolute;

  label: tooltip;
`;

export const displayStyle = css`
  visibility: auto;
`;

export const hideStyle = css`
  visibility: hidden;
`;
