import { cx } from 'emotion';
import { buttonFont } from '../../foundation/buttons/button.styles';
import { Body, Title } from '../../foundation/typography/typography.component';
import React from 'react';
import ReactModal from 'react-modal';
import { Icons, Svg } from '../../svgs/svg.component';
import { bodyText, buttonWrapper, callMessage, content, iconWrapper, imageStyle } from './call-message.styles';

if (window.document.getElementById('frame')) {
  ReactModal.setAppElement('#frame');
}

interface Props {
  className?: string;
  color?: 'blue' | 'pink' | 'green' | 'orange' | 'purple' | '';
  onTopButtonClick: () => void;
  onBottomButtonClick: () => void;
  topButtonText?: string;
  bottomButtonText?: string;
  title: string;
  body: string;
  icon?: Icons;
}

/**
 * @deprecated Not replaced in design-system
 */
export class CallMessage extends React.Component<Props, {}> {
  render() {
    const classNames = cx(callMessage, this.props.className || '', this.props.color || '');
    const icon = this.props.icon || 'Plus';

    return (
      <div className={classNames}>
        <div className={iconWrapper}>
          <div className="icon-div">
            <Svg icon={icon} className="xl white" />
          </div>
        </div>
        <div className={imageStyle} />
        <div className={content}>
          <Title>{this.props.title || ''}</Title>
          <div className={bodyText}>
            <Body>{this.props.body || ''}</Body>
          </div>
        </div>
        <div className={buttonWrapper}>
          <div className={cx(buttonFont, 'cancel-button-style')} onClick={this.props.onTopButtonClick}>
            {this.props.topButtonText || 'Ignore'}
          </div>
          <div className={cx(buttonFont, 'default-button-style')} onClick={this.props.onBottomButtonClick}>
            {this.props.bottomButtonText || 'Call'}
          </div>
        </div>
      </div>
    );
  }
}
