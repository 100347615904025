import React from 'react';
import { cx } from 'emotion';
import { noop, isArray } from 'lodash';

import { Strong, Body } from '../../foundation/';

import { capitalize, right, attrStyle, attributesStyle } from './attributes.styles';
import { attributeJub } from './attributes.styles.jubjub';

interface Props {
  className?: any;
  data: { [key: string]: any };
  actions?: { [key: string]: (key: string, value: string) => void };
  hide?: string[];
}

interface AttributeModel {
  name: string;
  value: string | number | boolean | string[] | number[] | boolean[];
  onClick?: { [key: string]: (key: string, value: string) => void };
}

/**
 * @deprecated Not replaced in design-system
 */
export const Attribute = ({ name, value, onClick }: AttributeModel) => {
  let click = '';
  let clickFx = noop;
  if (typeof onClick === 'function') {
    click = 'click';
    clickFx = onClick;
  }

  if (typeof value === 'boolean') {
    value = value ? 'True' : 'False';
  }

  return (
    <div
      className={cx('attribute', attrStyle, attributeJub, click)}
      onClick={() => clickFx.call(null, name, value)}
      title={`${name}: ${value}`}
    >
      <Strong className={capitalize}>{name}:</Strong>{' '}
      <Body className={cx(right)}>{value}</Body>
    </div>
  );
};

const renderAttribute = (key: string, idx: number, data, actions, hide) => {
  let value = data[key];
  if (isArray(value)) {
    value = data[key].join(', ');
    value = value.replace(/[,]\s$/m, '');
  }
  // @ts-ignore: has method #includes.
  if (
    hide.includes(key) ||
    !data.hasOwnProperty(key) ||
    !['string', 'number', 'boolean'].includes(typeof value)
  ) {
    return null;
  }

  let onclick = {};
  if (Object.keys(actions).includes(key)) {
    onclick = () => {
      actions[key].call(null, key, data[key]);
    };
  }

  return (
    <Attribute
      key={`item-${idx}`}
      name={key.replace(/[-_]/, ' ')}
      value={value}
      onClick={onclick}
    />
  );
};

/**
 * @deprecated Not replaced in design-system
 */
export const Attributes = ({
  className = '',
  data,
  actions = {},
  hide = [],
  ...props
}: Props) => {
  const classNames = cx('attributes', attributesStyle, className);

  return (
    <div className={classNames} {...props}>
      {Object.keys(data).map((k, v) => renderAttribute(k, v, data, actions, hide))}
    </div>
  );
};
