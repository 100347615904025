import React from 'react';
import { cx } from 'emotion';

import { SvgList } from '../../../svgs/svg-list';
import { checkboxStyle, checkboxContainerStyle, toggleStyle, svg } from './checkbox.styles';
import { checkboxStyleJub, toggleStyleJub, svgJub } from './checkbox.styles.jubjub';

const { Check } = SvgList;

export type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  toggle?: boolean;
  style?: 'round' | 'toggle';
  trackingId?: string;
};

/**
 * @deprecated Replaced by CheckboxField, ChecklistField with useForm, useFormField
 */
export const Checkbox = ({ className = '', toggle = false, style = 'round', trackingId, ...props }: Props) => {
  const classNames = cx(checkboxStyle, checkboxStyleJub, className);
  const onClick = (evt) => {
    if (evt.target.className.includes('relative-checkbox')) {
      evt.stopPropagation();
      evt.preventDefault();
      evt.currentTarget.firstElementChild.click();
    }
  };

  return (
    <div
      className={cx(
        (toggle || style === 'toggle') && cx(toggleStyle, toggleStyleJub),
        'relative-checkbox',
        checkboxContainerStyle
      )}
      onClick={onClick}
      data-trackingid={trackingId}
    >
      <input type="checkbox" className={classNames} {...props} />
      {!toggle && <Check className={cx(svg, svgJub)} />}
    </div>
  );
};
