import React, { HTMLProps } from 'react';
import { css } from '@emotion/core';

interface Props extends HTMLProps<HTMLUListElement> {
  children: React.ReactNode;
}

/**
 * @deprecated import from design-system
 */
export const NakedUl = ({ children, ...rest }: Props) => (
  <ul
    css={css`
      list-style-type: none;
      padding-left: 0;
    `}
    {...rest}
  >
    {children}
  </ul>
);
