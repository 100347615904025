import React from 'react';
import { useTransition, config } from 'react-spring';
import { XIcon } from '../icon';
import { IconButton } from '../icon-button';
import { ModalRenderer } from './modal-renderer.component';
import { ModalControlModalProps } from '../../hooks';
import { FullScreenModalContainer } from './fullscreen-modal-container.component';
import { FullscreenModalHeader } from './fullscreen-modal-header.component';
import { FullscreenModalBody } from './fullscreen-modal-body.component';
import { FullscreenModalActions } from './fullscreen-modal-actions.component';
import { css } from '@emotion/core';

type Props = ModalControlModalProps & {
  children: React.ReactNode;
};

/**
 * @deprecated import from design-system
 */
export const FullscreenModal = ({ children, onClose, show, ...rest }: Props) => {
  const transitions = useTransition(show, null, {
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(100%)' },
    config: { ...config.wobbly, friction: 24 },
  });

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <ModalRenderer key={key} onClose={onClose} timeout={500}>
              <FullScreenModalContainer style={props} {...rest}>
                {children}
                <IconButton
                  css={css`
                    -webkit-app-region: none;
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    z-index: 1;
                  `}
                  onClick={onClose}
                  label="Close modal"
                  name="Close modal"
                >
                  <XIcon />
                </IconButton>
              </FullScreenModalContainer>
            </ModalRenderer>
          )
      )}
    </>
  );
};

FullscreenModal.Header = FullscreenModalHeader;
FullscreenModal.Body = FullscreenModalBody;
FullscreenModal.Actions = FullscreenModalActions;
