/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const LocationIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M12,14.75 C9.790861,14.75 8,12.959139 8,10.75 C8,8.540861 9.790861,6.75 12,6.75 C14.209139,6.75 16,8.540861 16,10.75 C16,12.959139 14.209139,14.75 12,14.75 Z M12,12.25 C12.8284271,12.25 13.5,11.5784271 13.5,10.75 C13.5,9.92157288 12.8284271,9.25 12,9.25 C11.1715729,9.25 10.5,9.92157288 10.5,10.75 C10.5,11.5784271 11.1715729,12.25 12,12.25 Z M13.035275,22.7404994 C12.4559013,23.2930883 11.5447636,23.293176 10.9647759,22.740548 C5.51512391,17.5428468 2.75,13.3616902 2.75,10 C2.75,4.89136606 6.89136606,0.75 12,0.75 C17.1086339,0.75 21.25,4.89136606 21.25,10 C21.25,13.3616801 18.484893,17.5428209 13.035275,22.7404994 Z M5.25,10 C5.25,12.3490119 7.47689202,15.82728 11.9999946,20.2639912 C16.5232313,15.8270877 18.75,12.3489467 18.75,10 C18.75,6.27207794 15.7279221,3.25 12,3.25 C8.27207794,3.25 5.25,6.27207794 5.25,10 Z" />
  </SvgIcon>
  );