import { useHint, HintProps, UseHintProps, UseHintResults } from '../use-hint';
import { HoverLabel } from './hover-label.component';

type Props = Omit<UseHintProps, 'trigger'>;

type UseHoverLabelResults = Omit<UseHintResults, 'hintProps'> & {
  HoverLabel: typeof HoverLabel;
  labelProps: HintProps;
};

/**
 * @deprecated import from design-system
 */
export function useHoverLabel(props: Props = {}): UseHoverLabelResults {
  const { hintProps, ...rest } = useHint(props);
  return { ...rest, labelProps: hintProps, HoverLabel };
}
