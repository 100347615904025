import React from 'react';
import { cx } from 'emotion';

import { wizardButtonStyle } from './button.styles';
import { wizardButtonStyleJub } from './button.styles.jubjub';

interface Props {
  className?: string;
  color?: 'red' | 'gray5' | '';
  onClick?: () => void;
  children?: string;
  disabled?: boolean;
}

/**
 * @deprecated Not replaced in design-system
 */
export const WizardButton = ({ className = '', color = '', children, ...props }: Props) => {
  const classNames = cx(wizardButtonStyle, wizardButtonStyleJub, className, color);
  return (
    <button className={classNames} {...props}>
      {children}
    </button>
  );
};
