import React, { ElementType, ReactChild } from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '../theme';
import { getFontColorStyle, FontColorType } from '../../helpers/font-styles';
import {
  getFontFamilyStyle,
  getFontSizeStyle,
  getTextAlignStyle,
  getFontWeightStyle,
  TextSize,
  FontWeight,
  TextAlignment,
} from '../text/text.style';

interface TextLinkProps extends React.HTMLAttributes<HTMLElement> {
  size?: TextSize;
  weight?: FontWeight;
  color?: 'default' | 'danger';
  textAlign?: TextAlignment;
  children: string | ReactChild;
  to?: string;
  className?: string;
  disabled?: boolean;
}

const COLOR_MAP: { [key: string]: FontColorType } = {
  default: 'primary',
  disabled: 'disabled',
  danger: 'error',
};

const buttonStyles = css`
  position: relative;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  /* Remove the margin in Safari */
  margin: 0;
  border-radius: 0;
  /* Remove the padding in Firefox */
  padding: 0;
  cursor: pointer;
  user-select: none;
  appearance: none;
  /* Remove Firefox dotted outline. */
  &::-moz-focus-inner {
    border-style: none;
  }
  &:focus-visible {
    outline: none;
  }
`;

const aTagStyles = css`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const disabledStyles = css`
  pointer-events: none;
  cursor: default;
`;

const hoverStyle = (theme: WeaveTheme, weight) => css`
  :hover,
  :focus {
    color: ${theme.colors.gray[600]};
    font-weight: ${weight};
  }
`;

/**
 * @deprecated import from design-system
 */
export function TextLink({
  size = 'inherit',
  weight = 'inherit',
  color = 'default',
  disabled,
  textAlign,
  className,
  onClick,
  to,
  ...props
}: TextLinkProps) {
  const isButton = !to && !!onClick;
  const Component: ElementType = isButton ? 'button' : 'a';
  const baseStyles = isButton ? buttonStyles : aTagStyles;

  return (
    <Component
      type={isButton ? 'button' : undefined}
      className={className}
      css={(theme: WeaveTheme) => [
        baseStyles,
        getFontFamilyStyle(theme),
        size && getFontSizeStyle(size, theme),
        color && getFontColorStyle(COLOR_MAP[disabled ? 'disabled' : color], theme),
        textAlign && getTextAlignStyle(textAlign),
        weight && getFontWeightStyle(weight, theme),
        !disabled && hoverStyle(theme, weight),
        disabled && disabledStyles,
      ]}
      href={to}
      onClick={onClick}
      disabled={disabled && isButton ? true : false}
      {...props}
    />
  );
}
