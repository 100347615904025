export const TAG_ELEMENT_TYPE = 'tag';

export type TagType = {
  label: string;
  key: string;
  value: string;
  invalid?: boolean;
};

export type AddTagToTemplateFun = (
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  tag: TagType
) => void;

export const getTag = (tags: TagType[], key: string): TagType | undefined =>
  tags.find((tag) => tag.key === key);
