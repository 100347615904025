/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const FeedbackDashIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M11.7928932,6 L12.1265242,6.33363094 C12.6146795,6.82178631 12.6146795,7.61324253 12.1265242,8.1013979 C11.6383688,8.58955326 10.8469126,8.58955326 10.3587572,8.1013979 L7.88388348,5.62652416 C7.39572811,5.1383688 7.39572811,4.34691258 7.88388348,3.85875721 L10.3587572,1.38388348 C10.8469126,0.895728112 11.6383688,0.895728112 12.1265242,1.38388348 C12.6146795,1.87203884 12.6146795,2.66349506 12.1265242,3.15165043 L11.7781746,3.5 L20,3.5 C21.7949254,3.5 23.25,4.95507456 23.25,6.75 L23.25,17.75 C23.25,19.5449254 21.7949254,21 20,21 L19,21 C18.3096441,21 17.75,20.4403559 17.75,19.75 C17.75,19.0596441 18.3096441,18.5 19,18.5 L20,18.5 C20.4142136,18.5 20.75,18.1642136 20.75,17.75 L20.75,6.75 C20.75,6.33578644 20.4142136,6 20,6 L11.7928932,6 Z M12.1923882,21 L4,21 C2.20507456,21 0.75,19.5449254 0.75,17.75 L0.75,6.75 C0.75,4.95507456 2.20507456,3.5 4,3.5 L5,3.5 C5.69035594,3.5 6.25,4.05964406 6.25,4.75 C6.25,5.44035594 5.69035594,6 5,6 L4,6 C3.58578644,6 3.25,6.33578644 3.25,6.75 L3.25,17.75 C3.25,18.1642136 3.58578644,18.5 4,18.5 L12.2071068,18.5 L11.8587572,18.1516504 C11.3706018,17.6634951 11.3706018,16.8720388 11.8587572,16.3838835 C12.3469126,15.8957281 13.1383688,15.8957281 13.6265242,16.3838835 L16.1013979,18.8587572 C16.5895533,19.3469126 16.5895533,20.1383688 16.1013979,20.6265242 L13.6265242,23.1013979 C13.1383688,23.5895533 12.3469126,23.5895533 11.8587572,23.1013979 C11.3706018,22.6132425 11.3706018,21.8217863 11.8587572,21.3336309 L12.1923882,21 Z M6.25,9.5 L17.75,9.5 C18.4403559,9.5 19,10.0596441 19,10.75 C19,11.4403559 18.4403559,12 17.75,12 L6.25,12 C5.55964406,12 5,11.4403559 5,10.75 C5,10.0596441 5.55964406,9.5 6.25,9.5 Z M6.25,12.5 L11.75,12.5 C12.4403559,12.5 13,13.0596441 13,13.75 C13,14.4403559 12.4403559,15 11.75,15 L6.25,15 C5.55964406,15 5,14.4403559 5,13.75 C5,13.0596441 5.55964406,12.5 6.25,12.5 Z" />
  </SvgIcon>
  );