import { injectGlobal } from 'emotion';
import ProximaNova from './ProximaNova/MarkSimonson-ProximaNova.ttf';
import ProximaNovaItalic from './ProximaNova/MarkSimonson-ProximaNovaRegularIt.ttf';
import ProximaNovaSemibold from './ProximaNova/MarkSimonson-ProximaNovaSemibold.ttf';
import ProximaNovaBold from './ProximaNova/MarkSimonson-ProximaNovaABold.ttf';
import { weaveTheme } from '../../dsm/theme';

injectGlobal`
    @font-face {
      font-family: 'Proxima Nova';
      src: url(${ProximaNova}) format("truetype");
      font-weight: normal;
    }
    
    @font-face {
      font-family: 'Proxima Nova';
      src: url(${ProximaNovaItalic}) format("truetype");
      font-weight: normal;
      font-style: italic;
    }
    
    @font-face {
      font-family: 'Proxima Nova';
      src: url(${ProximaNovaSemibold}) format("truetype");
      font-weight: 600;
    }
    
    @font-face {
      font-family: 'Proxima Nova';
      src: url(${ProximaNovaBold}) format("truetype");
      font-weight: 800;
    }

    html {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
    }

    html,
    body {
      margin: 0;
      padding: 0;
    }

    * {
      box-sizing: inherit;
      font-family: ${weaveTheme.font.family};
    }

    *::before,
    *::after {
      box-sizing: inherit;
    }
`;
