import { RangeConfigType } from './date-selector.component';

/**
 * @deprecated
 */
export const defaultConfig: RangeConfigType = [
  {
    label: 'Day',
    options: [
      { label: 'Today', period: 'day', startShift: 0, endShift: 0 },
      {
        label: 'Yesterday',
        period: 'day',
        startShift: 1,
        endShift: 0,
      },
    ],
  },
  {
    label: 'Week',
    options: [
      {
        label: 'This Week',
        period: 'week',
        startShift: 0,
        endShift: 0,
      },
      {
        label: 'Last Week',
        period: 'week',
        startShift: 1,
        endShift: 0,
      },
    ],
  },
  {
    label: 'Month',
    options: [
      {
        label: 'This Month',
        period: 'month',
        startShift: 0,
        endShift: 0,
      },
      {
        label: 'Last Month',
        period: 'month',
        startShift: 1,
        endShift: 0,
      },
    ],
  },
  {
    label: 'Custom',
    options: ['custom'],
  },
];
