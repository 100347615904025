import React, { forwardRef, HTMLAttributes } from 'react';
import { css } from '@emotion/core';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
}

/**
 * @deprecated import from design-system
 */
export const NakedButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, ...rest }, ref) => (
    <button
      css={css`
        background: none;
        border: none;
        cursor: pointer;
        font-size: initial;
        padding: 0;
      `}
      {...rest}
      ref={ref}
    >
      {children}
    </button>
  )
);
