import AddPatient from './icons/Add Patient.svg';
import Alert from './icons/Alert.svg';
import AlertInvert from './icons/Alert Invert.svg';
import Analytics from './icons/Analytics.svg';
import AnalyticsInfo from './icons/Analytics Info.svg';
import Asterisk from './icons/Asterisk.svg';
import Award from './icons/Award.svg';
import Back from './icons/Back.svg';
import Building from './icons/Building.svg';
import Calendar from './icons/Calendar.svg';
import CallForward from './icons/Call Forward.svg';
import CarrotDown from './icons/Carrot Down.svg';
import CarrotLeft from './icons/Carrot Left.svg';
import CarrotRight from './icons/Carrot Right.svg';
import CarrotUp from './icons/Carrot Up.svg';
import CellPhone from './icons/Cell Phone.svg';
import Chat from './icons/Chat.svg';
import Check from './icons/Check.svg';
import Clock from './icons/Clock.svg';
import Copy from './icons/Copy.svg';
import CreditCard from './icons/Credit Card.svg';
import DataSync from './icons/Data Sync.svg';
import Delete from './icons/Delete.svg';
import Dial from './icons/Dial.svg';
import Document from './icons/Document.svg';
import DocumentList from './icons/Document List.svg';
import Download from './icons/Download.svg';
import Edit from './icons/Edit.svg';
import EditSimple from './icons/Edit Simple.svg';
import Email from './icons/Email.svg';
import Emoji from './icons/Emoji.svg';
import ExternalLink from './icons/External Link.svg';
import Feedback from './icons/Feedback.svg';
import FeedbackAlt from './icons/Feedback Alt.svg';
import Filter from './icons/Filter.svg';
import FilterAlt from './icons/Filter Alt.svg';
import FolderCheck from './icons/Folder Check.svg';
import FolderPlus from './icons/Folder Plus.svg';
import Folder from './icons/Folder.svg';
import Gift from './icons/Gift.svg';
import HamburgerMenu from './icons/Hamburger Menu.svg';
import Help from './icons/Help.svg';
import Home from './icons/Home.svg';
import Hourglass from './icons/Hourglass.svg';
import ID from './icons/ID.svg';
import Image from './icons/Image.svg';
import Info from './icons/Info.svg';
import List from './icons/List.svg';
import Lists from './icons/Lists.svg';
import Location from './icons/Location.svg';
import Lock from './icons/Lock.svg';
import LogOut from './icons/Log Out.svg';
import Menu from './icons/Menu.svg';
import Message from './icons/Message.svg';
import Minus from './icons/Minus.svg';
import More from './icons/More.svg';
import Moon from './icons/Moon.svg';
import Notification from './icons/Notification.svg';
import Number from './icons/Number.svg';
import Pause from './icons/Pause.svg';
import Pay from './icons/Pay.svg';
import Phone from './icons/Phone.svg';
import PhoneCall from './icons/Phone Call.svg';
import PhoneIncoming from './icons/Phone Incoming.svg';
import PhoneMissed from './icons/Phone Missed.svg';
import PhoneOutgoing from './icons/Phone Outgoing.svg';
import PhoneStatus from './icons/Phone Status.svg';
import Play from './icons/Play.svg';
import Plus from './icons/Plus.svg';
import PortingNumbers from './icons/Porting Numbers.svg';
import PortingStatus from './icons/Porting Status.svg';
import QuickFill from './icons/QuickFill.svg';
import RecallAnalytics from './icons/Recall Analytics.svg';
import Record from './icons/Record.svg';
import Reminders from './icons/Reminders.svg';
import ReviewsDash from './icons/Reviews Dash.svg';
import Reports from './icons/Reports.svg';
import Search from './icons/Search.svg';
import Send from './icons/Send.svg';
import Settings from './icons/Settings.svg';
import Speak from './icons/Speak.svg';
import Speaker from './icons/Speaker.svg';
import Star from './icons/Star.svg';
import StarFilled from './icons/Star Filled.svg';
import Stop from './icons/Stop.svg';
import Support from './icons/Support.svg';
import Sync from './icons/Sync.svg';
import SyncAlt from './icons/Sync Alt.svg';
import SyncAppHealth from './icons/Sync App Health.svg';
import Template from './icons/Template.svg';
import Terminal from './icons/Terminal.svg';
import Trash from './icons/Trash.svg';
import Training from './icons/Training.svg';
import Update from './icons/Update.svg';
import Updates from './icons/Updates.svg';
import Upload from './icons/Upload.svg';
import User from './icons/User.svg';
import UserManagement from './icons/User Management.svg';
import Voicemail from './icons/Voicemail.svg';
import Volume from './icons/Volume.svg';
import WeaveLogo from './icons/Weave Logo.svg';
import X from './icons/X.svg';

/**
 * @deprecated icons are now imported directly in design-system, ex: CarrotDownIcon
 */
export const SvgList = {
  AddPatient,
  Alert,
  AlertInvert,
  Analytics,
  AnalyticsInfo,
  Asterisk,
  Award,
  Back,
  Building,
  Calendar,
  CallForward,
  CarrotDown,
  CarrotLeft,
  CarrotRight,
  CarrotUp,
  CellPhone,
  Chat,
  Check,
  Clock,
  Copy,
  CreditCard,
  DataSync,
  Delete,
  Dial,
  Document,
  DocumentList,
  Download,
  Edit,
  EditSimple,
  Email,
  Emoji,
  ExternalLink,
  Feedback,
  FeedbackAlt,
  Filter,
  FilterAlt,
  FolderCheck,
  FolderPlus,
  Folder,
  Gift,
  HamburgerMenu,
  Help,
  Home,
  Hourglass,
  ID,
  Image,
  Info,
  List,
  Lists,
  Location,
  Lock,
  LogOut,
  Message,
  Menu,
  Minus,
  More,
  Moon,
  Notification,
  Number,
  Pause,
  Pay,
  Phone,
  PhoneCall,
  PhoneIncoming,
  PhoneMissed,
  PhoneOutgoing,
  PhoneStatus,
  Play,
  Plus,
  PortingNumbers,
  PortingStatus,
  QuickFill,
  RecallAnalytics,
  Record,
  Reports,
  Reminders,
  ReviewsDash,
  Search,
  Send,
  Settings,
  Speak,
  Speaker,
  Star,
  StarFilled,
  Stop,
  Support,
  Sync,
  SyncAlt,
  SyncAppHealth,
  Template,
  Terminal,
  Trash,
  Training,
  Update,
  Updates,
  Upload,
  User,
  UserManagement,
  Voicemail,
  Volume,
  WeaveLogo,
  X,
};
