/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const OptOutAlertIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M12,5 C12.6040614,5 13.09375,5.48968855 13.09375,6.09375 L13.093,10.906 L17.90625,10.90625 C18.5103114,10.90625 19,11.3959386 19,12 C19,12.6040614 18.5103114,13.09375 17.90625,13.09375 L13.093,13.093 L13.09375,17.90625 C13.09375,18.5103114 12.6040614,19 12,19 C11.3959386,19 10.90625,18.5103114 10.90625,17.90625 L10.906,13.093 L6.09375,13.09375 C5.48968855,13.09375 5,12.6040614 5,12 C5,11.3959386 5.48968855,10.90625 6.09375,10.90625 L10.906,10.906 L10.90625,6.09375 C10.90625,5.48968855 11.3959386,5 12,5 Z" transform="translate(12.000000, 12.000000) rotate(45.000000) translate(-12.000000, -12.000000) " />
  </SvgIcon>
  );