import * as React from 'react';
import ReactModal from 'react-modal';
import { Toggle, ToggleOption, gray3, Title, Svg } from '../..';
import { css } from 'emotion';
import { CustomPicker } from './custom-picker';
import { DateRangeRow } from './date-range-row';
import isEqual from 'lodash/isEqual';
import moment, { Moment } from 'moment';
import { defaultConfig } from './default-config';
import { modalBackground } from '../../foundation/colors/colors';

/**
 * @deprecated Replaced by Modal in design-system
 */
export const modalBackdrop = css`
  background-color: ${modalBackground};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 100;
  outline: none;

  & > div {
    margin: 0 auto;
    width: 100%;
    max-width: 350px;
    background-color: white;
    outline: none;
  }
  label: modal-backdrop;
`;

type DateRangePeriod = 'day' | 'week' | 'month' | 'year';

type PreconfiguredOption = {
  label: string;
  period: DateRangePeriod;
  startShift: number;
  endShift: number;
};

type OptionsConfig = PreconfiguredOption | 'custom';

export type RangeConfigType = Array<{
  label: string;
  options: OptionsConfig[];
}>;

interface DateSelectorProps {
  className?: string;
  onChange: (value: [Moment, Moment]) => void;
  isOpen: boolean;
  parentSelector?: HTMLElement;
  disableFuture?: boolean;
  disablePast?: boolean;
  value: [Moment, Moment];
  style?: { [key: string]: string | number };
  config?: RangeConfigType;
  defaultOption?: OptionsConfig;
}

/**
 * @deprecated Not yet replaced in design-system
 */
export const DateSelector = ({
  isOpen,
  onChange,
  parentSelector,
  className,
  disableFuture,
  disablePast,
  value = [moment(), moment()],
  style = {},
  config = defaultConfig,
  defaultOption,
}: DateSelectorProps) => {
  const [periodToggleIndex, setPeriodToggleIndex] = React.useState(0);
  const [selectedOption, setSelectedOption] = React.useState(defaultOption);

  const closeCustomPicker = (start, end) => {
    setSelectedOption('custom');
    onChange([start, end]);
  };

  const closeWithoutSave = () => {
    onChange(value);
  };

  const currentOption = config[periodToggleIndex];

  const setRange = (option: PreconfiguredOption) => () => {
    setSelectedOption(option);
    const start = moment()
      .startOf(option.period)
      .subtract(option.startShift, option.period);
    const end = moment(start)
      .add(option.endShift, option.period)
      .endOf(option.period);
    onChange([start, end]);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeWithoutSave}
      ariaHideApp={false}
      className={`react-modal ${className}`}
      shouldCloseOnOverlayClick
      overlayClassName={`react-modal-backdrop ${modalBackdrop}`}
      portalClassName="react-modal-portal"
      parentSelector={parentSelector}
    >
      <div
        style={style}
        className={css`
          overflow: hidden;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            border-bottom: 1px solid ${gray3};
          `}
        >
          <div
            className={css`
              width: 75px;
            `}
          />
          <Title>Select a Date</Title>
          <div
            onClick={closeWithoutSave}
            className={css`
              width: 75px;
              display: flex;
              justify-content: flex-end;
              padding: 20px;
              cursor: pointer;
            `}
          >
            <Svg icon="X" className="rg" color="gray9" />
          </div>
        </div>
        <div
          className={css`
            padding: 20px 16px 8px;
          `}
        >
          <Toggle className="md" onClick={(val) => setPeriodToggleIndex(val)} activeIdx={periodToggleIndex}>
            {config?.map(({ label }) => (
              <ToggleOption key={label}>{label}</ToggleOption>
            ))}
          </Toggle>
        </div>

        <div
          className={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {currentOption.options?.map((option) =>
            option === 'custom' ? (
              <CustomPicker
                key={option}
                disableFuture={disableFuture}
                disablePast={disablePast}
                defaultStart={value[0]}
                defaultEnd={value[1]}
                onChange={closeCustomPicker}
              />
            ) : (
              <DateRangeRow key={option.label} onClick={setRange(option)} selected={isEqual(selectedOption, option)}>
                {option.label}
              </DateRangeRow>
            )
          )}
        </div>
      </div>
    </ReactModal>
  );
};
