import { css } from 'emotion';
import { gray3, gray7, weaveBlue, weaveRed, weaveBlueLight } from '../../colors/colors';

export const inputStyle = css`
  color: ${gray7};
  height: 40px;
  width: 100%;
  font-size: 13px;
  border: 1px solid ${gray3};
  margin: 15px 0 0;
  padding-left: 15px;
  border-radius: 5px;
  display: block;
  outline: none;
  margin: 0;
  position: relative;
  transition: border-color 0.3s ease-in;

  &::placeholder {
    color: white;
  }

  .error-style & {
    border-color: ${weaveRed};
  }

  &.active,
  &:active,
  &.focus,
  &:focus {
    border-color: ${weaveBlue};
  }

  label: inputStyle;
`;

export const inputIconLeftStyle = css`
  padding-left: 38px;
`;

export const iconLeftStyle = css`
  pointer-events: none;
  position: absolute;
  left: 10px;
  top: 18px;
  transform: translate(0, -50%);
`;

export const inputIconRightStyle = css`
  padding-right: 30px;
`;

export const iconRightStyle = css`
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 18px;
  transform: translate(0, -50%);

  label: icon-right;
`;

export const inputIconRightPointer = css`
  pointer-events: auto;
  cursor: pointer;
`;

export const actionTextStyle = css`
  color: ${weaveBlue};
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
  transition: color 300ms;

  :hover {
    color: ${weaveBlueLight};
  }

  label: actionTextStyle;
`;
