import { useEffect } from 'react';

type Props = {
  // active is for when this hook is used for a component that does not
  // leave the tree when it's hidden. It ensures the listeners are only
  // added when needed and cleaned up otherwise.
  active?: boolean;
  ref: React.MutableRefObject<any>;
  // refs for any other elements that should be ignore (eg triggers for tooltips)
  ignoreRefs?: React.MutableRefObject<any>[];
  // it's a good idea to wrap the handler in`useCallback` when possible
  handler: Function;
};

const isTargetOrChild = (target: HTMLElement, container: HTMLElement) =>
  container.isSameNode(target) || container.contains(target);

/**
 * @deprecated import from design-system
 */
export function useOnClickOutside({
  active = true,
  ignoreRefs = [],
  ref,
  handler,
}: Props) {
  useEffect(() => {
    const listener = (e) => {
      if (!ref.current) {
        return;
      }
      // ignore clicks on ref or descendents of ref
      const isSame = isTargetOrChild(e.target, ref.current);
      // ignore same for any other specified refs
      const shouldIgnore = ignoreRefs.some(
        ({ current }) => current && isTargetOrChild(e.target, current)
      );
      if (isSame || shouldIgnore) {
        return;
      }
      handler(e);
    };

    if (active) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    } else {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [active, ref, handler, ...ignoreRefs]);
}
