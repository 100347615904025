import { jubcss } from '../../helpers/jub-css';
import { weaveBlue, gray3 } from '../..';

const thumbSize = '14px';

export const audioContainer = jubcss(`
  align-items: center !important;
  display: inline-flex !important;

  label: audioContainer;
`);

export const icon = jubcss(`
  cursor: default !important;
  display: inline-block !important;
  margin-right: 8px !important;

  &.clickable {
    cursor: pointer !important;
  }

  label: icon;
`);

export const containRangeSlider = (width: number) => {
  return jubcss(`
    align-items: center !important;
    display: inline-flex !important;
    height: 24px !important;
    width: ${width}px !important;

    label: containRangeSlider;
  `);
};

export const rangeSlider = (currentPosition: number = 0, duration: number = 0) => {
  let percent = 0;

  if (duration > 0) {
    percent = (currentPosition / duration) * 100;
  }

  return jubcss(`
    -webkit-appearance: none !important; 
    appearance: none !important;
    background: ${
      duration > 0 && currentPosition > 0
        ? `linear-gradient(to right, ${weaveBlue} 0%, ${weaveBlue} ${percent}%, ${gray3} ${percent}%, ${gray3} 100%)`
        : gray3
    } !important;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    height: 5px !important;
    margin: 0 !important;
    outline: none !important;
    padding: 0 !important;
    width: 100% !important;

    &.error {
      cursor: default !important;

      &::-webkit-slider-thumb {
        display: none !important;
      }

      &::-moz-range-thumb {
        display: none !important;
      }
    }

    &:hover {
      &::-webkit-slider-thumb {
        opacity: 1 !important;
      }
    }

    &::-webkit-slider-thumb {
      opacity: 0 !important;
      appearance: none !important;
      background: ${weaveBlue} !important;
      border-radius: 50% !important;
      cursor: pointer !important;
      height: ${thumbSize} !important;
      transition: opacity 200ms !important;
      width: ${thumbSize} !important;
    }

    &::-moz-range-thumb {
      width: ${thumbSize} !important;
      height: ${thumbSize} !important;
      background: ${weaveBlue} !important;
      border-radius: 50% !important;
      cursor: pointer !important;
    }

    label: rangeSlider;
  `);
};

export const time = jubcss(`
  margin-left: 8px !important;

  label: time;
`);
