import React from 'react';
import { ModalProps as MUIModalProps } from '@material-ui/core/Modal';
import { DSMModalBackdrop } from './modal.backdrop';
import { DSMModalBody } from './modal.body';

interface ModalProps extends Omit<Omit<MUIModalProps, 'BackdropComponent'>, 'children'> {
  children: React.ReactNode;
}
/**
 * @deprecated replaced by Modal from design-system
 */
export const DSMModal = ({ children, open, className, ...props }: ModalProps) => (
  <DSMModalBackdrop open={open} {...props}>
    <DSMModalBody className={className}>{children}</DSMModalBody>
  </DSMModalBackdrop>
);
