/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const AnalyticsIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M3.25,14 C3.94035594,14 4.5,14.5596441 4.5,15.25 L4.5,19.75 C4.5,20.4403559 3.94035594,21 3.25,21 C2.55964406,21 2,20.4403559 2,19.75 L2,15.25 C2,14.5596441 2.55964406,14 3.25,14 Z M12,9 C12.6903559,9 13.25,9.55964406 13.25,10.25 L13.25,19.75 C13.25,20.4403559 12.6903559,21 12,21 C11.3096441,21 10.75,20.4403559 10.75,19.75 L10.75,10.25 C10.75,9.55964406 11.3096441,9 12,9 Z M20.75,3 C21.4403559,3 22,3.55964406 22,4.25 L22,19.75 C22,20.4403559 21.4403559,21 20.75,21 C20.0596441,21 19.5,20.4403559 19.5,19.75 L19.5,4.25 C19.5,3.55964406 20.0596441,3 20.75,3 Z" />
  </SvgIcon>
  );