import { cx } from 'emotion';
import { body } from '../../foundation/typography/typography.styles';
import React from 'react';
import { displayStyle, hideStyle, relative, tooltip } from './tooltip.styles';

export enum TooltipAnchorPositions {
  topLeft = 0,
  topCenter,
  topRight,
  middleLeft,
  middleCenter,
  middleRight,
  bottomLeft,
  bottomCenter,
  bottomRight,
}
interface Props {
  anchor: TooltipAnchorPositions;
  tip: string;
  children: React.ReactNode;
  className?: string;
}
interface State {
  showTooltip: boolean;
  tipWidth: number;
  tipHeight: number;
}
/*
  Anchor Position:
  0 1 2
  3 4 5
  6 7 8
*/
interface Anchor {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

/**
 * @deprecated Replaced by Tooltip or Info in design-system
 */
export class Tooltip extends React.Component<Props, State> {
  public childRef;
  public tipRef;
  public tooltimer;
  public tipPadding = 2;

  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.tipRef = React.createRef();

    this.state = {
      showTooltip: false,
      tipWidth: 0,
      tipHeight: 0,
    };
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.setState({
      tipWidth: (this.tipRef.current && this.tipRef.current.offsetWidth) || 0,
      tipHeight: (this.tipRef.current && this.tipRef.current.offsetHeight) || 0,
    });
  }

  public componentWillUnmount() {
    if (this.tooltimer) {
      clearTimeout(this.tooltimer);
    }
    window.removeEventListener('resize', this.handleResize);
  }

  public handleMouseEnter = () => {
    if (this.tooltimer) {
      clearTimeout(this.tooltimer);
    }
    this.tooltimer = setTimeout(() => {
      this.setState({ showTooltip: true });
    }, 500);
  };

  public handleMouseLeave = () => {
    if (this.tooltimer) {
      clearTimeout(this.tooltimer);
    }
    this.tooltimer = setTimeout(() => {
      this.setState({ showTooltip: false });
    }, 1500);
  };

  public handleResize = () => {
    this.setState({
      tipWidth: this.tipRef.current.offsetWidth,
      tipHeight: this.tipRef.current.offsetHeight,
    });
  };

  public getTooltipAnchor = (): Anchor => {
    /* Anchor Position:
      0 1 2
      3 4 5
      6 7 8
    */
    return [
      { top: 0, left: 0 },
      { top: 0 },
      { top: 0, right: 0 },
      { left: 0 },
      {},
      { right: 0 },
      { bottom: 0, left: 0 },
      { bottom: 0 },
      { bottom: 0, right: 0 },
    ][this.props.anchor || 0];
  };

  public calculateTooltipPosition = (anchor: Anchor): Anchor => {
    const newAnchor: Anchor = {};
    if (anchor.top !== undefined) {
      newAnchor.top = anchor.top - this.state.tipHeight - this.tipPadding;
    }
    if (anchor.right !== undefined) {
      newAnchor.right = anchor.right - this.state.tipWidth - this.tipPadding;
    }
    if (anchor.bottom !== undefined) {
      newAnchor.bottom = anchor.bottom - this.state.tipHeight - this.tipPadding;
    }
    if (anchor.left !== undefined) {
      newAnchor.left = anchor.left - this.state.tipWidth - this.tipPadding;
    }
    // Calculate midpoint aligment
    const rect = this.childRef.current;
    if (anchor.left === undefined && anchor.right === undefined) {
      newAnchor.left = rect.offsetWidth / 2 - this.tipRef.current.offsetWidth / 2;
    }
    if (anchor.top === undefined && anchor.bottom === undefined) {
      newAnchor.top = rect.offsetHeight / 2 - this.tipRef.current.offsetHeight / 2;
    }
    return newAnchor;
  };

  public calculateWindowSafePosition = (anchor: Anchor): Anchor => {
    const newAnchor: Anchor = {};
    const rect = this.childRef.current.getBoundingClientRect();
    const windowPadding = 8;

    const available = {
      top: this.childRef.current.getBoundingClientRect().top - windowPadding,
      right: window.innerWidth - rect.right - windowPadding,
      bottom: window.innerHeight - rect.bottom - windowPadding,
      left: this.childRef.current.getBoundingClientRect().left - windowPadding,
    };

    if (anchor.top !== undefined) {
      newAnchor.top = Math.abs(anchor.top) > available.top ? -1 * available.top : anchor.top;
    }
    if (anchor.right !== undefined) {
      newAnchor.right = Math.abs(anchor.right) > available.right ? -1 * available.right : anchor.right;
    }
    if (anchor.bottom !== undefined) {
      const point = Math.abs(anchor.bottom + this.state.tipHeight);
      newAnchor.bottom = point > available.bottom ? -1 * available.bottom : anchor.bottom;
    }
    if (anchor.left !== undefined) {
      newAnchor.left = Math.abs(anchor.left) > available.left ? -1 * available.left : anchor.left;
    }
    return newAnchor;
  };

  public convertAnchorToStyle = (anchor: Anchor) => {
    const style: any = {};
    if (anchor.top !== undefined) {
      style.top = `${anchor.top}px`;
    }
    if (anchor.right !== undefined) {
      style.right = `${anchor.right}px`;
    }
    if (anchor.bottom !== undefined) {
      style.bottom = `${anchor.bottom}px`;
    }
    if (anchor.left !== undefined) {
      style.left = `${anchor.left}px`;
    }
    return style;
  };

  public render() {
    let anchor = {};
    if (this.childRef.current) {
      anchor = this.getTooltipAnchor();
      anchor = this.calculateTooltipPosition(anchor);
      anchor = this.calculateWindowSafePosition(anchor);
    }
    const style = this.convertAnchorToStyle(anchor);

    const toggleViewClass = this.state.showTooltip ? displayStyle : hideStyle;
    const classNames = cx('tooltip-wrapper', relative, this.props.className || '');

    return (
      <div className={classNames} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <div ref={this.tipRef} className={cx('tooltip-wrapper_tip', tooltip, toggleViewClass)} style={style}>
          <span className={body}>{this.props.tip}</span>
        </div>
        <div ref={this.childRef} className="tooltip-child">
          {this.props.children}
        </div>
      </div>
    );
  }
}
