import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { getPosition, Placement, PositionResult, getDefaultStyle } from './get-position';
import { genUID, shallowEqual } from '../../helpers';
import { debounce } from 'lodash';

export type UseHintProps = {
  buffer?: number;
  hoverDelay?: number;
  offset?: number;
  placement?: Placement;
  trigger?: 'hover' | 'click';
};

type ShowTip = (e: React.SyntheticEvent) => void;
type Noop = () => void;
type ElRef = React.MutableRefObject<any>;

type TriggerProps = {
  'aria-describedby': string;
  'aria-expanded': boolean;
  ref: ElRef;
};

type HoverTriggerProps = TriggerProps & {
  onBlur: Noop;
  onFocus: ShowTip;
  onMouseEnter: ShowTip;
  onMouseLeave: Noop;
};

type ClickTriggerProps = TriggerProps & {
  onClick: ShowTip;
};

export type HintProps = PositionResult & {
  buffer: number;
  id: string;
  ref: ElRef;
  'aria-hidden': boolean;
  role: string;
};

export type UseHintResults = {
  visible: boolean;
  show: ShowTip;
  hide: Noop;
  triggerRef: any;
  triggerProps: ClickTriggerProps | HoverTriggerProps;
  hintProps: HintProps;
};

/**
 * @deprecated import from design-system
 */
export function useHint({
  buffer = 16,
  hoverDelay = 300,
  offset,
  placement,
  trigger = 'hover',
}: UseHintProps = {}): UseHintResults {
  const id = useRef<string>(`hint-${genUID()}`);
  const triggerRef = useRef<any>();
  const tipRef = useRef<any>();
  const [visible, setShow] = useState<boolean>(false);
  const [position, setPosition] = useState<PositionResult>({
    style: getDefaultStyle(),
  } as PositionResult);

  const showTip = useCallback(() => {
    setShow(true);
  }, []);

  const handleClick = useCallback(() => {
    setShow((currentVisibility) => !currentVisibility);
  }, []);

  const debouncedShow = useCallback(debounce(showTip, hoverDelay), [showTip, hoverDelay]);

  const hideTip = useCallback(() => {
    debouncedShow.cancel();
    setShow(false);
  }, [debouncedShow]);

  useLayoutEffect(() => {
    if (visible && tipRef.current) {
      const livePosition: PositionResult = getPosition({
        buffer,
        offset,
        placement,
        tip: tipRef.current,
        trigger: triggerRef.current,
      });

      if (
        livePosition.placement !== position.placement ||
        !shallowEqual(livePosition.style, position.style)
      ) {
        setPosition(livePosition);
      }
    }
  });

  return {
    visible,
    show: showTip,
    hide: hideTip,
    triggerRef,
    triggerProps: {
      'aria-describedby': id.current,
      'aria-expanded': visible,
      ...(trigger === 'click'
        ? { onClick: handleClick }
        : {
            onBlur: hideTip,
            onFocus: showTip,
            onMouseEnter: debouncedShow,
            onMouseLeave: hideTip,
          }),
      ref: triggerRef,
    },
    hintProps: {
      id: id.current,
      ref: tipRef,
      'aria-hidden': !visible,
      role: 'tooltip',
      ...position,
      buffer,
    },
  };
}
