import React from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from 'dsm/theme';

type Props = React.HtmlHTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

export const FullscreenModalBody = ({ children, ...rest }: Props) => (
  <div
    css={(theme: WeaveTheme) => css`
      flex: 1;
      overflow-y: auto;
      padding: ${theme.spacing(2)};
      text-align: left;
    `}
    {...rest}
  >
    {children}
  </div>
);
