import React from 'react';
import MUIIconButton from '@material-ui/core/IconButton';
import MUIButton from '@material-ui/core/Button';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'default';
  disabled?: boolean;
  size?: 'small' | 'medium';
  filled?: boolean;
}

/**
 * @deprecated Replaced by ActionButton from design-system
 */
export const ContactButton: React.FC<ButtonProps> = ({
  children,
  color = 'primary',
  filled,
  size = 'medium',
  ...props
}) => {
  const diameter = size === 'medium' ? '48px' : '24px';
  return !filled ? (
    <MUIIconButton
      color={color}
      size={size}
      style={{
        borderRadius: diameter,
        width: diameter,
        maxWidth: diameter,
        minWidth: diameter,
        height: diameter,
        maxHeight: diameter,
        minHeight: diameter,
        padding: 0,
      }}
      {...props}
    >
      {children}
    </MUIIconButton>
  ) : (
    <MUIButton
      color={color}
      style={{
        borderRadius: diameter,
        width: diameter,
        maxWidth: diameter,
        minWidth: diameter,
        height: diameter,
        maxHeight: diameter,
        minHeight: diameter,
        padding: 0,
      }}
      variant="contained"
      {...props}
    >
      {children}
    </MUIButton>
  );
};
