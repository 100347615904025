/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const DocumentSentIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M11.1658921,0.75 C12.0333023,0.75 12.8647206,1.0967463 13.475094,1.71306172 L16.3092019,4.57475992 C16.9118978,5.1833232 17.25,6.00519853 17.25,6.86169821 L17.25,19 C17.25,20.2426407 16.2426407,21.25 15,21.25 L3,21.25 C1.75735931,21.25 0.75,20.2426407 0.75,19 L0.75,3 C0.75,1.75735931 1.75735931,0.75 3,0.75 L11.1658921,0.75 Z M9.25,3.25 L3.25,3.25 L3.25,18.75 L14.75,18.75 L14.75,8.75 L12.5,8.75 C10.7669685,8.75 9.35075407,7.39354523 9.25514479,5.68442391 L9.25,5.5 L9.25,3.25 Z M14.45,6.25 L11.75,3.524 L11.75,5.5 C11.75,5.87969577 12.0321539,6.19349096 12.3982294,6.24315338 L12.5,6.25 L14.45,6.25 Z" />
  </SvgIcon>
  );