/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const InfoRoundIconSmall: React.FC<SvgIconProps> = props => (
  <SvgIcon size={16} {...props} viewBox="0 0 16 16">
    <path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z M8,6 C8.55228475,6 9,6.44771525 9,7 L9,12 C9,12.5522847 8.55228475,13 8,13 C7.44771525,13 7,12.5522847 7,12 L7,7 C7,6.44771525 7.44771525,6 8,6 Z M8,3 C8.55228475,3 9,3.44771525 9,4 C9,4.55228475 8.55228475,5 8,5 C7.44771525,5 7,4.55228475 7,4 C7,3.44771525 7.44771525,3 8,3 Z" />
  </SvgIcon>
  );