import React from 'react';

import { cx } from 'emotion';
import { body } from '../../typography/typography.styles';
import { optionStyle } from './select.styles';

interface OptionProps {
  value: string;
  title?: string;
  className?: string;
  onClick?: (val: string | any) => void;
  children: React.ReactNode;
}

export const Option = ({ className = '', value, children, ...props }: OptionProps) => {
  return (
    <option className={cx(body, optionStyle, className)} value={value} {...props}>
      {children}
    </option>
  );
};
