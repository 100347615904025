import { WeaveTheme } from 'dsm/theme';
import { getFontSizeStyle, getFontFamilyStyle } from 'dsm/text/text.style';
import { getFontColorStyle } from 'helpers/font-styles';
import { css } from '@emotion/core';

type TabBarStyleProps = {
  activeIndex: number;
  tabWidth: number;
};

export const tabBarStyle = (
  theme: WeaveTheme,
  { activeIndex, tabWidth }: TabBarStyleProps
) => css`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid ${theme.colors.gray[300]};
  display: flex;
  position: relative;

  a,
  button {
    min-width: ${tabWidth}%;
    flex-basis: ${tabWidth}%;
  }

  :focus {
    outline: none;
  }

  ::after {
    content: '';
    display: block;
    width: ${tabWidth}%;
    height: 2px;
    background: ${theme.colors.weaveBlue};
    position: absolute;
    bottom: -1px;
    left: 0;
    opacity: ${activeIndex >= 0 ? 1 : 0};
    transition: transform 0.3s ease-out;
    transform: translateX(${100 * activeIndex}%);
  }
`;

export const tabStyle = (theme: WeaveTheme) => [
  getFontFamilyStyle(theme),
  getFontSizeStyle('medium', theme),
  css`
    ${getFontColorStyle('light', theme)};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    transition: background 0.25s ease-out;

    :hover,
    :focus {
      background: ${theme.colors.gray[200]};
      outline: none;
    }

    &.selected,
    &[aria-selected='true'] {
      ${getFontColorStyle('primary', theme)};
      font-weight: ${theme.font.weight.bold};

      :focus::after {
        background: ${theme.colors.weaveBlue};
      }
    }
  `,
];
