import React from 'react';
import { cx } from 'emotion';
import { badgeStyle, cursorStyle } from './badge.styles';
import { badgeStyleJub, cursorStyleJub } from './badge.styles.jubjub';
interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  color?: 'blue' | 'lightBlue' | 'darkBlue' | 'green' | 'yellow' | 'red' | 'gray9' | 'gray5' | 'white' | '';
  text?: React.ReactNode;
}

/**
 * @deprecated Not replaced in new design system
 */
export const Badge = ({ className = '', color = '', text, children, ...props }: Props) => {
  const cursor = typeof props.onClick === 'function' ? cx(cursorStyle, cursorStyleJub) : '';
  return (
    <span className={cx(badgeStyle, cursor, color, badgeStyleJub, className)} {...props}>
      {text || children}
    </span>
  );
};
