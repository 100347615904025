/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const CopyIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M5.93076869,13.25 C6.62112463,13.25 7.18076869,13.8096441 7.18076869,14.5 C7.18076869,15.1903559 6.62112463,15.75 5.93076869,15.75 L4.5,15.75 C2.70507456,15.75 1.25,14.2949254 1.25,12.5 L1.25,4.5 C1.25,2.70507456 2.70507456,1.25 4.5,1.25 L12.5,1.25 C14.2949254,1.25 15.75,2.70507456 15.75,4.5 L15.75,5.92857143 C15.75,6.61892737 15.1903559,7.17857143 14.5,7.17857143 C13.8096441,7.17857143 13.25,6.61892737 13.25,5.92857143 L13.25,4.5 C13.25,4.08578644 12.9142136,3.75 12.5,3.75 L4.5,3.75 C4.08578644,3.75 3.75,4.08578644 3.75,4.5 L3.75,12.5 C3.75,12.9142136 4.08578644,13.25 4.5,13.25 L5.93076869,13.25 Z M8.25,19.5 L8.25,11.5 C8.25,9.70507456 9.70507456,8.25 11.5,8.25 L19.5,8.25 C21.2949254,8.25 22.75,9.70507456 22.75,11.5 L22.75,19.5 C22.75,21.2949254 21.2949254,22.75 19.5,22.75 L11.5,22.75 C9.70507456,22.75 8.25,21.2949254 8.25,19.5 Z M10.75,19.5 C10.75,19.9142136 11.0857864,20.25 11.5,20.25 L19.5,20.25 C19.9142136,20.25 20.25,19.9142136 20.25,19.5 L20.25,11.5 C20.25,11.0857864 19.9142136,10.75 19.5,10.75 L11.5,10.75 C11.0857864,10.75 10.75,11.0857864 10.75,11.5 L10.75,19.5 Z" />
  </SvgIcon>
  );