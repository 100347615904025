/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const EditSimpleIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M9.52575495,17.6223395 L9.52575495,18.9311988 L11.7757553,22.005727 L14.0257737,18.931198 L14.0257737,17.6223395 L9.52575495,17.6223395 Z M9.52575495,15.1223395 L14.0257737,15.1223395 L14.0257737,3.3723282 L9.52575495,3.3723282 L9.52575495,15.1223395 Z M14.275774,0.872328157 C15.518415,0.872328157 16.525774,1.8796878 16.525774,3.12233037 L16.525774,19.0129066 C16.525774,19.4907086 16.3736536,19.9561055 16.0914784,20.3416855 L13.5914758,23.7578285 C12.8576103,24.7606232 11.449769,24.9786335 10.4469742,24.244768 C10.2606364,24.1084022 10.0964,23.9441657 9.96003427,23.7578279 L7.46003397,20.3416855 C7.17785861,19.956105 7.02575495,19.4907076 7.02575495,19.0129051 L7.02575495,3.12232911 C7.02575495,1.87968842 8.03311426,0.872328157 9.27575495,0.872328157 L14.275774,0.872328157 Z" transform="translate(11.775764, 12.775764) rotate(45.000000) translate(-11.775764, -12.775764) " />
  </SvgIcon>
  );