import { css } from '@emotion/core';
import { WeaveTheme } from '../../dsm/theme';
import { getFontColorStyle } from '../../helpers/font-styles';
import {
  getFontSizeStyle,
  getFontFamilyStyle,
  getTextAlignStyle,
  getFontWeightStyle,
} from '../../dsm/text/text.style';

/**
 * @deprecated not yet replaced in design-system
 */
export const TableStyles = (theme: WeaveTheme) => css`
  &.ReactTable {
    ${getFontSizeStyle('medium', theme)};
    ${getFontFamilyStyle(theme)};
    ${getFontColorStyle('default', theme)};
    ${getTextAlignStyle('left')};
    ${getFontWeightStyle('regular', theme)};

    border: 0;

    .rt-table {
      .rt-thead {
        &.-header {
          box-shadow: none;
          border: 0;
        }

        &.-filters {
          border: 0;
        }

        .rt-tr {
          .rt-th {
            border-right: 0;
            padding: 16px 0;
            border-bottom: solid 1px ${theme.colors.gray[300]};

            &:focus {
              outline: 0;
            }

            &.-sort-desc {
              box-shadow: none;
            }

            &.-sort-asc {
              box-shadow: none;
            }

            div {
              ${getTextAlignStyle('left')};
              ${getFontSizeStyle('medium', theme)};
              ${getFontColorStyle('light', theme)};

              &:focus {
                outline: 0;
              }
            }
          }
        }
      }

      .rt-tbody {
        .rt-tr-group {
          border-bottom: solid 1px ${theme.colors.gray[300]};

          &:last-child {
            border-bottom: solid 1px ${theme.colors.gray[300]};
          }

          .rt-tr {
            .rt-td {
              ${getFontSizeStyle('large', theme)};
              ${getFontColorStyle('default', theme)};

              align-items: center;
              border-right: 0;
              display: flex;
              padding: 16px 0;
            }
          }
        }
      }
    }

    .pagination-bottom {
      .-pagination {
        border-top: 0;
        box-shadow: none;
        display: inline-block;
        text-align: right;
        width: 100%;
        padding: 16px 0;

        .-previous {
          display: inline-block;

          .-btn {
            background: transparent;
            display: inline-block;
            width: auto;

            &:not([disabled]):hover {
              ${getFontColorStyle('default', theme)};
            }
          }
        }

        .-center {
          display: inline-block;

          .-pageInfo {
            .-pageJump {
              input[type='number'] {
                -moz-appearance: textfield;
                width: 35px;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              }
            }
          }

          .-pageSizeOptions {
            display: none;
          }
        }

        .-next {
          display: inline-block;

          .-btn {
            background: transparent;
            display: inline-block;
            width: auto;

            &:not([disabled]):hover {
              ${getFontColorStyle('default', theme)};
            }
          }
        }
      }
    }
  }
`;
