import React, { useState } from 'react';
import { cx } from 'emotion';
import { noop } from 'lodash';

import { Group } from '../group/group.component';
import { Label } from '../label/label.component';
import { Svg, Icons } from '../../../svgs/svg.component';
import { Body } from '../../typography/typography.component';
import {
  actionTextStyle,
  inputStyle,
  inputIconLeftStyle,
  iconLeftStyle,
  iconRightStyle,
  inputIconRightStyle,
  inputIconRightPointer,
} from './input.styles';
import { inputStyleJub, inputIconLeftStyleJub, iconLeftStyleJub, inputIconRightStyleJub } from './input.styles.jubjub';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  actionText?: string;
  actionTextClassName?: string;
  error?: boolean;
  iconLeft?: Icons;
  iconRight?: Icons | null;
  onActionTextClick?: (event?) => void;
  onClickRightIcon?: () => void;
  gutter?: boolean;
  iconRightColor?:
    | 'white'
    | 'gray'
    | 'gray3'
    | 'gray5'
    | 'gray9'
    | 'green'
    | 'red'
    | 'blue'
    | 'yellow'
    | 'pink'
    | 'purple';
  showActionText?: boolean;
}

/**
 * @deprecated Replaced by TextField with useForm, useFormField in design-system
 */
export const Input = ({
  actionText = '',
  actionTextClassName = '',
  className = '',
  name = '',
  error = false,
  iconLeft,
  iconRight,
  iconRightColor,
  onActionTextClick,
  onClickRightIcon,
  onBlur = noop,
  onFocus = noop,
  showActionText = true,
  type = 'text',
  ...props
}: Props) => {
  let input: HTMLInputElement | null;
  const [active, setActive] = useState(!!props.value);
  const handleBlur = (evt) => {
    if (!evt.target.value) {
      setActive(false);
    }
    onBlur(evt);
  };

  const handleFocus = (evt) => {
    if (!active) {
      setActive(true);
    }
    onFocus(evt);
  };

  return (
    <Group className={cx(className, error && 'error-style')} iconLeft={iconLeft}>
      <input
        ref={(el) => (input = el)}
        name={name}
        className={cx(
          inputStyle,
          inputStyleJub,
          iconLeft ? cx(inputIconLeftStyle, inputIconLeftStyleJub) : '',
          iconRight ? cx(inputIconRightStyle, inputIconRightStyleJub) : ''
        )}
        onBlur={handleBlur}
        onFocus={handleFocus}
        type={type}
        {...props}
      />
      <Label
        className={cx(
          (active || !!props.value) && 'active',
          !!props.value && 'label-position',
          props.onClick && 'label-click-pointer'
        )}
        htmlFor={name}
        onClick={() => {
          if (input) {
            if (props.onClick) {
              input.click();
            }
            input.focus();
          }
        }}
      >
        {props.placeholder || name.replace(/(_|-)/, ' ')}
      </Label>
      {iconLeft && <Svg icon={iconLeft} className={cx(iconLeftStyle, iconLeftStyleJub, 'sm')} />}
      {iconRight && (
        <Svg
          icon={iconRight}
          color={iconRightColor || 'blue'}
          className={cx(iconRightStyle, 'sm', onClickRightIcon ? inputIconRightPointer : '')}
          onClick={onClickRightIcon ? onClickRightIcon : noop}
        />
      )}
      {actionText && showActionText && !iconRight && (
        <Body
          onClick={onActionTextClick ? onActionTextClick : noop}
          className={cx(actionTextStyle, actionTextClassName)}
        >
          {actionText}
        </Body>
      )}
    </Group>
  );
};
