/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const ReportsIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M8.23759006,2.51737099 C8.88615148,2.28081359 9.60368206,2.61480883 9.84023946,3.26337025 C10.0767969,3.91193167 9.74280161,4.62946225 9.09424019,4.86601965 C6.06040986,5.97258412 4,8.86623867 4,12.1493845 C4,16.4295913 7.46979319,19.8993845 11.75,19.8993845 C14.978178,19.8993845 17.8334532,17.9074508 18.9799194,14.9472943 C19.2292472,14.3035343 19.9532379,13.9837838 20.5969979,14.2331115 C21.2407579,14.4824393 21.5605085,15.20643 21.3111807,15.85019 C19.794899,19.7652038 16.019015,22.3993845 11.75,22.3993845 C6.08908131,22.3993845 1.5,17.8103032 1.5,12.1493845 C1.5,7.80753793 4.22461354,3.98107091 8.23759006,2.51737099 Z M19.224845,4.18638292 C20.9995582,5.70361443 22.0486149,7.86322188 22.3804092,10.5997362 C22.5299933,11.8333244 21.6512343,12.954607 20.4176461,13.104191 C20.3304956,13.1147588 20.2428014,13.1202169 20.1550132,13.1205374 L13.2582131,13.1457279 C12.0155807,13.1502486 11.0045509,12.1465731 10.9999685,10.9039407 L10.9999685,3.95003153 C10.9999685,2.70737343 12.0073419,1.7 13.25,1.7 C13.3279851,1.7 13.4059174,1.70405439 13.4834815,1.71214675 C15.6401426,1.93715405 17.5642938,2.76675028 19.224845,4.18638292 Z M13.4999697,10.6448134 L19.8614235,10.6215924 C19.5699807,8.61666091 18.813821,7.12406655 17.6003068,6.08661329 C16.3955076,5.05661057 15.0374445,4.44245374 13.4999697,4.23190364 L13.4999697,10.6448134 Z" />
  </SvgIcon>
  );