/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const CreditCardIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M20.75,9 L20.75,7 C20.75,6.58578644 20.4142136,6.25 20,6.25 L4,6.25 C3.58578644,6.25 3.25,6.58578644 3.25,7 L3.25,9 L20.75,9 Z M20.75,11.5 L3.25,11.5 L3.25,17 C3.25,17.4142136 3.58578644,17.75 4,17.75 L20,17.75 C20.4142136,17.75 20.75,17.4142136 20.75,17 L20.75,11.5 Z M4,3.75 L20,3.75 C21.7949254,3.75 23.25,5.20507456 23.25,7 L23.25,17 C23.25,18.7949254 21.7949254,20.25 20,20.25 L4,20.25 C2.20507456,20.25 0.75,18.7949254 0.75,17 L0.75,7 C0.75,5.20507456 2.20507456,3.75 4,3.75 Z" />
  </SvgIcon>
  );