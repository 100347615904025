/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const SendIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M14.1542824,6.48364887 L10.2845192,15.2551123 L14.1542824,13.707207 L14.1542824,6.48364887 Z M16.6542824,6.48364887 L16.6542824,13.707207 L20.5240457,15.2551123 L16.6542824,6.48364887 Z M5.52404571,19.8518841 L15.4042824,-2.54331919 L25.2845192,19.8518841 L15.4042824,15.8997894 L5.52404571,19.8518841 Z" transform="translate(15.404282, 8.654282) rotate(45.000000) translate(-15.404282, -8.654282) " />
  </SvgIcon>
  );