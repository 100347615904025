import { css } from 'emotion';
export const timeInput = css`
  display: grid;
  grid-template-columns: 1fr 1fr 26px 26px;
  grid-template-rows: 20px 1fr;
  justify-items: center;
  align-items: center;
  grid-gap: 3px 15px;

  label: time-input;
`;

const label = css`
  align-self: end;

  label: label;
`;

export const time = css`
  ${label};
  justify-self: start;

  label: time;
`;

export const meridiem = css`
  ${label};
  justify-self: center;

  label: meridiem;
`;

export const input = css`
  margin: 0;

  label: input;
`;
