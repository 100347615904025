import React from 'react';
import { weaveGreen, Subtitle } from '../../';
import { Svg } from '../../svgs/svg.component';
import { css } from 'emotion';
import { gray1, gray3 } from '../../foundation/colors/colors';

/**
 * @deprecated Replaced by useCalendar and Calendar in design-system
 */
export const DateRangeRow = ({ selected = false, onClick, children }) => (
  <div
    onClick={onClick}
    className={css`
      display: flex;
      flex-direction: row;
      padding-left: 12px;
      margin-bottom: -1px;
      cursor: pointer;
      &:hover {
        background-color: ${gray1};
        & > div {
          border-bottom: none;
          padding-bottom: 1px;
        }
      }
    `}
  >
    <div
      className={css`
        margin: 0 12px;
        border-bottom: 1px solid ${gray3};
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        width: 100%;
      `}
    >
      {selected && (
        <div
          className={css`
            width: 20px;
            height: 20px;
            background-color: ${weaveGreen};
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Svg icon="Check" color="white" />
        </div>
      )}
      <Subtitle
        className={css`
          margin-left: ${selected ? 16 : 32}px;
        `}
      >
        {children}
      </Subtitle>
    </div>
  </div>
);
