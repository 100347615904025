import { cx, css } from 'emotion';
import { noop } from 'lodash';
import React from 'react';
import { body } from '../../typography/typography.styles';
import { labelJub } from './label.styles.jubjub';
import { TextFit } from '../../../helpers/text-fit';

const label = css`
  width: fill-available;
  max-width: 80%;
  border-radius: 4px;
  align-self: center;
  background-color: rgba(0, 0, 0, 0) !important;
`;

const labelBackground = css`
  width: min-content;
  background-color: white !important;
  padding: 0 2px;
`;

export interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {}

/**
 * @deprecated Not replaced in design-system, use the new form components
 */
export const Label = ({ children, className = '', onClick = noop, ...props }: Props) => {
  return (
    <label onClick={onClick} {...props} className={cx(label, className)}>
      <div className={cx(body, labelJub)}>
        <TextFit mode="single" max={14}>
          <div className={cx(labelBackground)}>{children}</div>
        </TextFit>
      </div>
    </label>
  );
};
