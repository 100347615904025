import React from 'react';
import { noop } from 'lodash';
import { cx } from 'emotion';
import { dotStyle } from './dot.styles';
import { dotStyleJub } from './dot.styles.jubjub';
interface Props {
  className?: string;
  color?: 'blue' | 'lightBlue' | 'darkBlue' | 'green' | 'yellow' | 'red' | 'gray9' | 'gray7' | 'gray5' | 'pink' | '';
  size?: 'md';
  isActive?: boolean;
  title?: string;
  onClick?: (evt?: React.SyntheticEvent<Element, Event>) => void;
  children?: React.ReactNode | string;
}

/**
 * @deprecated not replaced in the design-system
 */
export const Dot = ({
  className = '',
  color = '',
  title = '',
  size = 'md',
  isActive = true,
  onClick = noop,
  children,
  ...props
}: Props) => {
  const hasTextStyle = !!children ? 'has-text' : '';

  return (
    <span
      className={cx(
        'dot-style',
        dotStyle,
        color,
        dotStyleJub,
        hasTextStyle,
        isActive ? 'is-active' : '',
        size,
        className
      )}
      onClick={onClick}
      title={title || isActive ? 'Active' : 'Inactive'}
      {...props}
    >
      {children}
    </span>
  );
};
