import { css } from 'emotion';
import { gray3, weaveGreen, gray5 } from '../../colors/colors';

export const checkboxStyle = css`
  font-family: 'Proxima Nova', sans-serif;
  appearance: none;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid ${gray3};
  border-radius: 50%;
  margin: 0;
  outline: none;
  cursor: pointer;
  transition: background-color 0.1s ease-in, border-color 0.1s ease-in;

  :focus {
    border-color: ${gray3};
  }

  :checked {
    background-color: ${weaveGreen};
    border-color: ${weaveGreen};

    :focus {
      border-color: ${weaveGreen};
    }
  }

  &[disabled],
  &.disabled {
    background-color: ${gray5};
    cursor: not-allowed;
  }

  label: checkbox;
`;

export const toggleStyle = css`
  font-family: 'Proxima Nova', sans-serif;
  position: relative;
  width: 44px;
  height: 24px;
  border: 1px solid ${gray3};
  border-radius: 20px;
  margin-right: 8px;
  vertical-align: middle;
  cursor: pointer;

  .horizontal input[type='checkbox'] {
    top: -1px;

    :checked {
      left: 18px;
    }
  }

  input[type='checkbox'] {
    transition: left 0.1s ease-in;
    background-color: ${gray3};
    position: absolute;
    top: 1px;
    left: 1px;
    width: 20px;
    height: 20px;

    :focus {
      border-color: ${gray3};
    }

    :checked {
      left: 21px;
      background-color: ${weaveGreen};

      :focus {
        border-color: ${weaveGreen};
      }
    }
  }

  label: toggleStyle;
`;

export const svg = css`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  cursor: pointer;
  pointer-events: none;
  transform: translate(-50%, -50%);

  & path {
    fill: ${gray3};
    transition: fill 0.1s ease-in, border-color 0.1s ease-in;
  }

  input[type='checkbox']:checked + & path {
    fill: white;
  }

  label: svg;
`;

export const checkboxContainerStyle = css`
  display: flex;
  line-height: 1;
  position: relative;
`;
