/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const ExternalLinkIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M8.05289714,1.75 C8.74325307,1.75 9.30289714,2.30964406 9.30289714,3 C9.30289714,3.69035594 8.74325307,4.25 8.05289714,4.25 L8.05289714,4.25 L5,4.25 C4.58578644,4.25 4.25,4.58578644 4.25,5 L4.25,5 L4.25,19 C4.25,19.4142136 4.58578644,19.75 5,19.75 L5,19.75 L19,19.75 C19.4142136,19.75 19.75,19.4142136 19.75,19 L19.75,19 L19.75,16.0270182 C19.75,15.3366623 20.3096441,14.7770182 21,14.7770182 C21.6903559,14.7770182 22.25,15.3366623 22.25,16.0270182 L22.25,16.0270182 L22.25,19 C22.25,20.7949254 20.7949254,22.25 19,22.25 L19,22.25 L5,22.25 C3.20507456,22.25 1.75,20.7949254 1.75,19 L1.75,19 L1.75,5 C1.75,3.20507456 3.20507456,1.75 5,1.75 L5,1.75 Z M20.4603107,1.70673104 C20.8418043,1.70673104 21.1833822,1.87763032 21.4126624,2.14704677 C21.681709,2.37618769 21.8523775,2.71756185 21.8523775,3.09879778 L21.8523775,8.59879778 C21.8523775,9.28915372 21.2927334,9.84879778 20.6023775,9.84879778 C19.9120215,9.84879778 19.3523775,9.28915372 19.3523775,8.59879778 L19.3529198,5.98462401 L12.836766,12.5004482 C12.3486107,12.9886036 11.5571544,12.9886036 11.0689991,12.5004482 C10.5808437,12.0122928 10.5808437,11.2208366 11.0689991,10.7326813 L17.5950523,4.20695756 L14.9603107,4.20673104 C14.2699548,4.20673104 13.7103107,3.64708698 13.7103107,2.95673104 C13.7103107,2.26637511 14.2699548,1.70673104 14.9603107,1.70673104 L20.4603107,1.70673104 Z" />
  </SvgIcon>
  );