/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const FeedbackBadIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M12.8743335,2.15929204 C13.0221159,2.20777184 13.1661426,2.26702813 13.3052539,2.33658378 C14.9358296,3.15187165 16.0284973,4.75278073 16.1935475,6.56833299 L16.3877552,8.70461776 L18.4962369,8.70461776 C20.6718986,8.70461776 22.4356209,10.4683401 22.4356209,12.6440018 C22.4356209,12.8913433 22.4123263,13.1381351 22.3660457,13.3811082 L21.2838317,19.0627321 C20.9918561,20.595604 19.6516627,21.7046178 18.0912313,21.7046178 L3.75,21.7046178 C2.50735931,21.7046178 1.5,20.6972585 1.5,19.4546178 L1.52631722,12.4437998 C1.53097223,11.2044669 2.53695974,10.2022509 3.77630135,10.2022509 L6.88909575,10.2022509 L8.86152001,4.18965157 C9.40895954,2.52087544 11.2055573,1.61185251 12.8743335,2.15929204 Z M6.566,12.702 L4.02536409,12.7022509 L4.00094078,19.2046178 L6.566,19.204 L6.566,12.702 Z M11.2369675,4.96891358 L9.20809468,11.1535867 C9.16974841,11.2704788 9.12245095,11.382581 9.06711193,11.4892344 L9.066,19.204 L18.0912313,19.2046178 C18.4513309,19.2046178 18.7606063,18.9486915 18.8279853,18.5949518 L19.9101993,12.913328 C19.9271095,12.8245497 19.9356209,12.7343762 19.9356209,12.6440018 C19.9356209,11.8490519 19.2911867,11.2046178 18.4962369,11.2046178 L16.1594515,11.2046178 C14.9957443,11.2046178 14.0240489,10.3172513 13.9186918,9.15832319 L13.7038145,6.79467235 C13.6171494,5.84135628 13.0434079,5.00074575 12.1872199,4.57265175 C12.1574722,4.5577779 12.1266734,4.54510648 12.0950714,4.53473951 C11.7382187,4.41767451 11.3540325,4.61206086 11.2369675,4.96891358 Z" transform="translate(11.967810, 11.852309) scale(-1, -1) translate(-11.967810, -11.852309) " />
  </SvgIcon>
  );