import { gray3, gray5, gray7, weaveRed, weaveBlue, gray1, weaveBlueLight, weaveGreen } from '../../colors/colors';

import { inputHeight } from './select.styles';
import { jubcss } from '../../../helpers/jub-css';

export const selectInputJub = jubcss(`
  font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
  height: ${inputHeight}px !important;
  text-align: left;
  width: 100% !important;
  background-color: white !important;
  border: 1px solid ${gray3} !important;
  transition: border-color 0.2s ease-in !important;
  padding-left: 15px !important;
  border-radius: 5px !important;
  display: block !important;
  margin: 15px 0 0 !important;
  outline: none !important;
  appearance: none !important;
  position: relative !important;
  cursor: pointer !important;

  .icon-wv {
    position: absolute !important;
    top: 10px !important;
    right: 15px !important;
  }

  .placeholder {
    color: ${gray7} !important;
    font-size: 13px !important;
  }

  &.error-style {
    border-color: ${weaveRed} !important;
  }

  &.active, &:active,
  &.focus, &:focus {
    border-color: ${weaveBlue} !important;
  }

  label: selectInput;
`);
export const optionsContainerJub = (displayLength) =>
  jubcss(`
  position: absolute !important;
  top: ${inputHeight}px !important;
  left: 0 !important;
  right: 0 !important;
  max-height: ${38 * displayLength + 2}px !important;
  background: white !important;
  box-shadow: 0 0 10px ${gray1} !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  border-radius: 5px !important;
  border: 1px solid ${gray1} !important;
  cursor: pointer !important;
  z-index: 1 !important;
  opacity: 0 !important;
  display:none;
  visibility: hidden !important;
  transition: visibility 0s, opacity 0.1s ease-in !important;

  &.show-options {
    display:block;
    visibility: visible !important;
    transition: opacity 0.1s ease-in, visibility 1s !important;
    opacity: 1 !important;
  }

  label: optionsContainer;
`);

export const optionStyleJub = jubcss(`
  height: 38px !important;
  padding-left: 20px !important;
  padding-top: 9px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  cursor: pointer !important;
  text-overflow: ellipsis !important;
  transition: background-color 0.1s ease-in !important;
  white-space: nowrap !important;

  &:hover {
    background-color: ${gray1} !important;
  }
  &:active {
    color: ${gray5} !important;
  }
  .selected {
    color: ${weaveBlue} !important;
  }

  label: optionStyle;
`);

export const placeholderStyleJub = jubcss(`
  display: inline-block !important;
  cursor: pointer !important;
  margin-top: 10px !important;

  label: placeholderStyle;
`);

export const selectedTextJub = jubcss`
  width: 85% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
`;

export const activeOptionJub = jubcss(`
  color: ${weaveGreen} !important;

  label: activeOption;
`);

export const addOptionStyleJub = jubcss(`
  color: ${weaveBlue} !important;
  font-size: 15px !important;
  transition: 300ms !important;

  :hover {
    color: ${weaveBlueLight} !important;
  }

  label: addOptionStyle;
`);
