import React from 'react';
import { cx } from 'emotion';
import { buttonStyle } from './button.styles';
import { buttonStyleJub } from './button.styles.jubjub';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fill?: 'solid' | 'outline';
  variant?: 'square' | 'rounded';
  color?: 'blue' | 'green' | 'gray' | 'red';
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
}

/**
 * @deprecated Use PrimaryButton or SecondaryButton from design-system
 */
export const Button = ({
  className,
  children,
  color = 'blue',
  fill = 'solid',
  variant = 'rounded',
  size = 'medium',
  fullWidth = false,
  ...props
}: Props) => {
  const { disabled = false } = props;
  const styles = buttonStyle({ color, fill, size, variant, fullWidth, disabled });
  const jubjubStyles = buttonStyleJub({ color, fill, size, variant, fullWidth, disabled });
  const classNames = cx(styles, jubjubStyles, className);
  return (
    <button className={classNames} {...props}>
      {children}
    </button>
  );
};
