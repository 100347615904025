import { useEffect, useRef } from 'react';

type Attributes = { [key: string]: string | number };

function getOrCreateRoot(ref, attributes: Attributes) {
  if (!ref.current) {
    ref.current = document.createElement('div');

    for (var key in attributes) {
      ref.current.setAttribute(key, attributes[key]);
    }
    ref.current.setAttribute('style', 'width: 0; height: 0');
  }
  return ref.current;
}

/**
 * @deprecated import from design-system
 */
export function usePortal(selector: string, attributes: Attributes) {
  const rootEl = useRef<HTMLDivElement>();

  useEffect(() => {
    if (selector) {
      const target = document.querySelector(selector);
      if (target && rootEl.current) {
        target.appendChild(rootEl.current);
      }
    }

    return () => {
      if (rootEl.current) {
        rootEl.current.remove();
      }
    };
  }, []);

  return getOrCreateRoot(rootEl, attributes);
}
