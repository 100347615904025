import React from 'react';
import { cx } from 'emotion';

import { lbStyle } from './line-break.styles';

interface Props {
  className?: string;
}
/**
 * @deprecated not replaced in the design-system, can use the new theme to style an HR
 */
const LineBreak = ({ className = '' }: Props) => {
  return <div className={cx(className, lbStyle)} />;
};

const Lb = LineBreak;
export { Lb, LineBreak };
