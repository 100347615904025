import React, { useEffect, useRef, forwardRef } from 'react';
import { animated } from 'react-spring';
import { FlyoutMenuItem } from './flyout-menu-item.component';
import { FlyoutMenuProps } from './use-flyout-menu';
import { KeyCodes } from 'constants/key-codes';
import { css } from '@emotion/core';

export const FlyoutMenu: React.ForwardRefExoticComponent<FlyoutMenuProps> = forwardRef(
  ({ actions, onClose, ...rest }, ref: React.RefObject<HTMLUListElement>) => {
    const indexRef = useRef(0);

    const moveFocus = (index: number) => {
      indexRef.current = index;
      if (ref && ref.current) {
        const nextItem = ref.current.querySelectorAll('li')[index];
        if (nextItem) {
          nextItem.focus();
        }
      }
    };

    const handleKeyDown = (action: () => void) => (e) => {
      switch (e.keyCode) {
        case KeyCodes.Escape:
          return onClose();
        case KeyCodes.End:
          e.preventDefault();
          return moveFocus(actions.length - 1);
        case KeyCodes.Enter:
          e.preventDefault();
          return action();
        case KeyCodes.Home:
          e.preventDefault();
          return moveFocus(0);
        case KeyCodes.Down:
          e.preventDefault();
          if (indexRef.current + 1 === actions.length) {
            return moveFocus(0);
          }
          return moveFocus(indexRef.current + 1);
        case KeyCodes.Up:
          e.preventDefault();
          if (indexRef.current === 0) {
            return moveFocus(actions.length - 1);
          }
          return moveFocus(indexRef.current - 1);
        default:
          break;
      }
    };

    useEffect(() => {
      moveFocus(0);
    }, []);

    return (
      <animated.ul
        css={css`
          background: white;
          list-style-type: none;
          margin: 0;
          padding: 0;
          position: absolute;
        `}
        ref={ref}
        {...rest}
      >
        {actions.map(({ onClick, ...rest }, i) => {
          const handleClick = () => {
            onClick();
            onClose();
          };
          return (
            <FlyoutMenuItem
              key={`menu-item-${i}`}
              {...rest}
              onClick={handleClick}
              onKeyDown={handleKeyDown(handleClick)}
            />
          );
        })}
      </animated.ul>
    );
  }
);
