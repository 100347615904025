import { Node, Text } from 'slate';
import { TAG_ELEMENT_TYPE, TagType } from '../message-template.models';

const serializeNode = (
  node: Node,
  tagProp: keyof Omit<TagType, 'invalid'> = 'key'
): string => {
  if (Text.isText(node)) {
    return Node.string(node);
  } else {
    switch (node.type) {
      case TAG_ELEMENT_TYPE:
        const tag = node.tag as TagType;

        if (!tag || tag.invalid) {
          return '';
        }
        return tag[tagProp] ?? '';
      default:
        // do nothing - unexpected node type
        return '';
    }
  }
};

const serializeLine = (
  nodes: Node | Node[],
  tagProp: keyof Omit<TagType, 'invalid'> = 'key'
): string => {
  if (nodes === undefined || nodes === null) {
    return '';
  }
  if (!(nodes instanceof Array)) {
    return serializeNode(nodes);
  }
  return nodes
    .map((node) =>
      node.children
        ? serializeNode(node, tagProp) + serializeLine(node.children as Node)
        : serializeNode(node, tagProp)
    )
    .join('');
};

/**
 * Serializes the editor nodes into a plain text template with the tag keys.
 * @param nodes
 */
export const serializePlainText = (nodes: Node[]): string => {
  return nodes
    .map((node) => serializeLine(node.children as Node))
    .join('\n')
    .trim();
};

/**
 * Serializes the editor nodes into a plain text of the template
 * using the values of the tags.
 */
export const serializePlainTextValues = (nodes: Node[]): string => {
  return nodes
    .map((node) => serializeLine(node.children as Node, 'value'))
    .join('\n')
    .trim();
};

export const testItems = { serializeNode, serializePlainText };
