import { jubcss } from '../../helpers/jub-css';

export const cursorStyleJub = jubcss`
`;

export const badgeStyleJub = jubcss`
  font-size: 10px !important;
  display: inline-block !important;
  text-align: center !important;
  color: white !important;
  padding: 4px 8px !important;
  border-radius: 10px !important;
  text-transform: uppercase !important;
  cursor: pointer !important;
  outline: none !important;
  transition: opacity 0.2s ease-in !important;
  font-family: 'Proxima Nova', sans-serif !important;

  label: badgeStyle;
`;
