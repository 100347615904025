/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const DownloadIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M22,15.7499978 C22.6472087,15.7499978 23.1795339,16.2418744 23.2435464,16.8721947 L23.25,17 L23.25,20.5 C23.25,21.4181734 22.5428897,22.1711903 21.6435272,22.2441966 L21.5,22.2499978 L2.49769024,22.2499978 C1.57951842,22.2482998 0.827807845,21.5397988 0.756458742,20.6403028 L0.750921991,20.4967651 L0.757396109,16.9976894 C0.758672225,16.3073346 1.31934983,15.748726 2.00970459,15.7499978 C2.65691217,15.7511982 3.18832724,16.2440563 3.25117449,16.8744937 L3.25739184,17.0023106 L3.25231275,19.7499978 L20.75,19.7499978 L20.75,17 C20.75,16.3096441 21.3096441,15.7499978 22,15.7499978 Z M11.75,2 C12.4403559,2 13,2.55964406 13,3.25 L12.999922,12.479 L14.8629708,10.6161165 C15.3511261,10.1279612 16.1425824,10.1279612 16.6307377,10.6161165 C17.1188931,11.1042719 17.1188931,11.8957281 16.6307377,12.3838835 L12.7416504,16.2729708 C12.4720759,16.5425453 12.1100071,16.6632524 11.7576004,16.6350921 C11.4051035,16.6634155 11.0427275,16.5427275 10.7729708,16.2729708 L6.88388348,12.3838835 C6.39572811,11.8957281 6.39572811,11.1042719 6.88388348,10.6161165 C7.37203884,10.1279612 8.16349506,10.1279612 8.65165043,10.6161165 L10.499922,12.465 L10.5,3.25 C10.5,2.55964406 11.0596441,2 11.75,2 Z" />
  </SvgIcon>
  );