import React, { useRef, useState } from 'react';
import { cx } from 'emotion';

import { Svg, Subtitle } from '../../';
import * as styles from './audio.styles';
import * as jubstyles from './audio.styles.jubjub';

interface Props {
  autoplay?: boolean;
  className?: string;
  loop?: boolean;
  muted?: boolean;
  onEnded?: () => void;
  onPlay?: () => void;
  preload?: 'auto' | 'metadata' | 'none';
  src: string;
  trackWidth?: number;
}

export function getTime(time: number) {
  if (!isNaN(time)) {
    return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2);
  }

  return '0:00';
}

/**
 * @deprecated Not yet replaced in design-system
 */
export const Audio = ({
  autoplay = false,
  className,
  loop = false,
  muted = false,
  onEnded,
  onPlay,
  preload = 'metadata',
  src,
  trackWidth = 138,
}: Props) => {
  const audioElement = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [hasError, setHasError] = useState<boolean>(false);
  const isLoaded = duration > 0;

  const onPlayClick = () => {
    if (audioElement && audioElement.current && !isPlaying) {
      setIsPlaying(true);
      audioElement.current.play();
    }
  };

  const onPauseClick = () => {
    if (audioElement && audioElement.current && isPlaying) {
      setIsPlaying(false);
      audioElement.current.pause();
    }
  };

  const renderPlayPause = (playing: boolean, loaded: boolean, error: boolean) => {
    if (error) {
      return <Svg className={cx(styles.icon, jubstyles.icon)} color="gray3" icon="Play" />;
    }

    return (
      <Svg
        className={cx(styles.icon, jubstyles.icon, !playing || loaded ? 'clickable' : '')}
        color={playing && !loaded ? 'gray3' : 'gray9'}
        icon={playing && loaded ? 'Pause' : 'Play'}
        onClick={playing && loaded ? onPauseClick : onPlayClick}
      />
    );
  };

  const onAudioEnd = () => {
    setTimeout(() => {
      setIsPlaying(false);
      setCurrentTime(0);
    }, 100);

    if (onEnded) {
      onEnded();
    }
  };

  const onAudioPlay = () => {
    if (onPlay) {
      onPlay();
    }
  };

  const onCurrentTimeChange = (e) => {
    const time = +e.target.value;

    if (audioElement && audioElement.current) {
      setCurrentTime(time);
      audioElement.current.currentTime = time;
    }
  };

  return (
    <div className={cx(styles.audioContainer, jubstyles.audioContainer, className)}>
      {renderPlayPause(isPlaying, isLoaded, hasError)}
      <div className={cx(styles.containRangeSlider(trackWidth), jubstyles.containRangeSlider(trackWidth))}>
        <input
          className={cx(
            styles.rangeSlider(currentTime, duration),
            jubstyles.rangeSlider(currentTime, duration),
            hasError ? 'error' : ''
          )}
          disabled={hasError}
          onChange={onCurrentTimeChange}
          type="range"
          min="0"
          step="0.000001"
          max={duration}
          value={currentTime}
        />
      </div>
      <Subtitle>
        <span className={cx(styles.time, jubstyles.time)}>{hasError ? 'N/A' : getTime(duration)}</span>
      </Subtitle>
      <audio
        ref={audioElement}
        onCanPlay={() => setHasError(false)}
        onPlay={onAudioPlay}
        onEnded={onAudioEnd}
        onError={() => setHasError(true)}
        onLoad={() => setHasError(false)}
        onTimeUpdate={(e: any) => setCurrentTime(e.target.currentTime)}
        onDurationChange={(e: any) => setDuration(e.target.duration)}
        autoPlay={autoplay}
        loop={loop}
        muted={muted}
        preload={preload}
        src={src}
      >
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
};
