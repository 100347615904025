import React from 'react';
import { noop } from 'lodash';
import { cx } from 'emotion';
import { cardStyle } from './card.styles';
import { Title, Body } from '../../foundation/typography/typography.component';

interface Props {
  className?: string;
  size?: '' | 'sm' | 'md';
  color?: '' | 'gray';
  title?: string;
  svg?: any;
  text?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

/**
 * @deprecated Not replaced in design-system, use styles from the weave theme
 */
export const Card = ({ title, svg, text, color = '', className = '', children, onClick }: Props) => {
  const Svg = svg;
  return (
    <div className={cx(cardStyle, color, className)} onClick={onClick || noop}>
      {children || (
        <div className="default center-text">
          <Title>{title}</Title>
          <div className="svg">
            <Svg className="card-svg" />
          </div>
          <Body>{text}</Body>
        </div>
      )}
    </div>
  );
};
