import { css } from 'emotion';
import {
  gray1,
  gray3,
  gray5,
  gray7,
  gray9,
  weaveBlue,
  weaveBlueDark,
  weaveRed,
  weaveGreen,
} from '../colors/colors';

export const title = css`
  color: ${gray9};
  font-weight: 800;
  font-size: 20px;
  box-sizing: border-box;
  font-weight: 800;

  .browser-wv & {
    font-size: 30px;
    .lg {
      font-size: 28px;
    }
  }
  &.light,
  .light-text-wv & {
    color: ${gray1};
  }
  .lg {
    font-size: 24px;
  }
  label: title;
`;
export const headline = css`
  color: ${gray9};
  font-size: 22px;
  box-sizing: border-box;

  .browser-wv & {
    font-size: 28px;
  }
  &.light,
  .light-text-wv & {
    color: ${gray1};
  }
  label: headline;
`;
export const subtitle = css`
  color: ${gray9};
  font-size: 15px;

  .browser-wv & {
    font-size: 22px;
  }
  &.light,
  .light-text-wv & {
    color: ${gray3};
  }
  label: subtitle;
`;
export const body = css`
  line-height: 1.4em;
  text-align: left;
  color: ${gray7};
  font-size: 13px;

  .browser-wv & {
    font-size: 14px;
  }
  &.light,
  .light-text-wv & {
    color: ${gray5};
  }
  label: body;
`;
export const strong = css`
  text-align: left;
  font-weight: 700;
  color: ${gray9};
  font-size: 13px;

  .browser-wv & {
    font-size: 14px;
  }
  &.light,
  .light-text-wv & {
    color: ${gray3};
  }
  label: strong;
`;
export const anchor = css`
  color: ${weaveBlue};
  font-size: 13px;
  text-decoration: none;

  &:hover {
    color: ${weaveBlueDark};
  }
  .browser-wv & {
    font-size: 14px;
  }
  label: anchor;
`;
export const secondary = css`
  color: ${gray5};
  font-size: 11px;

  .browser-wv & {
    font-size: 12px;
  }
  &.light,
  .light-text-wv & {
    color: ${gray3};
  }
  label: subtitle;
`;
export const warn = css`
  color: ${weaveRed};
  font-size: 11px;

  .browser-wv & {
    font-size: 13px;
  }
  label: errorText;
`;
export const money = css`
  color: ${weaveGreen};
  font-size: 15px;

  &.black {
    color: black;
  }
  &.gray {
    color: ${gray5};
  }
  &.red {
    color: ${weaveRed};
  }
  .browser-wv & {
    font-size: 18px;
    &.md {
      font-size: 16px;
    }
    &.lg {
      font-size: 20px;
    }
    &.xl {
      font-size: 60px;
    }
  }
  &.strong {
    font-weight: 600;
  }
  &.md {
    font-size: 13px;
  }
  &.lg {
    font-size: 18px;
  }
  &.xl {
    font-size: 50px;
  }
  label: errorText;
`;
