import React, { ElementType, ReactNode } from 'react';
import { css } from '@emotion/core';
import { WeaveTheme } from '../theme';
import { getFontColorStyle } from '../../helpers/font-styles';
import {
  getFontWeightStyle,
  getTextAlignStyle,
  getFontFamilyStyle,
} from '../text/text.style';

interface HeadingProps extends React.HTMLAttributes<HTMLElement> {
  level?: 1 | 2;
  color?: 'default' | 'white';
  textAlign?: 'left' | 'center' | 'right';
  as?: ElementType;
  children: ReactNode;
  className?: string;
}

const FONT_SIZE_MAP = {
  1: 24,
  2: 20,
};

const HTML_TAG_MAP = {
  1: 'h1',
  2: 'h2',
};

/**
 * @deprecated import from design-system
 */
export function Heading({
  level = 1,
  color = 'default',
  textAlign = 'left',
  className = '',
  as,
  ...props
}: HeadingProps) {
  const Component = !!as ? as : HTML_TAG_MAP[level];
  return (
    <Component
      css={(theme: WeaveTheme) => [
        getFontFamilyStyle(theme),
        getFontWeightStyle('bold', theme),
        getTextAlignStyle(textAlign),
        getFontColorStyle(color, theme),
        css`
          font-size: ${theme.fontSize(FONT_SIZE_MAP[level])};
          line-height: 1.35;
        `,
      ]}
      className={className}
      {...props}
    />
  );
}
