/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const XIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M13.767767,12 L19.8838835,18.1161165 C20.3720388,18.6042719 20.3720388,19.3957281 19.8838835,19.8838835 C19.3957281,20.3720388 18.6042719,20.3720388 18.1161165,19.8838835 L12,13.767767 L5.88388348,19.8838835 C5.39572811,20.3720388 4.60427189,20.3720388 4.11611652,19.8838835 C3.62796116,19.3957281 3.62796116,18.6042719 4.11611652,18.1161165 L10.232233,12 L4.11611652,5.88388348 C3.62796116,5.39572811 3.62796116,4.60427189 4.11611652,4.11611652 C4.60427189,3.62796116 5.39572811,3.62796116 5.88388348,4.11611652 L12,10.232233 L18.1161165,4.11611652 C18.6042719,3.62796116 19.3957281,3.62796116 19.8838835,4.11611652 C20.3720388,4.60427189 20.3720388,5.39572811 19.8838835,5.88388348 L13.767767,12 Z" />
  </SvgIcon>
  );