import { cx } from 'emotion';
import React from 'react';
import * as styles from './calendar.styles';
import moment from 'moment';
import { Subtitle } from '../../foundation';

interface Props {
  date: moment.Moment;
  isDateVisible: boolean;
  isDayBetweenMinMaxDate: boolean | null;
  selection: Selection;
  dateClicked: (date: moment.Moment) => void;
}

export enum Selection {
  None,
  Only,
  Start,
  End,
  Range,
}

/**
 * @deprecated Replaced by useCalendar, DatePickerField, RangePickerField, and other Calendar layouts in design-system
 */
export class CalendarDay extends React.Component<Props> {
  public render() {
    return (
      <Subtitle
        className={cx(this.getClassNames(), this.props.isDayBetweenMinMaxDate === false && styles.disabledCell)}
        onClick={this.handleClick}
      >
        {this.props.isDateVisible && <span className={cx(styles.value, 'value')}>{this.props.date.date()}</span>}
      </Subtitle>
    );
  }

  private getClassNames = () => {
    let classNames = [styles.day];

    const isDateFirstDay = () => {
      return this.props.date.date() === 1;
    };

    const isDateLastDay = () => {
      const lastDay = this.props.date.clone().endOf('month');
      return this.props.date.isSame(lastDay, 'day');
    };

    if (isDateFirstDay()) {
      classNames.push('first-day');
    } else if (isDateLastDay()) {
      classNames.push('last-day');
    }

    if (this.props.date.isSame(moment(), 'day')) {
      classNames.push(styles.today);
    }

    if (this.props.isDateVisible === false) {
      return classNames;
    }

    switch (this.props.selection) {
      case Selection.Only:
        classNames.push('only');
        break;

      case Selection.Start:
        classNames.push(styles.selected);
        classNames.push('start');
        break;

      case Selection.End:
        classNames.push(styles.selected);
        classNames.push('end');
        break;

      case Selection.Range:
        classNames.push(styles.selected);
        break;

      case Selection.None:
        // Don't add any names other than styles.day
        break;

      default:
        // Don't add any names other than styles.day
        break;
    }

    return classNames;
  };

  private handleClick = () => {
    if (this.props.isDateVisible) {
      this.props.dateClicked(this.props.date);
    }
  };
}
