import React from 'react';
import MUIModal, { ModalProps as MUIModalProps } from '@material-ui/core/Modal';
import MUIBackdrop from '@material-ui/core/Backdrop';
import MUIFade from '@material-ui/core/Fade';

interface ModalProps extends Omit<Omit<MUIModalProps, 'BackdropComponent'>, 'children'> {
  children: React.ReactElement;
}

export const DSMModalBackdrop = ({ children, open, ...props }: ModalProps) => {
  return (
    <MUIModal
      open={open}
      BackdropComponent={MUIBackdrop}
      BackdropProps={{ timeout: 300 }}
      closeAfterTransition
      {...props}
    >
      <MUIFade in={open} timeout={{ enter: 500, exit: 150 }}>
        {children}
      </MUIFade>
    </MUIModal>
  );
};
