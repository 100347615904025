/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const EmojiSadIconSmall: React.FC<SvgIconProps> = props => (
  <SvgIcon size={16} {...props} viewBox="0 0 16 16">
    <path d="M8,15.5 C3.85786438,15.5 0.5,12.1421356 0.5,8 C0.5,3.85786438 3.85786438,0.5 8,0.5 C12.1421356,0.5 15.5,3.85786438 15.5,8 C15.5,12.1421356 12.1421356,15.5 8,15.5 Z M8,13.5 C11.0375661,13.5 13.5,11.0375661 13.5,8 C13.5,4.96243388 11.0375661,2.5 8,2.5 C4.96243388,2.5 2.5,4.96243388 2.5,8 C2.5,11.0375661 4.96243388,13.5 8,13.5 Z M6,7 C5.44771525,7 5,6.55228475 5,6 C5,5.44771525 5.44771525,5 6,5 C6.55228475,5 7,5.44771525 7,6 C7,6.55228475 6.55228475,7 6,7 Z M10,7 C9.44771525,7 9,6.55228475 9,6 C9,5.44771525 9.44771525,5 10,5 C10.5522847,5 11,5.44771525 11,6 C11,6.55228475 10.5522847,7 10,7 Z M11.7583274,9.84812614 C12.1183474,10.2669388 12.0706865,10.8983074 11.6518739,11.2583274 C11.2330612,11.6183474 10.6016926,11.5706865 10.2416726,11.1518739 C9.48823431,10.2753962 9.02472746,10 8,10 C6.93139851,10 6.35911473,10.3279171 5.81001982,11.0864025 C5.48615866,11.5337641 4.86095909,11.633881 4.4135975,11.3100198 C3.96623591,10.9861587 3.86611902,10.3609591 4.18998018,9.9135975 C5.09637102,8.66156576 6.25094151,8 8,8 C9.6997013,8 10.6602307,8.57070597 11.7583274,9.84812614 Z" />
  </SvgIcon>
  );