import React, { Component, createContext } from 'react';
import { createPortal } from 'react-dom';
import tabbable from 'tabbable';
import { genUID } from '../../helpers/gen-uid';

export type RendererContext = {
  containerProps: {
    role: string;
    'aria-labelledby': string;
    'aria-describedby': string;
    'aria-modal': 'true' | 'false';
  };
  descriptionId: string;
  titleId: string;
  uid: string;
};

interface Props {
  children: React.ReactNode;
  className?: string;
  containerAttributes?: { [key: string]: string };
  onClose: (event: KeyboardEvent) => void;
  placement?: 'after' | 'before';
  target?: HTMLElement;
  timeout?: number;
}

export const ModalRendererContext = createContext<RendererContext>({
  containerProps: {
    role: 'dialog',
    'aria-labelledby': '',
    'aria-describedby': '',
    'aria-modal': 'true',
  },
  descriptionId: '',
  titleId: '',
  uid: '',
});

export class ModalRenderer extends Component<Props> {
  private uid: string = genUID();
  private descriptionId: string = `modal-${this.uid}-desc`;
  private titleId: string = `modal-${this.uid}-title`;
  private contextValue: RendererContext = {
    containerProps: {
      role: 'dialog',
      'aria-labelledby': this.titleId,
      'aria-describedby': this.descriptionId,
      'aria-modal': 'true',
    },
    descriptionId: this.descriptionId,
    titleId: this.titleId,
    uid: this.uid,
  };
  private target: HTMLElement;
  private container: HTMLDivElement;
  private firstTab: HTMLDivElement;
  private lastTab: HTMLDivElement;

  constructor(props) {
    super(props);
    // get the DOM target
    this.target = props.target || document.body;

    // create portal container with needed attributes
    this.container = document.createElement('div');
    this.container.id = `modal-${this.uid}`;
    this.container.tabIndex = -1;
    if (props.className) {
      this.container.className = props.className;
    }
    // if any additional portal props are passed, add them
    if (props.containerAttributes) {
      Object.entries(props.containerAttributes).forEach(([key, value]) => {
        this.container.setAttribute(key, value as string);
      });
    }
    // insert the portal container in the right place
    if (props.placement === 'before' && this.target.firstChild) {
      this.target.insertBefore(this.container, this.target.firstChild);
    } else {
      this.target.appendChild(this.container);
    }
  }

  componentDidMount() {
    // disable page scrolling
    document.body.style.overflow = 'hidden';
    // trap focus to the modal
    setTimeout(() => {
      this.trapTabbables();
      this.container.addEventListener('keydown', this.closeOnEsc);
      // move focus to the modal
      this.firstTab.focus();
    }, this.props.timeout || 100);
  }

  componentDidUpdate() {
    // retrap focus, in case the content changed
    this.resetTabbables();
    this.trapTabbables();
  }

  componentWillUnmount() {
    // reenable page scrolling
    document.body.style.overflow = '';
    // cleanup listeners before killing the portal
    if (this.container) {
      this.container.removeEventListener('keydown', this.closeOnEsc);
    }
    this.resetTabbables();
    this.target.removeChild(this.container);
  }

  closeOnEsc = (e) => {
    if (e.keyCode === 27) {
      // stop bubbling so we only close the topmost modal
      // keeping the rest of the stack intact
      e.stopPropagation();
      this.props.onClose(e);
    }
  };

  resetTabbables = () => {
    this.firstTab && this.firstTab.removeEventListener('keydown', this.killTab);
    this.lastTab && this.lastTab.removeEventListener('keydown', this.trapFocus);
  };

  trapTabbables = () => {
    const tabs = tabbable(this.container);
    this.firstTab = tabs[0];

    if (tabs.length > 1) {
      this.lastTab = tabs[tabs.length - 1];
      this.firstTab.addEventListener('keydown', this.trapFocus);
      this.lastTab.addEventListener('keydown', this.trapFocus);
    } else if (this.firstTab) {
      this.firstTab.addEventListener('keydown', this.killTab);
    }
  };

  killTab = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
    }
  };

  trapFocus = (e) => {
    if (e.keyCode === 9) {
      if (e.target === this.lastTab && !e.shiftKey) {
        e.preventDefault();
        this.firstTab.focus();
      }
      if (e.target === this.firstTab && e.shiftKey) {
        e.preventDefault();
        this.lastTab.focus();
      }
    }
  };

  render() {
    return (
      <ModalRendererContext.Provider value={this.contextValue}>
        {createPortal(this.props.children, this.container)}
      </ModalRendererContext.Provider>
    );
  }
}
