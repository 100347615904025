import React from 'react';
import { debounce } from 'lodash';
import { cx } from 'emotion';

import { Input } from '../';

export interface SearchProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  className?: string;
  placeholder?: string;
  value: string;
  name: string;
  debounce?: number;
  onChange: (value: string) => void;
}

export interface SearchState {
  value: string;
}
/**
 * @deprecated Use SearchField with useForm, useFormField from design-system
 */
export class Search extends React.Component<SearchProps, SearchState> {
  public debouncedPropsOnChange = debounce(() => {
    this.props.onChange(this.state.value);
  }, this.props.debounce || 0);

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
    };
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  public handleChange = (evt) => {
    this.setState(
      {
        value: evt.target.value,
      },
      this.handleChangeCallback
    );
  };

  public handleChangeCallback = () => {
    if (this.props.debounce && this.props.debounce > 0) {
      this.debouncedPropsOnChange();
    } else {
      this.props.onChange(this.state.value);
    }
  };

  public render() {
    const { className = '', debounce, placeholder = 'Search...', name = '', onChange, value, ...rest } = this.props;
    return (
      // @ts-ignore
      <Input
        className={cx('search-input', className)}
        onChange={this.handleChange}
        name={name}
        value={this.state.value}
        iconLeft="Search"
        placeholder={placeholder}
        {...rest}
      />
    );
  }
}
