import React, { forwardRef, useEffect } from 'react';
import { FlyoutProps } from './use-flyout';
import { animated } from 'react-spring';
import { KeyCodes } from 'constants/key-codes';
import { css } from '@emotion/core';

type FlyoutComponentProps = Omit<FlyoutProps, 'ref'> &
  React.HTMLAttributes<HTMLDivElement> & {
    style?: React.CSSProperties;
  };

/**
 * @deprecated import from design-system
 */
export const Flyout: React.ForwardRefExoticComponent<FlyoutComponentProps> = forwardRef(
  ({ children, onClose, ...rest }, ref: React.RefObject<HTMLDivElement>) => {
    const handleKeyDown = (e) => {
      if (e.keyCode === KeyCodes.Escape) onClose();
    };

    useEffect(() => {
      if (ref.current) {
        ref.current.focus();
      }
    }, []);

    return (
      <animated.div
        css={css`
          background: white;
          position: absolute;

          :focus {
            outline: none;
          }
        `}
        {...rest}
        ref={ref}
        onKeyDown={handleKeyDown}
        tabIndex={-1}
      >
        {children}
      </animated.div>
    );
  }
);
