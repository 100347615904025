import React from 'react';
import { cx } from 'emotion';

import { Group } from '../group/group.component';
import { Label } from '../label/label.component';
import { textareaStyle } from './textarea.styles';
import { textareaStyleJub } from './textarea.styles.jubjub';

export type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  error?: boolean;
};

/**
 * @deprecated Use TextareaField with useForm, useFormField in design-system
 */
export const Textarea = ({ className = '', label = '', name = '', error = false, ...props }: Props) => {
  return (
    <Group>
      <textarea
        name={name}
        className={cx(textareaStyle, textareaStyleJub, error && 'error-style', className)}
        {...props}
      />
      <Label htmlFor={name}>{label}</Label>
    </Group>
  );
};
