/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const DataSyncIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M16.767767,6.5 L14.9393398,4.67157288 C14.4511845,4.18341751 14.4511845,3.39196129 14.9393398,2.90380592 C15.4274952,2.41565056 16.2189514,2.41565056 16.7071068,2.90380592 L20.5961941,6.79289322 C20.8402718,7.0369709 20.9623106,7.3568738 20.9623106,7.6767767 C20.9623106,7.99667959 20.8402718,8.31658249 20.5961941,8.56066017 L16.7071068,12.4497475 C16.2189514,12.9379028 15.4274952,12.9379028 14.9393398,12.4497475 C14.4511845,11.9615921 14.4511845,11.1701359 14.9393398,10.6819805 L16.6213203,9 L5.75,9 C5.05964406,9 4.5,8.44035594 4.5,7.75 C4.5,7.05964406 5.05964406,6.5 5.75,6.5 L16.767767,6.5 Z M7.23223305,15.5 L18.25,15.5 C18.9403559,15.5 19.5,16.0596441 19.5,16.75 C19.5,17.4403559 18.9403559,18 18.25,18 L7.37867966,18 L9.06066017,19.6819805 C9.54881554,20.1701359 9.54881554,20.9615921 9.06066017,21.4497475 C8.57250481,21.9379028 7.78104858,21.9379028 7.29289322,21.4497475 L3.40380592,17.5606602 C3.15972824,17.3165825 3.0376894,16.9966796 3.0376894,16.6767767 C3.0376894,16.3568738 3.15972824,16.0369709 3.40380592,15.7928932 L7.29289322,11.9038059 C7.78104858,11.4156506 8.57250481,11.4156506 9.06066017,11.9038059 C9.54881554,12.3919613 9.54881554,13.1834175 9.06066017,13.6715729 L7.23223305,15.5 Z" />
  </SvgIcon>
  );