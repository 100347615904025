import React from 'react';
import MUICheckbox, { CheckboxProps as MUICheckboxProps } from '@material-ui/core/Checkbox';

// interface CheckboxProps extends MUICheckboxProps {
//   className?: string;
// }
/**
 * @deprecated Replaced by CheckboxField from design-system
 */
export const DSMCheckbox: React.FC<MUICheckboxProps> = ({ ...props }) => <MUICheckbox {...props} />;
