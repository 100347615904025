import React, { ReactElement } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { find, get } from 'lodash';
import { css, cx } from 'emotion';
import { Body, Secondary, Warn } from '../../typography/typography.component';
import { Group } from '../group/group.component';
import {
  activeOption,
  optionsContainer,
  optionStyle,
  placeholderStyle,
  selectInput,
  displayStyle,
  selectedText,
  addOptionStyle,
} from './select.styles';
import {
  activeOptionJub,
  optionsContainerJub,
  optionStyleJub,
  placeholderStyleJub,
  selectInputJub,
  selectedTextJub,
  addOptionStyleJub,
} from './select.styles.jubjub';
import { Option } from './option.component';
import { Svg } from '../../../svgs';
import { Label } from '../label/label.component';
export { Option };

type Props = React.SelectHTMLAttributes<HTMLSelectElement> & {
  children: React.ReactNode;
  secondary?: string;
  placeholder: string;
  error?: string;
  displayOptionCount?: number;
  onChange?: (val: any, idx?: string) => void;
  addOptionText: string;
  onAddOptionClick: (e?) => void;
};

interface State {
  showOptions: boolean;
}

/**
 * @deprecated
 */
class SelectCore extends React.Component<Props, State> {
  optionAnchorRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
    };
    this.optionAnchorRef = React.createRef();
  }

  componentDidUpdate() {
    const optionAnchor = this.optionAnchorRef;
    if (optionAnchor && optionAnchor.current) {
      optionAnchor.current.scrollIntoView(false);
    }
  }

  public toggleOptions = () => {
    this.setState({ showOptions: !this.state.showOptions });
  };

  public handleClickOutside = () => {
    this.setState({ showOptions: false });
  };

  public handleChange = (child, idx) => {
    this.toggleOptions();
    if (this.props.onChange) {
      this.props.onChange(child.props.value, idx);
    }
  };

  public renderInput = () => {
    const children = Array.isArray(this.props.children)
      ? this.props.children
      : [this.props.children];
    const activeChild = find(children, (child) => {
      const castChild = child as ReactElement;
      return castChild && castChild.props && castChild.props.value === this.props.value;
    });
    const displayValue = get(activeChild, 'props.children', this.props.placeholder);
    const isPlaceholderDefault = this.props.placeholder === displayValue;
    const placeholderStyling = isPlaceholderDefault ? placeholderStyle : '';
    const styles = cx(
      selectInput,
      selectInputJub,
      !!this.props.error ? 'error-style' : '',
      this.state.showOptions && 'show-'
    );
    return (
      <div className={styles} onClick={this.toggleOptions}>
        <Body
          className={cx(
            displayStyle,
            placeholderStyleJub,
            placeholderStyling,
            selectedText,
            selectedTextJub
          )}
        >
          {displayValue}
        </Body>
        {this.state.showOptions ? (
          <Svg icon="CarrotUp" className="sm gray icon-wv" />
        ) : (
          <Svg icon="CarrotDown" className="icon-wv gray sm" />
        )}
      </div>
    );
  };

  addOptionClick = () => {
    this.setState({ showOptions: false });

    if (this.props.onAddOptionClick) {
      this.props.onAddOptionClick();
    }
  };

  public renderOptions = () => {
    const displayOptionCount = this.props.displayOptionCount
      ? this.props.displayOptionCount
      : 3;
    const styles = cx(
      optionsContainer(displayOptionCount),
      optionsContainerJub(displayOptionCount),
      this.state.showOptions && 'show-options'
    );
    const { addOptionText = 'Add Item...' } = this.props;
    return (
      <div className={styles}>
        {this.props.onAddOptionClick && (
          <Option
            className={cx(optionStyle, optionStyleJub, addOptionStyle, addOptionStyleJub)}
            onClick={this.addOptionClick}
            value=""
            title={addOptionText}
          >
            {addOptionText}
          </Option>
        )}
        {React.Children.map(this.props.children, (child, idx) => {
          const childProps = get(child, 'props', {});
          const val = childProps.value;
          const title = childProps.children || val;

          return (
            <div ref={val === this.props.value ? this.optionAnchorRef : null}>
              <Option
                className={cx(
                  optionStyle,
                  optionStyleJub,
                  val === this.props.value ? cx(activeOption, activeOptionJub) : ''
                )}
                onClick={() => this.handleChange(child, idx)}
                value={val}
                title={title}
              >
                {childProps.children}
              </Option>
            </div>
          );
        })}
      </div>
    );
  };

  public render() {
    return (
      <Group className={cx('select-group', this.props.className || '')}>
        {this.renderInput()}
        {this.renderOptions()}
        {this.props.error && <Warn>{this.props.error}</Warn>}
        {!this.props.error && this.props.secondary && (
          <Secondary>{this.props.secondary}</Secondary>
        )}
        <select
          value={this.props.value}
          className={css`
            display: none;
          `}
          name={this.props.name}
          id={this.props.id || ''}
        >
          {this.props.children}
        </select>
        {this.props.value && (
          <Label htmlFor={this.props.name}>{this.props.placeholder}</Label>
        )}
      </Group>
    );
  }
}

/**
 * @deprecated Use DropdownField with useForm, useFormField from design-system
 */
const Select = enhanceWithClickOutside(SelectCore);
export { Select };
