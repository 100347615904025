import React from 'react';
import { cx } from 'emotion';
import { toggleWrapper } from './toggle.styles';
import { toggleWrapperJub } from './toggle.styles.jubjub';
import { Subtitle } from '../../foundation/typography/typography.component';

interface State {
  activeIdx: number;
}

interface Props {
  className?: string;
  children: React.ReactNode;
  size?: 'md' | '';
  activeIdx?: number;
  initialIdx?: number;
  onClick?: (activeIdx: number) => void;
}

type ToggleOptionProps = React.HTMLAttributes<HTMLDivElement> & {
  color?: 'light' | '';
};

/**
 * @deprecated Replaced by SwitchField in design-system
 */
export class Toggle extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeIdx: props.initialIdx || props.activeIdx || 0,
    };
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.activeIdx !== this.props.activeIdx) {
      this.setState({ activeIdx: this.props.activeIdx || 0 });
    }
  }

  public onClick = (activeIdx: number, toggleOptionClick?) => {
    this.setState({ activeIdx: this.props.activeIdx || activeIdx }, () => {
      if (this.props.onClick) {
        this.props.onClick(activeIdx);
      }
      if (toggleOptionClick) {
        toggleOptionClick();
      }
    });
  };

  public render() {
    const count = (React.Children.toArray(this.props.children) || []).length;
    const width = `${100 / count}%`;
    const left = `${(this.state.activeIdx / count) * 100}%`;
    const activeState = 'active-toggle';

    return (
      <div className={cx(toggleWrapper, toggleWrapperJub, this.props.className || '', this.props.size || '')}>
        <div className={activeState} style={{ width, left }} />
        {React.Children.map(
          this.props.children,
          (toggleOption: React.ReactElement<ToggleOptionProps>, index: number) => {
            const className = `toggle-option ${index === this.state.activeIdx ? 'active' : ''}`;

            const { onClick: toggleOptionClick, ...toggleOptionProps } = toggleOption.props;
            const newToggleOption = React.createElement(toggleOption.type, toggleOptionProps);

            return (
              <div
                key={index}
                onClick={() => {
                  this.onClick(index, toggleOptionClick);
                }}
                className={className}
              >
                {newToggleOption}
              </div>
            );
          }
        )}
      </div>
    );
  }
}

export const ToggleOption = (props: ToggleOptionProps) => {
  return <Subtitle {...props} />;
};
