/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const PhoneIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M11.5886214,12.9090069 C10.3758048,11.894614 9.59365255,10.8236891 9.21299627,9.68706657 C10.3205138,8.71919188 10.644681,7.11686858 9.97998162,5.78746985 L8.33324093,2.49398847 C7.77751512,1.38253685 6.42600078,0.932032067 5.31454916,1.48775788 C5.09824561,1.59590965 4.90079246,1.73822583 4.72978949,1.9092288 L2.7876855,3.85134745 C1.76406227,4.87497067 1.20853076,6.27583893 1.25241538,7.72280017 C1.36048907,11.2861998 2.95330809,14.6341051 5.97282842,17.7348668 C9.01959744,20.8636104 12.5395901,22.6310718 16.4933477,22.9983986 C18.0483778,23.1428782 19.587012,22.5875402 20.6913265,21.4832257 L22.4774025,19.6971497 C23.3560822,18.81847 23.3560822,17.3938488 22.4774025,16.5151692 C22.2961319,16.3338985 22.0852162,16.1849392 21.853763,16.0747234 L18.2285419,14.3484271 C16.965614,13.7470327 15.4694106,14.0238755 14.5052726,15.012756 C13.7226781,14.5772311 12.7439118,13.8752853 11.5886214,12.9090069 Z M6.2522925,3.92226155 L7.74391364,6.90550384 C7.90371782,7.22511221 7.8158451,7.61307875 7.53392185,7.83263779 L6.43210266,8.69072367 L6.57177575,9.43878033 C6.94744175,11.4507603 8.10209168,13.2520683 9.98469874,14.8266702 C11.754464,16.3068917 13.2067273,17.2691286 14.3911961,17.7186638 L15.2209598,18.0335797 L16.2569992,16.8003053 C16.4766934,16.5387871 16.8453346,16.4587339 17.1537077,16.6055783 L20.4593325,18.1796858 L18.9235595,19.7154588 C18.3451096,20.2939086 17.5391613,20.5847989 16.7246227,20.5091192 C13.3811181,20.1984884 10.4073667,18.7053061 7.76390314,15.9907191 C5.15730502,13.3139894 3.83910744,10.5433038 3.75126637,7.64701298 C3.72827923,6.88908226 4.01927141,6.15529544 4.55545579,5.61911106 L6.2522925,3.92226155 Z" />
  </SvgIcon>
  );