/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const MessageUnreadIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M12,2.75 C17.6019328,2.75 22.25,6.36516337 22.25,11 C22.25,11.8361098 22.0961245,12.6562924 21.7977793,13.438788 C21.7123459,13.6628615 21.5694329,13.8481397 21.3922446,13.9842286 C21.7805221,14.7375478 22,15.593143 22,16.5 C22,19.5375661 19.5375661,22 16.5,22 C14.4630197,22 12.6846759,20.8926453 11.7341159,19.247083 C11.5056492,19.2404692 11.2817745,19.2258349 11.0244261,19.2004874 L10.7449574,19.1703888 L9.32480012,18.9876265 C9.0345488,18.9535579 8.82994856,18.9367523 8.63149172,18.9308102 L6.13305499,19.9746172 C5.84112745,20.0965593 5.52676514,20.1555662 5.21048831,20.1477861 C3.96822342,20.1172279 2.98594106,19.0854009 3.01649927,17.843136 L3.01649927,17.843136 L3.085,15.05 L2.96958608,14.8467343 C2.12612557,13.3359155 1.75,12.2910883 1.75,11 C1.75,6.36516337 6.39806719,2.75 12,2.75 Z M12,5.25 C7.66080731,5.25 4.25,7.90285013 4.25,11 C4.25,11.7917227 4.53228502,12.53843 5.24625703,13.7945025 C5.46993814,14.028108 5.60330077,14.345189 5.59477579,14.6917499 L5.59477579,14.6917499 L5.52522457,17.5191752 L7.82478173,16.5586191 C7.91354606,16.5215411 8.00415686,16.4954681 8.09509594,16.4797768 C8.20851629,16.4462922 8.32834425,16.4282227 8.45239258,16.4282227 C8.8042081,16.4282227 9.09260444,16.4451786 9.51003952,16.4921652 L9.7304013,16.5182216 L10.590467,16.6316724 C10.7433013,16.6518222 10.8796022,16.6689807 11.0037525,16.6834538 L11,16.5 C11,13.4624339 13.4624339,11 16.5,11 C17.6650286,11 18.7454534,11.3622315 19.6347932,11.9802132 C19.7117348,11.6592744 19.75,11.3316463 19.75,11 C19.75,7.90285013 16.3391927,5.25 12,5.25 Z" />
  </SvgIcon>
  );