import React, { useEffect, HTMLAttributes } from 'react';
import { Transforms, Range } from 'slate';
import {
  Editable,
  useFocused,
  useSelected,
  ReactEditor,
  RenderElementProps,
  useSlate,
} from 'slate-react';
import { WeaveTheme, Text } from '../..';
import { css } from '@emotion/core';
import { MessageTemplateTag } from '../tags/message-template-tag';
import { TagType, TAG_ELEMENT_TYPE, getTag } from '../message-template.models';
import { useTemplateTags } from '../message-template';
import { genUID } from '../../../helpers';
import { getFontColorStyle } from 'helpers/font-styles';

const TagElement = ({ attributes, children, element }: RenderElementProps) => {
  const focused = useFocused();
  const selected = useSelected();
  const editor = useSlate();

  const tag = element.tag as TagType;

  return (
    <span {...attributes}>
      <MessageTemplateTag
        css={{ margin: '4px 1px' }}
        contentEditable={false} //needed for slate
        tag={tag}
        placed
        error={tag?.invalid}
        selected={selected}
        focused={focused}
        onRemove={() => {
          const path = ReactEditor.findPath(editor, element);
          Transforms.removeNodes(editor, { at: path, voids: true });
        }}
      />
      {children}
    </span>
  );
};

const insertTag = (editor: ReactEditor, tag: TagType, range?: Range) => {
  const options = {
    type: TAG_ELEMENT_TYPE,
    tag,
    children: [{ text: '' }],
  };

  if (range) {
    Transforms.select(editor, range);
  }
  Transforms.insertNodes(editor, options);
  // move cursor to end of inserted tag
  Transforms.move(editor, { distance: 1, unit: 'character' });
  ReactEditor.focus(editor);
};

const DefaultElement = (props) => {
  return (
    <Text as="p" size="large" color="dark" css={{ margin: 0 }} {...props.attributes}>
      {props.children}
    </Text>
  );
};

const renderElement = (props: RenderElementProps) => {
  switch (props.element.type) {
    case TAG_ELEMENT_TYPE:
      return <TagElement {...props} />;
    default:
      return <DefaultElement {...props} />;
  }
};

type TemplateEditorProps = HTMLAttributes<HTMLDivElement> & {
  placeholder?: string;
};

export const TemplateEditor = ({ placeholder, ...props }: TemplateEditorProps) => {
  const editor = useSlate();
  const { tags, handleAddTagToTemplate } = useTemplateTags();
  const editorId = 'template-editor-' + genUID();

  useEffect(() => {
    handleAddTagToTemplate((e, tag) => {
      insertTag(editor, tag);
    });
  }, []);

  return (
    <Editable
      id={editorId}
      css={(theme: WeaveTheme) =>
        css`
          border: 1px solid ${theme.colors.gray[300]};
          border-radius: ${theme.borderRadius.small};
          padding: ${theme.spacing(2)};
          font-size: ${theme.fontSize(16)};
          transition: border-color ease-out 250ms;
          p[data-slate-node='element']
            span[data-slate-leaf]
            span[contenteditable='false'] {
            /*Style the placeholder text*/
            ${getFontColorStyle('light', theme)}
            opacity: 1 !important; /*override slate*/
          }
          span[data-slate-void='true'] span[data-slate-spacer] {
            /* Needed to fix the cursor after a tag, so it doesn't look like a superscript */
            position: relative !important;
          }
          :focus {
            border-color: ${theme.colors.weaveBlue};
          }
        `
      }
      renderElement={renderElement}
      onDragEnter={(e) => {
        ReactEditor.focus(editor);
      }}
      onDragLeave={(e) => {
        const editorElement = document.getElementById(editorId);

        // onDragLeave fires when dragging over the children in the editor nodes,
        // so we need to make sure the mouse actually left the editor box
        const isInBox = (x: number, y: number, boundingRect: DOMRect) => {
          const offset = 1;
          return (
            x > boundingRect.left - offset &&
            x < boundingRect.right - offset &&
            y > boundingRect.top - offset &&
            y < boundingRect.bottom - offset
          );
        };

        if (
          editorElement &&
          !isInBox(e.clientX, e.clientY, editorElement.getBoundingClientRect())
        ) {
          ReactEditor.blur(editor);
        }
      }}
      onDrop={(e) => {
        e.preventDefault();
        const data = e.dataTransfer.getData('text/plain');
        const range = ReactEditor.findEventRange(editor, e);

        const tag = getTag(tags, data);

        if (tag) {
          insertTag(editor, tag, range);
        }
      }}
      placeholder={placeholder ?? 'Type your message'}
      {...props}
    />
  );
};
