import React, { Children } from 'react';
import { tabBarStyle } from './styles';

export type TabBarProps = React.HTMLAttributes<HTMLDivElement> & {
  activeTab: string;
  children: Array<JSX.Element | boolean | null>;
};

/**
 * @deprecated import from design-system
 */
export const TabBar = ({ activeTab, children, ...rest }: TabBarProps) => {
  const childArr = Children.toArray(children).filter(Boolean) as JSX.Element[];
  const activeIndex = childArr.findIndex((child) => child.props.id === activeTab);
  const tabWidth = 100 / childArr.length;
  return (
    <div css={(theme) => tabBarStyle(theme, { activeIndex, tabWidth })} {...rest}>
      {children}
    </div>
  );
};
