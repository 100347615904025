import { css } from '@emotion/core';
import { WeaveTheme } from '../theme/weaveTheme';
import { ButtonSize } from './button-type';

export const heights = {
  large: 48,
  small: 40,
  tiny: 24,
};

const fonts = {
  large: 16,
  small: 14,
  tiny: 14,
};

export const radius = {
  large: 24,
  small: 20,
  tiny: 20,
};

const padding = {
  large: '8px 16px',
  small: '8px 16px',
  tiny: '2px 12px',
};

const lineHeights = {
  large: 1.375,
  small: 1.375,
  tiny: 1,
};

export const baseButtonStyle = (theme: WeaveTheme, size: ButtonSize) => css`
  width: ${size === 'tiny' ? 'auto' : '100%'};
  height: ${heights[size]}px;
  border-radius: ${radius[size]}px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fontFamily};
  font-size: ${theme.fontSize(fonts[size])};
  line-height: ${lineHeights[size]};
  padding: ${padding[size]};
  text-transform: capitalize;

  :focus {
    outline: none;
  }

  :disabled {
    cursor: default;
  }
`;
