import { css } from 'emotion';

import { weaveBlue, gray3 } from '../../';

const thumbSize = '14px';

export const audioContainer = css`
  align-items: center;
  display: inline-flex;

  label: audioContainer;
`;

export const icon = css`
  cursor: default;
  display: inline-block;
  margin-right: 8px;

  &.clickable {
    cursor: pointer;
  }

  label: icon;
`;

export const containRangeSlider = (width: number) => {
  return css`
    align-items: center;
    display: inline-flex;
    height: 24px;
    width: ${width}px;

    label: containRangeSlider;
  `;
};

export const rangeSlider = (currentPosition: number = 0, duration: number = 0) => {
  let percent = 0;

  if (duration > 0) {
    percent = (currentPosition / duration) * 100;
  }

  return css`
    -webkit-appearance: none;
    appearance: none;
    background: ${duration > 0 && currentPosition > 0
      ? `linear-gradient(to right, ${weaveBlue} 0%, ${weaveBlue} ${percent}%, ${gray3} ${percent}%, ${gray3} 100%)`
      : gray3};
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 5px;
    margin: 0;
    outline: none;
    padding: 0;
    width: 100%;

    &.error {
      cursor: default;

      &::-webkit-slider-thumb {
        display: none;
      }

      &::-moz-range-thumb {
        display: none;
      }
    }

    &:hover {
      &::-webkit-slider-thumb {
        opacity: 1;
      }
    }

    &::-webkit-slider-thumb {
      opacity: 0;
      appearance: none;
      background: ${weaveBlue};
      border-radius: 50%;
      cursor: pointer;
      height: ${thumbSize};
      transition: opacity 200ms;
      width: ${thumbSize};
    }

    &::-moz-range-thumb {
      width: ${thumbSize};
      height: ${thumbSize};
      background: ${weaveBlue};
      border-radius: 50%;
      cursor: pointer;
    }

    label: rangeSlider;
  `;
};

export const time = css`
  margin-left: 8px;

  label: time;
`;
