import { css } from 'emotion';
import { gray1, gray3, gray7, weaveBlue, weaveRed } from '../../colors/colors';

export const textareaStyle = css`
  color: ${gray7};
  min-height: 80px;
  width: 100%;
  font-size: 13px;
  border: 1px solid ${gray3};
  padding: 10px 10px 12px;
  border-radius: 10px;
  display: block;
  outline: none;
  margin: 10px 0 0 0;
  transition: border-color 0.2s ease-in;
  background-color: white;

  &:disabled,
  &.disabled {
    background-color: ${gray1};
  }

  &::placeholder {
    color: ${gray3};
  }

  &.error-style {
    border-color: ${weaveRed};
  }

  &.active,
  &:active,
  &.focus,
  &:focus {
    border-color: ${weaveBlue};
  }

  label: textareaStyle;
`;
