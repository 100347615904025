import React from 'react';
import { NakedButton } from 'naked/naked-button.component';
import { tabStyle } from './styles';

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  disabled?: boolean;
  id: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

/**
 * @deprecated import from design-system
 */
export const Tab = ({ children, ...rest }: Props) => (
  <NakedButton css={tabStyle} {...rest}>
    {children}
  </NakedButton>
);
