import { jubcss } from '../../../helpers/jub-css';
import { gray7, gray3, weaveRed, weaveBlue } from '../../colors/colors';

export const dateTimeInputJub = jubcss(`
  font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
  color: ${gray7} !important;
  height: 40px !important;
  width: 100% !important;
  background-color: white !important;
  font-size: 13px !important;
  display: block !important;
  margin: 15px 0 !important;
  outline: none !important;

  ::placeholder {
    color: ${gray3} !important;
  }

  &.error-style {
    border-color: ${weaveRed} !important;
  }

  &.active,
  :active,
  &.focus,
  :focus {
    border-color: ${weaveBlue} !important;
  }

  input {
    margin: 0 !important;
  }

  label: date-input;
`);
