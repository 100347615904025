import React from 'react';
import { BoxProps } from '@material-ui/core/Box';
import { Box } from '../box';

/**
 * @deprecated import from design-system
 */
export const ButtonGroup = React.memo(({ children, ...props }: BoxProps) => {
  const itemCount = React.Children.count(children);
  return (
    <Box display="flex" flexDirection="row" {...props}>
      {React.Children.map(children, (c, idx) => (
        <Box
          width={`${100 / itemCount}%`}
          mr={itemCount > 1 && idx < itemCount - 1 ? 1 : 0}
          ml={itemCount > 1 && idx > 0 ? 1 : 0}
        >
          {c}
        </Box>
      ))}
    </Box>
  );
});
