import React, { forwardRef } from 'react';
import { StyledIconBtn } from './styled-icon-btn.component';
import { BtnWithHoverLabel } from './btn-with-hover-label.component';
import { Placement } from '../../hooks/use-hint/get-position';

export type IconButtonSize = 'normal' | 'small';

export type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  hoverLabelPlacement?: Placement;
  label: string;
  showLabelOnHover?: boolean;
  // matches the icon sizes
  size?: IconButtonSize;
};

/**
 * @deprecated import from design-system
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ showLabelOnHover, size = 'normal', ...props }, ref) =>
    showLabelOnHover ? (
      <BtnWithHoverLabel size={size} {...props} ref={ref} />
    ) : (
      <StyledIconBtn size={size} {...props} aria-label={props.label} ref={ref} />
    )
);
