import React from 'react';
import MUISwitch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

interface SwitchProps {
  checked?: boolean;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  id?: string;
  required?: boolean;
  value?: any;
}

const useStyles = makeStyles({
  checked: {
    transform: 'translateX(60%) !important',
  },
});

/**
 * @deprecated replaced by SwitchField from design-system
 */
export const Switch: React.FC<SwitchProps> = (props) => {
  const classes = useStyles();
  return <MUISwitch {...props} classes={{ checked: classes.checked }} color="primary" size="medium" />;
};
