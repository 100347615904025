import { css } from 'emotion';

export const browserStyle = css`
  text-align: center;
  height: 100%;
  background: white;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;

  label: browserStyle;
`;

export const logoXL = css`
  text-align: center;
  width: 100px;
  height: 100px;

  label: logoXL;
`;
