import { cx } from 'emotion';
import React from 'react';

import '../fonts';
import { title, headline, subtitle, body, strong, anchor, secondary, warn, money } from './typography.styles';
import {
  titleJub,
  headlineJub,
  subtitleJub,
  bodyJub,
  strongJub,
  anchorJub,
  secondaryJub,
  warnJub,
  moneyJub,
} from './typography.styles.jubjub';

// type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
//   color?: 'light' | '';
// };

// type AnchorProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
//   color?: 'light' | '';
// };

// interface TitleProps {
//   size?: '' | 'lg';
// }

// interface MoneyProps {
//   color?: 'light' | 'black' | 'gray' | 'red' | '';
//   size?: 'md' | 'lg' | 'xl' | '';
//   weight?: 'strong' | '';
// }

/**
 * @deprecated Use Heading from design-system
 */
export const Title = ({ className = '', color = '', size = '', ...props }: any) => {
  return (
    <div className={cx('title-wv', color, size, title, titleJub, className)} {...props}>
      {props.children}
    </div>
  );
};

/**
 * @deprecated Use Heading from design-system
 */
export const Headline = ({ className = '', color = '', ...props }: any) => {
  return (
    <div className={cx('headline-wv', color, headline, headlineJub, className)} {...props}>
      {props.children}
    </div>
  );
};

/**
 * @deprecated Use Text from design-system
 */
export const Subtitle = ({ className = '', color = '', ...props }: any) => {
  return (
    <div className={cx('subtitle-wv', color, subtitle, subtitleJub, className)} {...props}>
      {props.children}
    </div>
  );
};

/**
 * @deprecated Use Text from design-system
 */
export const Body = ({ className = '', color = '', ...props }: any) => {
  return (
    <span className={cx('body-wv', color, body, bodyJub, className)} {...props}>
      {props.children}
    </span>
  );
};

/**
 * @deprecated Use Text from design-system
 */
export const Strong = ({ className = '', color = '', ...props }: any) => {
  return (
    <span className={cx('strong-wv', color, strong, strongJub, className)} {...props}>
      {props.children}
    </span>
  );
};

/**
 * @deprecated Use Text from design-system
 */
export const Secondary = ({ className = '', color = '', ...props }: any) => {
  return (
    <span className={cx('secondary-wv', color, secondary, secondaryJub, className)} {...props}>
      {props.children}
    </span>
  );
};

/**
 * @deprecated Use TextLink from design-system
 */
export const A = ({ className = '', color = '', ...props }: any) => {
  return (
    <a className={cx('a-wv', color, anchor, anchorJub, className)} {...props}>
      {props.children}
    </a>
  );
};

/**
 * @deprecated Use Text from design-system
 */
export const Warn = ({ className = '', color = '', ...props }: any) => {
  return (
    <span className={cx('warn-wv', color, warn, warnJub, className)} {...props}>
      {props.children}
    </span>
  );
};

/**
 * @deprecated Use Text from design-system
 */
export const Money = ({ className = '', color = '', size = '', weight = '', ...props }: any) => {
  return (
    <span className={cx('money-wv', money, color, size, weight, moneyJub, className)} {...props}>
      {props.children}
    </span>
  );
};

/**
 * @deprecated Use Text or Heading from design-system
 */
export const Typography = ({ variant, ...props }: any) => {
  switch (variant) {
    case 'Title':
      return <Title {...props} />;
    case 'Headline':
      return <Headline {...props} />;
    case 'Subtitle':
      return <Subtitle {...props} />;
    case 'Body':
      return <Body {...props} />;
    case 'Strong':
      return <Strong {...props} />;
    case 'Secondary':
      return <Secondary {...props} />;
    case 'Warn':
      return <Warn {...props} />;
    case 'Money':
      return <Money {...props} />;
    default:
      return <Body {...props} />;
  }
};
