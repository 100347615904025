import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SearchIconSmall } from '../icon';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput, { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { MuiTheme } from '../theme';

export interface SearchFieldProps extends OutlinedInputProps {
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  variant?: 'search 1' | 'search 2';
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    outlinedInputRootVariant1: {
      paddingLeft: '40px',
      borderRadius: '50px',
    },
    outlinedInputRootVariant2: {
      paddingLeft: '40px',
    },
    inputLabelRoot: {
      transform: 'translate(40px, 12px) scale(1) !important',
    },
    inputLabelShrink: {
      transform: 'translate(14px, -6px) scale(0.75) !important',
    },
    searchIconAdornmentVariant1: {
      position: 'absolute',
      left: 'calc(50% - 8px)',
      top: '11px',
      color: theme.palette.grey[500],
      transition: 'all 0.1s ease-out',
      pointerEvents: 'none',
    },
    searchIconAdornmentVariant2: {
      position: 'absolute',
      left: '16px',
      top: '11px',
      color: theme.palette.grey[500],
      transition: 'color 0.2s ease-out',
    },
    focused: {
      '& + svg': {
        color: theme.palette.primary.main,
        left: '16px',
      },
    },
    input: {
      paddingLeft: 0,
    },
    notchedOutline: {
      borderRadius: 20,
    },
  })
);

/**
 * @deprecated replaced by SearchField from design-system
 */
export const SearchField: React.FC<SearchFieldProps> = ({
  children,
  fullWidth,
  disabled,
  variant = 'search 1',
  className,
  style,
  ...props
}) => {
  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef<HTMLLabelElement>(null);
  const classes = useStyles();

  React.useEffect(() => {
    setLabelWidth(labelRef.current!.offsetWidth);
  }, []);

  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      className={className}
      style={style}
    >
      <InputLabel
        ref={labelRef}
        margin="dense"
        htmlFor="search-text-field"
        classes={{ root: classes.inputLabelRoot, shrink: classes.inputLabelShrink }}
      >
        {variant === 'search 2' ? 'Search' : ''}
      </InputLabel>
      <OutlinedInput
        disabled={disabled}
        id="search-text-field"
        margin="dense"
        autoComplete="off"
        labelWidth={labelWidth}
        className={
          variant === 'search 1'
            ? classes.outlinedInputRootVariant1
            : classes.outlinedInputRootVariant2
        }
        classes={{ focused: classes.focused, input: classes.input }}
        {...props}
      />
      <SearchIconSmall
        style={props.value ? { left: '16px' } : undefined}
        className={
          variant === 'search 1'
            ? classes.searchIconAdornmentVariant1
            : classes.searchIconAdornmentVariant2
        }
      />
    </FormControl>
  );
};
