/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const ComputerIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M21,1.75 C22.2426407,1.75 23.25,2.75735931 23.25,4 L23.25,16 C23.25,17.2426407 22.2426407,18.25 21,18.25 L15,18.25 L15,20 L17,20 C17.5522847,20 18,20.4477153 18,21 L18,21.5 C18,22.0522847 17.5522847,22.5 17,22.5 L7,22.5 C6.44771525,22.5 6,22.0522847 6,21.5 L6,21 C6,20.4477153 6.44771525,20 7,20 L9,20 L9,18.25 L3,18.25 C1.75735931,18.25 0.75,17.2426407 0.75,16 L0.75,4 C0.75,2.75735931 1.75735931,1.75 3,1.75 L21,1.75 Z M12,15.75 C11.4477153,15.75 11,16.1977153 11,16.75 C11,17.3022847 11.4477153,17.75 12,17.75 C12.5522847,17.75 13,17.3022847 13,16.75 C13,16.1977153 12.5522847,15.75 12,15.75 Z M20.75,4.25 L3.25,4.25 L3.25,15.25 L20.75,15.25 L20.75,4.25 Z" />
  </SvgIcon>
  );