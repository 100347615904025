// Current palette
/**
 * @deprecated Use theme.colors.weaveBlue in the design-system
 */
export const weaveBlue = '#28CAF4';
/**
 * @deprecated Not replaced design-system
 */
export const weaveBlueLight = '#4ADAFF';
/**
 * @deprecated Not replaced design-system
 */
export const weaveBlueDark = '#25BBE2';

/**
 * @deprecated Use theme.font.colors.green in the design-system
 */
export const weaveGreen = '#2BD53C';
/**
 * @deprecated Use theme.font.colors.warning in the design-system
 */
export const weaveYellow = '#FFB92E';
/**
 * @deprecated Use theme.font.colors.error in the design-system
 */
export const weaveRed = '#FF6B59';
/**
 * @deprecated Use theme.colors.strawberry in the design-system
 */
export const weavePink = '#FF31A2';
/**
 * @deprecated Use theme.colors.studio in the design-system
 */
export const weavePurple = '#864BA0';

// Grayscale colors
/**
 * @deprecated Use theme.colors.black in the design-system
 */
export const black = '#000000'; // 100% black
/**
 * @deprecated Use theme.colors.gray600 in the design-system
 */
export const gray9 = '#454D52';
/**
 * @deprecated Use theme.colors.gray500 in the design-system
 */
export const gray7 = '#797E82';
/**
 * @deprecated Use theme.colors.gray400 in the design-system
 */
export const gray5 = '#A3A9AC';
/**
 * @deprecated Use theme.colors.gray300 in the design-system
 */
export const gray4 = '#BBBBBB';
/**
 * @deprecated Use theme.colors.gray200 in the design-system
 */
export const gray3 = '#CED4D6';
/**
 * @deprecated Use theme.colors.gray100 in the design-system
 */
export const gray1 = '#E9EDEF';
/**
 * @deprecated Use theme.colors.white in the design-system
 */
export const white = '#FFFFFF'; // 0% black
// Grayscale colors end

export const modalBackground = 'rgba(69, 77, 82, 0.8)';
