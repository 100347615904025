import React from 'react';
import { css } from '@emotion/core';
import { FontColorType, getFontColorStyle } from '../../helpers/font-styles';
import { WeaveTheme } from '..';

export type SvgIconProps = React.SVGProps<SVGSVGElement> & {
  children?: React.ReactNode;
  size?: number;
  color?: FontColorType;
};

export const SvgIcon = ({
  children,
  color,
  focusable,
  viewBox,
  size = 24,
  ...props
}: SvgIconProps) => (
  <svg
    css={(theme: WeaveTheme) => [
      color && getFontColorStyle(color, theme),
      css`
        fill: currentColor;
        transition: fill 200ms ease-out;
        user-select: none;
        line-height: 1;
        font-size: ${theme.fontSize(size)};
      `,
    ]}
    focusable={focusable ?? false}
    width={size}
    height={size}
    aria-hidden={props['aria-hidden'] ?? true}
    {...props}
    viewBox={viewBox}
  >
    {children}
  </svg>
);
