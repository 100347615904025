/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const ChatIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M8,2.25 L16,2.25 C20.0040644,2.25 23.25,5.49593556 23.25,9.50000357 L23.25,11.4138984 C23.25,15.3437647 20.1189906,18.5581399 16.1904809,18.6613903 C15.7237976,18.6736558 15.4155294,18.6983535 15.3714723,18.7098045 C15.1620923,18.7642246 14.9677415,18.9501128 14.6097848,19.474148 L14.3926483,19.7943357 C13.6793403,20.8361923 13.0778156,21.3496094 12.0000004,21.3496094 C10.9107544,21.3496094 10.3213372,20.8233636 9.67648581,19.7860821 C9.6466805,19.7381385 9.49652969,19.4929519 9.45559565,19.4276038 C9.15136591,18.9419237 8.97469828,18.7701637 8.74190402,18.7091154 C8.70287789,18.6988811 8.35996498,18.6737356 7.84254316,18.6624949 C3.90076694,18.5768669 0.75,15.3569118 0.75,11.4142056 L0.75,9.5 C0.75,5.49593556 3.99593556,2.25 8,2.25 Z M12.3298035,18.3820074 L12.5454274,18.0640314 C13.2193499,17.0774344 13.7811676,16.5400795 14.7425902,16.2901955 C15.0366866,16.2137567 15.4621572,16.179669 16.1247978,16.1622533 C18.6986479,16.0946065 20.75,13.9886374 20.75,11.4138949 L20.75,9.5 C20.75,6.87664744 18.6233526,4.75 16,4.75 L8,4.75 C5.37664744,4.75 3.25,6.87664744 3.25,9.5 L3.25,11.4142056 C3.25,13.9973578 5.31429548,16.1069834 7.89683979,16.1630846 C8.62491141,16.1789015 9.07485845,16.2118958 9.37606473,16.2908846 C10.3750363,16.5528565 10.962743,17.1242373 11.5742578,18.100475 C11.6235215,18.1791208 11.7766953,18.4292437 11.7996489,18.4661659 C11.8992788,18.6264265 11.9721262,18.732988 12.027439,18.8019741 C12.0704401,18.7453388 12.1628729,18.6258259 12.3298035,18.3820074 Z" />
  </SvgIcon>
  );