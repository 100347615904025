import { css } from 'emotion';
import { gray3, gray5, gray7, weaveRed, weaveBlue, gray1, weaveBlueLight, weaveGreen } from '../../colors/colors';

export const inputHeight = 40;

export const selectInput = css`
  height: ${inputHeight}px;
  text-align: left;
  width: 100%;
  background-color: white;
  border: 1px solid ${gray3};
  transition: border-color 0.2s ease-in;
  padding-left: 15px;
  border-radius: 5px;
  display: block;
  margin: 15px 0 0;
  outline: none;
  appearance: none;
  position: relative;
  cursor: pointer;

  .icon-wv {
    position: absolute;
    top: 10px;
    right: 15px;
  }

  &.error-style {
    border-color: ${weaveRed};
  }

  &.active,
  &:active,
  &.focus,
  &:focus {
    border-color: ${weaveBlue};
  }
  label: selectInput;
`;

export function optionsContainer(displayLength) {
  return css`
    position: absolute;
    top: ${inputHeight}px;
    left: 0;
    right: 0;
    max-height: ${38 * displayLength + 2}px;
    background: white;
    box-shadow: 0 0 10px ${gray1};
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    border: 1px solid ${gray1};
    cursor: pointer;
    z-index: 100;
    display: none;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.1s ease-in;

    &.show-options {
      display: block;
      visibility: visible;
      transition: opacity 0.1s ease-in, visibility 1s;
      opacity: 1;
    }

    &::-webkit-scrollbar {
      background-color: ${gray1};
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: ${gray3};
    }

    label: optionsContainer;
  `;
}

export const optionStyle = css`
  height: 38px;
  padding-left: 20px;
  padding-top: 9px;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  text-overflow: ellipsis;
  transition: background-color 0.1s ease-in;
  white-space: nowrap;

  &:hover {
    background-color: ${gray1};
  }
  &:active {
    color: ${gray5};
  }
  .selected {
    color: ${weaveBlue};
  }
  label: optionStyle;
`;

export const displayStyle = css`
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${gray7};
  label: placeholderStyle;
`;

export const placeholderStyle = css`
  label: valIsPlaceholderStyle;
  color: ${gray3};
`;

export const selectedText = css`
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const activeOption = css`
  color: ${weaveGreen};
  label: activeOption;
`;

export const addOptionStyle = css`
  color: ${weaveBlue};
  font-size: 15px;
  transition: 300ms;

  :hover {
    color: ${weaveBlueLight};
  }

  label: addOptionStyle;
`;
