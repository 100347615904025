import React from 'react';
import { useModalContext } from './use-modal-context';
import { animated } from 'react-spring';
import { css } from '@emotion/core';

type Props = {
  children: React.ReactNode;
  style: { [key: string]: any };
};

export const FullScreenModalContainer = ({ children, style, ...rest }: Props) => {
  const { containerProps } = useModalContext();
  return (
    <animated.section
      {...containerProps}
      {...rest}
      css={css`
        width: 100vw;
        height: 100vh;
        background: white;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
      `}
      style={style}
    >
      {children}
    </animated.section>
  );
};
