/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const FolderCheckIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M12.6689797,5.75 L20,5.75 C21.2426407,5.75 22.25,6.75735931 22.25,8 L22.25,19 C22.25,20.2426407 21.2426407,21.25 20,21.25 L4,21.25 C2.75735931,21.25 1.75,20.2426407 1.75,19 L1.75,5 C1.75,3.75735931 2.75735931,2.75 4,2.75 L9.46481624,2.75 C10.2171109,2.75 10.9196314,3.12597755 11.3369294,3.75192456 L12.6689797,5.75 Z M4.25,5.25 L4.25,18.75 L19.75,18.75 L19.75,8.25 L12.5351838,8.25 C11.7828891,8.25 11.0803686,7.87402245 10.6630706,7.24807544 L9.3310203,5.25 L4.25,5.25 Z M10.5355339,13.863961 L14.2478445,10.1516504 C14.7359999,9.66349506 15.5274561,9.66349506 16.0156115,10.1516504 C16.5037668,10.6398058 16.5037668,11.431262 16.0156115,11.9194174 L11.4194174,16.5156115 C10.931262,17.0037668 10.1398058,17.0037668 9.65165043,16.5156115 L7.88388348,14.7478445 C7.39572811,14.2596891 7.39572811,13.4682329 7.88388348,12.9800776 C8.37203884,12.4919222 9.16349506,12.4919222 9.65165043,12.9800776 L10.5355339,13.863961 Z" />
  </SvgIcon>
  );