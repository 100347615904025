import { useEffect, useRef, useState, useCallback } from 'react';
import { useOnClickOutside } from 'hooks/use-onclick-outside';
import { genUID } from 'helpers/gen-uid';
import isFunction from 'lodash/isFunction';
import { Flyout } from './flyout.component';
import { useExecutionCounter } from '../';

export type FlyoutProps = {
  'aria-labelledby': string;
  id: string;
  onClose: () => void;
  role: 'menu';
  ref: React.Ref<any>;
};

export type FlyoutTriggerProps = {
  'aria-haspopup': 'true';
  'aria-controls': string;
  'aria-expanded': boolean;
  id: string;
  onClick: () => void;
  ref: React.Ref<any>;
};

export type ToggleCallback = (active: boolean) => void;

type FlyoutComponent<T = any> = React.ForwardRefExoticComponent<FlyoutProps & T>;

export type UseFlyoutResponse<T = any> = {
  active: boolean;
  close: () => void;
  open: () => void;
  Flyout: FlyoutComponent<T>;
  flyoutProps: FlyoutProps;
  triggerProps: FlyoutTriggerProps;
};

/**
 * @deprecated import from design-system
 */
export function useFlyout(onToggle?: ToggleCallback): UseFlyoutResponse {
  const runCount = useExecutionCounter();
  const [active, setActive] = useState(false);
  const idRef = useRef(genUID());
  const controlId = `flyout-trigger-${idRef.current}`;
  const menuId = `flyout-${idRef.current}`;
  const menuRef = useRef<any>(null);
  const triggerRef = useRef<any>(null);

  const openFlyout = useCallback(() => {
    setActive(true);
  }, []);

  const closeFlyout = useCallback(() => {
    setActive(false);
  }, []);

  const handleClick = useCallback(() => {
    setActive((visible) => !visible);
  }, []);

  useOnClickOutside({
    active: active,
    ref: menuRef,
    handler: closeFlyout,
    ignoreRefs: [triggerRef],
  });

  useEffect(() => {
    if (runCount.current > 1 && isFunction(onToggle)) {
      onToggle(active);
    }
  }, [active, onToggle]);

  return {
    active,
    open: openFlyout,
    close: closeFlyout,
    Flyout,
    flyoutProps: {
      'aria-labelledby': controlId,
      id: menuId,
      onClose: closeFlyout,
      role: 'menu',
      ref: menuRef,
    },
    triggerProps: {
      id: controlId,
      'aria-haspopup': 'true',
      'aria-controls': menuId,
      'aria-expanded': active,
      onClick: handleClick,
      ref: triggerRef,
    },
  };
}
