import React from 'react';
import { defaultPosition, loading, positionFullPageClass, ldsRing } from './loader.styles';
import { cx } from 'emotion';

export interface LoadingProps {
  fullPage?: boolean;
  className?: string;
  show?: boolean;
  size?: 'sm' | '';
}

/**
 * @deprecated Replaced by ContentLoader in design-system
 */
export class Loader extends React.Component<LoadingProps, {}> {
  public static defaultProps = {
    className: '',
    show: true,
  };

  public render() {
    let position = defaultPosition;
    let klassName = this.props.className || '';
    if (this.props.fullPage) {
      position = positionFullPageClass;
      klassName += ' full-page ';
    }

    if (!this.props.show) {
      return null;
    }

    return (
      <div
        className={cx('react-loader', loading, position, klassName, ldsRing, this.props.size || '')}
        title="Loading ..."
      >
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
}
