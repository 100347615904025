import React, { HTMLAttributes } from 'react';
import { TagType } from '../message-template.models';
import { MessageTemplateTag } from './message-template-tag';
import { NakedUl } from '../../../naked';
import { css } from '@emotion/core';
import { useTemplateTags } from '../message-template';
import { validateTags } from '../parse-template';

type TagListProps = HTMLAttributes<HTMLUListElement> & {
  onTagSelect?: (event: React.MouseEvent<HTMLElement, MouseEvent>, tag: TagType) => void;
};

export const TagList = ({ onTagSelect, ...props }: TagListProps) => {
  const { addTagToTemplate, tags } = useTemplateTags();

  //@ts-ignore : during build, tsc throws an error not recognizing the process
  if (process.env.NODE_ENV === 'development') {
    validateTags(tags);
  }

  return (
    <>
      {tags?.length > 0 && (
        <NakedUl
          css={css`
            display: flex;
            flex-wrap: wrap;
            margin: 0;
          `}
          {...props}
        >
          {tags.map((tag, idx) => (
            <li key={idx} css={{ margin: 4 }}>
              <MessageTemplateTag
                tabIndex={0}
                tag={tag}
                onSelect={(e) =>
                  typeof addTagToTemplate === 'function' && addTagToTemplate(e, tag)
                }
              />
            </li>
          ))}
        </NakedUl>
      )}
    </>
  );
};
