/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const AlertInvertIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,17.5 C12.6903559,17.5 13.25,16.9403559 13.25,16.25 C13.25,15.5596441 12.6903559,15 12,15 C11.3096441,15 10.75,15.5596441 10.75,16.25 C10.75,16.9403559 11.3096441,17.5 12,17.5 Z M12,6.5 C11.3096441,6.5 10.75,7.05964406 10.75,7.75 L10.75,12.25 C10.75,12.9403559 11.3096441,13.5 12,13.5 C12.6903559,13.5 13.25,12.9403559 13.25,12.25 L13.25,7.75 C13.25,7.05964406 12.6903559,6.5 12,6.5 Z" />
  </SvgIcon>
  );