import React from 'react';
import { useFlyoutMenu, FlyoutMenuAction } from '../../hooks';
import { WeaveTheme, CarrotDownIconSmall } from 'dsm/index';
import { heights, radius } from './common';
import { PrimaryButton } from './primary-button';
import { NakedButton } from 'naked/index';
import { useTransition } from 'react-spring';
import { css } from '@emotion/core';

export type MultiActionButtonProps = React.HTMLAttributes<HTMLDivElement> & {
  actions: FlyoutMenuAction[];
  label: string;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom';
  onClick: (e: React.MouseEvent) => void;
  size?: 'large' | 'small';
  type?: 'submit' | 'reset' | 'button';
};

const secondaryBtnWidths = {
  large: 64,
  small: 56,
};

const placementTransitions = {
  top: {
    from: { opacity: 0, transform: 'translateY(-95%)' },
    enter: { opacity: 1, transform: 'translateY(-100%)' },
    leave: { opacity: 0, transform: 'translateY(-95%)' },
  },
  bottom: {
    from: { opacity: 0, transform: 'translateY(95%)' },
    enter: { opacity: 1, transform: 'translateY(100%)' },
    leave: { opacity: 0, transform: 'translateY(95%)' },
  },
};

/**
 * @deprecated import from design-system
 */
export const MultiActionButton = ({
  actions,
  disabled,
  label,
  menuPlacement = 'top',
  onClick,
  size = 'large',
  type,
  ...rest
}: MultiActionButtonProps) => {
  const { active, FlyoutMenu, flyoutProps, triggerProps } = useFlyoutMenu();
  const menuTransitions = useTransition(
    active,
    null,
    placementTransitions[menuPlacement]
  );

  return (
    <div
      className="multi-action-btn"
      css={[
        css`
          position: relative;
        `,
        !disabled &&
          css`
            :hover:not(:active),
            :focus-within:not(:active) {
              button:first-of-type {
                box-shadow: 0 6px 8px 0 rgba(41, 190, 223, 0.4);
              }
            }
          `,
      ]}
      {...rest}
    >
      <PrimaryButton
        css={css`
          padding-right: ${secondaryBtnWidths[size]}px;
        `}
        onClick={onClick}
        disabled={disabled}
        type={type}
        size={size}
      >
        {label}
      </PrimaryButton>
      <NakedButton
        {...triggerProps}
        type="button"
        css={(theme: WeaveTheme) => [
          css`
            width: ${secondaryBtnWidths[size]}px;
            height: ${heights[size]}px;
            background: ${theme.colors.weaveBlue};
            border-left: 2px solid #20a6c9;
            border-top-right-radius: ${radius[size]}px;
            border-bottom-right-radius: ${radius[size]}px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: ${theme.spacing(size === 'large' ? 2 : 1)};
            position: absolute;
            top: 0;
            right: 0;
            transition: background 250ms ease-out;

            :hover,
            :focus {
              background: ${theme.colors.hoverBlue};
              outline: none;
            }

            :active {
              background: ${theme.colors.pressedBlue};
            }

            :disabled {
              background: ${theme.colors.gray[300]};
              border-color: white;
              cursor: default;
            }
          `,
          active && `background: ${theme.colors.hoverBlue};`,
        ]}
        disabled={disabled}
      >
        <CarrotDownIconSmall
          color="white"
          css={css`
            transition: transform 200ms ease-out;
            transform: rotate(${active ? 180 : 0}deg);
          `}
        />
      </NakedButton>
      {menuTransitions.map(
        ({ item, key, props }) =>
          item && (
            <FlyoutMenu
              key={key}
              {...flyoutProps}
              actions={actions}
              css={(theme: WeaveTheme) => css`
                min-width: 120px;
                border-radius: ${theme.borderRadius.small};
                box-shadow: ${theme.shadows.heavy};
                ${menuPlacement}: ${theme.spacing(-1)};
                right: 0;

                li {
                  padding: ${theme.spacing(1.5, 2)};
                  white-space: nowrap;
                }
              `}
              style={props}
            />
          )
      )}
    </div>
  );
};
