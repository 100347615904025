import { jubcss } from '../../helpers/jub-css';

export const titleJub = jubcss`
  line-height: inherit;
  font-family: 'Proxima Nova', sans-serif;

  label: title;
`;
export const headlineJub = jubcss`
  line-height: inherit;
  font-family: 'Proxima Nova', sans-serif;

  label: headline;
`;
export const subtitleJub = jubcss`
  line-height: inherit;
  font-family: 'Proxima Nova', sans-serif;

  label: subtitle;
`;
export const bodyJub = jubcss`
  line-height: inherit;
  font-family: 'Proxima Nova', sans-serif;

  label: body;
`;
export const strongJub = jubcss`
  line-height: inherit;
  font-family: 'Proxima Nova', sans-serif;

  label: strong;
`;
export const anchorJub = jubcss`
  line-height: inherit;
  font-family: 'Proxima Nova', sans-serif;

  label: anchor;
`;
export const secondaryJub = jubcss`
  line-height: inherit;
  font-family: 'Proxima Nova', sans-serif;

  label: subtitle;
`;
export const warnJub = jubcss`
  line-height: inherit;
  font-family: 'Proxima Nova', sans-serif;

  label: errorText;
`;
export const moneyJub = jubcss`
  line-height: inherit;
  font-family: 'Proxima Nova', sans-serif;

  label: errorText;
`;
