import React, { ChangeEvent, HTMLAttributes, useState } from 'react';
import { CheckIconSmall, WeaveTheme } from '../';
import { css } from '@emotion/core';

type InputProps = Omit<HTMLAttributes<HTMLInputElement>, 'onChange' | 'name' | 'value'>;

export type CheckBoxProps = InputProps & {
  checked: boolean;
  disabled?: boolean;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

const circleStyle = css`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

/**
 * @deprecated Replaced by CheckboxField from design-system
 */
export const CheckboxRound = ({
  className,
  checked,
  disabled,
  onChange,
  ...rest
}: CheckBoxProps) => {
  const [focused, setFocused] = useState(false);
  return (
    <div
      className={className}
      css={(theme: WeaveTheme) => [
        circleStyle,
        css`
          position: relative;

          ::before {
            content: '';
            width: 32px;
            height: 32px;
            display: block;
            background: ${theme.colors.gray[300]};
            border-radius: 50%;
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            margin-top: -6px;
            margin-left: -6px;
            transform: scale(0);
            transform-origin: 50% 50%;
            transition: transform 200ms ease-out;
          }

          :hover:not(:disabled)::before {
            transform: scale(1);
          }
        `,
        !disabled &&
          focused &&
          css`
            ::before {
              transform: scale(1);
            }
          `,
      ]}
    >
      <div
        css={(theme: WeaveTheme) => [
          circleStyle,
          css`
            background: white;
            border: 1px solid ${theme.colors.gray[disabled ? 300 : 400]};
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: background 200ms ease-out, border-color 200ms ease-out;
          `,
          checked &&
            css`
              background: ${theme.colors.weaveBlue};
              border-color: ${theme.colors.weaveBlue};
            `,
        ]}
      >
        <CheckIconSmall
          color="white"
          css={[
            css`
              transform: scale(0);
              transform-origin: 50% 50%;
              transition: transform 200ms ease-out;
            `,
            checked &&
              css`
                transform: scale(1);
              `,
          ]}
        />
      </div>
      <input
        css={[
          circleStyle,
          css`
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
          `,
        ]}
        onBlur={() => {
          setFocused(false);
        }}
        onChange={onChange}
        onFocus={() => {
          setFocused(true);
        }}
        {...rest}
        type="checkbox"
        checked={checked}
        disabled={disabled}
      />
    </div>
  );
};
