import { FlyoutMenu } from './flyout-menu.component';
import { UseFlyoutResponse, ToggleCallback, useFlyout, FlyoutProps } from '../use-flyout';

export type FlyoutMenuProps = Omit<FlyoutProps, 'ref'> &
  React.HTMLAttributes<HTMLUListElement> & {
    actions: FlyoutMenuAction[];
    style?: React.CSSProperties;
  };

export type FlyoutMenuAction = {
  Icon?: React.FC;
  label: string;
  onClick: () => void;
};

type UseFlyoutMenuResponse = Omit<UseFlyoutResponse, 'Flyout'> & {
  FlyoutMenu: typeof FlyoutMenu;
};

/**
 * @deprecated import from design-system
 */
export function useFlyoutMenu(onToggle?: ToggleCallback): UseFlyoutMenuResponse {
  const { Flyout, ...rest } = useFlyout(onToggle);
  return { ...rest, FlyoutMenu };
}
