/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const SearchIconSmall: React.FC<SvgIconProps> = props => (
  <SvgIcon size={16} {...props} viewBox="0 0 16 16">
    <path d="M10.4267503,12.0909638 C9.50549638,12.66704 8.41663168,13 7.25,13 C3.9362915,13 1.25,10.3137085 1.25,7 C1.25,3.6862915 3.9362915,1 7.25,1 C10.5637085,1 13.25,3.6862915 13.25,7 C13.25,8.42398365 12.7539396,9.73210619 11.9252,10.7609865 L14.4497475,13.2855339 C14.8402718,13.6760582 14.8402718,14.3092232 14.4497475,14.6997475 C14.0592232,15.0902718 13.4260582,15.0902718 13.0355339,14.6997475 L10.4267503,12.0909638 Z M7.25,11 C9.459139,11 11.25,9.209139 11.25,7 C11.25,4.790861 9.459139,3 7.25,3 C5.040861,3 3.25,4.790861 3.25,7 C3.25,9.209139 5.040861,11 7.25,11 Z" />
  </SvgIcon>
  );