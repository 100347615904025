import { cx } from 'emotion';
import React from 'react';
import { svgButtonStyle } from './svg-button.styles';
import { SvgList } from './svg-list';
import { Svg } from './svg.component';

export type Icons = keyof typeof SvgList;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  icon: Icons;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: 'white' | 'gray' | 'gray3' | 'gray5' | 'gray9' | 'green' | 'red' | 'blue' | 'yellow' | 'pink' | 'purple';
  svgProps?: React.SVGProps<SVGSVGElement>;
  interactive?: boolean;
}

/**
 * @deprecated Replaced by IconButton in design-system
 */
export const SvgButton = ({ icon, className, size, color, svgProps, interactive, ...props }: Props) => {
  let iconProps, iconColor;

  if (svgProps !== undefined) {
    ({ color: iconColor, ...iconProps } = svgProps);
  }

  if (iconColor === undefined) {
    iconColor = color === 'white' ? 'blue' : 'white';
  }

  return (
    <div className={cx(svgButtonStyle, { interactive: !!interactive }, size, color, className)} {...props}>
      <Svg icon={icon} size={size} color={iconColor} {...iconProps} />
    </div>
  );
};
