import { useEffect, useRef } from 'react';

/**
 * A hook for tracker the number of executions for a hook.
 * The main use case is for preventing some useEffect logic
 * from running on the first execution cycle of a hook.
 * @deprecated import from design-system
 */
export function useExecutionCounter(): React.MutableRefObject<number> {
  const executionRef = useRef(0);
  useEffect(() => {
    executionRef.current += 1;
  });
  return executionRef;
}
