import React, { ElementType } from 'react';
import { WeaveTheme } from '../theme';
import { getFontColorStyle, FontColorType } from 'helpers/font-styles';
import {
  getFontSizeStyle,
  getTextAlignStyle,
  getFontFamilyStyle,
  getFontWeightStyle,
  FontWeight,
  TextAlignment,
  TextSize,
} from './text.style';

export interface TextProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  color?: FontColorType;
  as?: ElementType;
  size?: TextSize;
  textAlign?: TextAlignment;
  weight?: FontWeight;
}

/**
 * @deprecated import from design-system
 */
export const Text = React.forwardRef<HTMLElement, TextProps>(
  (
    {
      size = 'large',
      weight = 'inherit',
      color = 'default',
      textAlign,
      as = 'p',
      className,
      ...props
    },
    ref
  ) => {
    const Component = as;
    return (
      <Component
        ref={ref}
        className={className}
        css={(theme: WeaveTheme) => [
          getFontFamilyStyle(theme),
          getFontSizeStyle(size, theme),
          getFontColorStyle(color, theme),
          textAlign && getTextAlignStyle(textAlign),
          getFontWeightStyle(weight, theme),
        ]}
        {...props}
      />
    );
  }
);
