import React, { forwardRef } from 'react';
import { Hint, HintUIProps } from '../use-hint';
import { css } from '@emotion/core';
import { WeaveTheme } from '../../dsm/theme';

type Props = HintUIProps & {
  theme?: 'dark' | 'light';
};

/**
 * @deprecated import from design-system
 */
export const HoverLabel = forwardRef<HTMLParagraphElement, Props>(
  ({ theme = 'dark', ...rest }, ref) => (
    <Hint
      css={(weaveTheme: WeaveTheme) => {
        const dark = weaveTheme.colors.gray[600];
        return css`
          max-width: calc(100vw - ${rest.buffer * 2}px);
          background: ${theme === 'dark' ? dark : 'white'};
          color: ${theme === 'dark' ? 'white' : dark};
          padding: ${weaveTheme.spacing(1)};
        `;
      }}
      {...rest}
      ref={ref}
    />
  )
);
