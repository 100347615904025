import React, { forwardRef } from 'react';
import { Hint, HintUIProps } from '../use-hint';
import { css } from '@emotion/core';
import { WeaveTheme } from '../../dsm/theme';

/**
 * @deprecated import from design-system
 */
export const Tooltip = forwardRef<HTMLParagraphElement, HintUIProps>((props, ref) => (
  <Hint
    css={(theme: WeaveTheme) => css`
      max-width: 270px;
      border-radius: ${theme.borderRadius.medium};
      box-shadow: ${theme.shadows.light};
      color: ${theme.colors.gray[600]};
      padding: ${theme.spacing(2)};
    `}
    {...props}
    ref={ref}
  />
));
