import React from 'react';
import { useActiveTab, useTabSetter } from './tabs-provider.component';
import { Tab } from '../common';

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  controls: string;
  disabled?: boolean;
  id: string;
};

/**
 * @deprecated import from design-system
 */
export const TabListItem = ({ children, controls, id, ...rest }: Props) => {
  const setTab = useTabSetter();
  const isActive = useActiveTab() === id;
  const handleClick = () => {
    setTab(id);
  };
  return (
    <Tab
      {...rest}
      className={isActive ? 'active' : undefined}
      id={id}
      aria-controls={controls}
      aria-selected={isActive}
      role="tab"
      tabIndex={isActive ? 0 : -1}
      onClick={handleClick}
    >
      {children}
    </Tab>
  );
};
