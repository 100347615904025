import React, { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { usePortal } from '..';
import { HintProps } from './use-hint';
import { WeaveTheme } from '../../dsm/theme';
import { css } from '@emotion/core';
import { getFontSizeStyle } from '../../dsm/text/text.style';

export interface HintUIProps extends HintProps {
  children: React.ReactNode;
  hasInteractiveContent?: boolean;
}
/**
 * @deprecated Use Hint from design-system
 */
export const Hint = forwardRef<HTMLParagraphElement, HintUIProps>(
  ({ buffer, children, hasInteractiveContent, id, placement, ...rest }, ref) => {
    const portalElRef = usePortal('body', { id });
    const visible = !rest['aria-hidden'];
    return createPortal(
      //@ts-ignore
      <p
        css={(theme: WeaveTheme) => [
          getFontSizeStyle('large', theme),
          css`
            background: white;
            border-radius: ${theme.borderRadius.small};
            font-family: ${theme.fontFamily};
            opacity: ${visible ? 1 : 0};
            margin: 0;
            padding: ${theme.spacing(1)};
            pointer-events: none;
            position: absolute;
            transition: opacity 250ms ease-in-out;

            :focus {
              outline: none;
            }
          `,
          hasInteractiveContent &&
            visible &&
            css`
              pointer-events: all;
            `,
        ]}
        {...rest}
        ref={ref}
      >
        {children}
      </p>,
      portalElRef
    );
  }
);
