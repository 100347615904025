import React, { forwardRef, useEffect } from 'react';
import { useHoverLabel } from '../../hooks';
import { IconButtonProps, IconButtonSize } from './icon-button.component';
import { StyledIconBtn } from './styled-icon-btn.component';
import composeRefs from '@seznam/compose-react-refs';

type Props = Omit<IconButtonProps, 'showLabelOnHover' | 'size'> & {
  size: IconButtonSize;
};

export const BtnWithHoverLabel = forwardRef<HTMLButtonElement, Props>(
  ({ children, disabled, hoverLabelPlacement = 'top', label, ...rest }, externalRef) => {
    const { HoverLabel, labelProps, triggerProps, visible, hide } = useHoverLabel({
      placement: hoverLabelPlacement,
    });
    // if hover label is visible when button is disabled
    // it can hang around until the button is reenabled
    useEffect(() => {
      if (disabled && visible) {
        hide();
      }
    }, [disabled, visible, hide]);

    return (
      <StyledIconBtn
        {...rest}
        disabled={disabled}
        {...(disabled ? {} : triggerProps)}
        ref={composeRefs(externalRef, triggerProps.ref)}
      >
        {children}
        <HoverLabel {...labelProps}>{label}</HoverLabel>
      </StyledIconBtn>
    );
  }
);
