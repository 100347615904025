import { css } from 'emotion';
import {
  gray3,
  gray5,
  gray7,
  gray9,
  weaveBlue,
  weaveBlueDark,
  weaveGreen,
  weaveRed,
  weaveYellow,
  weavePink,
  weavePurple,
} from '../foundation/colors/colors';

const svgColor = (color: string) => css`
  & path {
    transition: fill 0.1s ease-in;
    fill: ${color};
  }
`;

const svg = (key: string, fillColor: string, hoverColor: string) => css`
  &.${key} {
    ${svgColor(fillColor)};

    &.interactive:hover {
      ${svgColor(hoverColor)};
    }
  }
`;

export const svgStyle = css`
  label: svgStyle;

  width: 24px;
  height: 24px;

  &.sm {
    width: 16px;
    height: 16px;
  }

  &.rg,
  &.md {
    width: 24px;
    height: 24px;
  }

  &.lg {
    width: 32px;
    height: 32px;
  }

  &.xl {
    width: 40px;
    height: 40px;
  }

  ${svgColor(weaveBlue)};

  &.interactive {
    cursor: pointer;

    &:hover {
      ${svgColor(weaveBlueDark)};
    }
  }

  ${svg('white', 'white', weaveBlue)};
  ${svg('gray', gray5, gray7)};
  ${svg('gray3', gray3, gray5)};
  ${svg('gray5', gray5, gray7)};
  ${svg('gray9', gray9, gray5)};
  ${svg('green', weaveGreen, gray9)};
  ${svg('red', weaveRed, gray9)};
  ${svg('yellow', weaveYellow, weaveRed)};
  ${svg('pink', weavePink, weavePurple)};
  ${svg('purple', weavePurple, weavePink)};
`;
