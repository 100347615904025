import { useContext } from 'react';
import { ModalRendererContext } from './modal-renderer.component';

export function useModalContext() {
  const context = useContext(ModalRendererContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used inside a ModalRendererContext');
  }
  return context;
}
