import React from 'react';
import MUIRadio from '@material-ui/core/Radio';
import { RadioButtonIcon } from './radio-button-icon';

interface RadioProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  onChange?: () => void;
  required?: boolean;
  value?: any;
}

/**
 * @deprecated replaced by RadioField from design-system
 */
export const DSMRadio: React.FC<RadioProps> = (props) => {
  return <MUIRadio {...props} color="primary" icon={<RadioButtonIcon />} checkedIcon={<RadioButtonIcon checked />} />;
};
