import React, { forwardRef } from 'react';
import { NakedButton } from '../../naked';
import { WeaveTheme } from 'dsm/theme';
import { IconButtonProps, IconButtonSize } from './icon-button.component';
import { css } from '@emotion/core';

type Props = Omit<
  IconButtonProps,
  'hoverLabelPlacement' | 'showLabelOnHover' | 'label' | 'size'
> & {
  size: IconButtonSize;
};

const buttonSizes = {
  normal: 40,
  small: 32,
};

export const StyledIconBtn = forwardRef<HTMLButtonElement, Props>(
  ({ children, size, ...rest }, ref) => (
    <NakedButton
      css={(theme: WeaveTheme) => css`
        width: ${buttonSizes[size]}px;
        height: ${buttonSizes[size]}px;
        background-color: transparent;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        transition: background-color 250ms ease-in-out;

        svg {
          fill: ${theme.colors.gray[600]};
          line-height: 1;
          transition: fill 250ms ease-in-out;
        }

        :disabled {
          cursor: default;

          svg {
            fill: ${theme.colors.gray[400]};
          }
        }

        :hover:not(:disabled),
        :focus:not(:disabled) {
          background-color: ${theme.colors.gray[300]};
          outline: none;
        }
      `}
      {...rest}
      ref={ref}
    >
      {children}
    </NakedButton>
  )
);
