/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const LockIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M5.75,9.75 L5.75,7 C5.75,3.54822031 8.54822031,0.75 12,0.75 C15.4517797,0.75 18.25,3.54822031 18.25,7 L18.25,9.75 L19,9.75 C20.7949254,9.75 22.25,11.2050746 22.25,13 L22.25,20 C22.25,21.7949254 20.7949254,23.25 19,23.25 L5,23.25 C3.20507456,23.25 1.75,21.7949254 1.75,20 L1.75,13 C1.75,11.2050746 3.20507456,9.75 5,9.75 L5.75,9.75 Z M8.25,9.75 L15.75,9.75 L15.75,7 C15.75,4.92893219 14.0710678,3.25 12,3.25 C9.92893219,3.25 8.25,4.92893219 8.25,7 L8.25,9.75 Z M5.75,12.25 L5,12.25 C4.58578644,12.25 4.25,12.5857864 4.25,13 L4.25,20 C4.25,20.4142136 4.58578644,20.75 5,20.75 L19,20.75 C19.4142136,20.75 19.75,20.4142136 19.75,20 L19.75,13 C19.75,12.5857864 19.4142136,12.25 19,12.25 L18.25,12.25 L5.75,12.25 Z" />
  </SvgIcon>
  );