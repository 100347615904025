/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const BuildingIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M15.75,20.25 L19.25,20.25 L19.25,4.5 C19.25,4.08578644 18.9142136,3.75 18.5,3.75 L5.5,3.75 C5.08578644,3.75 4.75,4.08578644 4.75,4.5 L4.75,20.25 L8.25,20.25 L8.25,15.75 C8.25,14.9215729 8.92157288,14.25 9.75,14.25 L14.25,14.25 C15.0784271,14.25 15.75,14.9215729 15.75,15.75 L15.75,20.25 Z M5.5,1.25 L18.5,1.25 C20.2949254,1.25 21.75,2.70507456 21.75,4.5 L21.75,21.25 C21.75,22.0784271 21.0784271,22.75 20.25,22.75 L3.75,22.75 C2.92157288,22.75 2.25,22.0784271 2.25,21.25 L2.25,4.5 C2.25,2.70507456 3.70507456,1.25 5.5,1.25 Z M10.75,16.75 L10.75,20.25 L13.25,20.25 L13.25,16.75 L10.75,16.75 Z M9,8.25 C8.30964406,8.25 7.75,7.69035594 7.75,7 C7.75,6.30964406 8.30964406,5.75 9,5.75 C9.69035594,5.75 10.25,6.30964406 10.25,7 C10.25,7.69035594 9.69035594,8.25 9,8.25 Z M9,12.25 C8.30964406,12.25 7.75,11.6903559 7.75,11 C7.75,10.3096441 8.30964406,9.75 9,9.75 C9.69035594,9.75 10.25,10.3096441 10.25,11 C10.25,11.6903559 9.69035594,12.25 9,12.25 Z M15,8.25 C14.3096441,8.25 13.75,7.69035594 13.75,7 C13.75,6.30964406 14.3096441,5.75 15,5.75 C15.6903559,5.75 16.25,6.30964406 16.25,7 C16.25,7.69035594 15.6903559,8.25 15,8.25 Z M15,12.25 C14.3096441,12.25 13.75,11.6903559 13.75,11 C13.75,10.3096441 14.3096441,9.75 15,9.75 C15.6903559,9.75 16.25,10.3096441 16.25,11 C16.25,11.6903559 15.6903559,12.25 15,12.25 Z" />
  </SvgIcon>
  );