import React from 'react';
import { useActiveTab } from './tabs-provider.component';
import { TabBar } from '../common';
import { getKeyboardHandler } from '../common/utils';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: Array<JSX.Element | boolean | null>;
};

const handleKeyDown = getKeyboardHandler('[role="tab"]');

/**
 * @deprecated
 */
export const TabList = ({ children, ...rest }: Props) => {
  const activeTab = useActiveTab();

  return (
    <TabBar
      {...rest}
      activeTab={activeTab}
      role="tablist"
      tabIndex={-1}
      onKeyDown={handleKeyDown}
    >
      {children}
    </TabBar>
  );
};
