/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const PortingNumbersIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M6.5,15 L6.5,9 L3.25,9 C2.55964406,9 2,8.44035594 2,7.75 C2,7.05964406 2.55964406,6.5 3.25,6.5 L6.5,6.5 L6.5,3.25 C6.5,2.55964406 7.05964406,2 7.75,2 C8.44035594,2 9,2.55964406 9,3.25 L9,6.5 L15,6.5 L15,3.25 C15,2.55964406 15.5596441,2 16.25,2 C16.9403559,2 17.5,2.55964406 17.5,3.25 L17.5,6.5 L20.75,6.5 C21.4403559,6.5 22,7.05964406 22,7.75 C22,8.44035594 21.4403559,9 20.75,9 L17.5,9 L17.5,15 L20.75,15 C21.4403559,15 22,15.5596441 22,16.25 C22,16.9403559 21.4403559,17.5 20.75,17.5 L17.5,17.5 L17.5,20.75 C17.5,21.4403559 16.9403559,22 16.25,22 C15.5596441,22 15,21.4403559 15,20.75 L15,17.5 L9,17.5 L9,20.75 C9,21.4403559 8.44035594,22 7.75,22 C7.05964406,22 6.5,21.4403559 6.5,20.75 L6.5,17.5 L3.25,17.5 C2.55964406,17.5 2,16.9403559 2,16.25 C2,15.5596441 2.55964406,15 3.25,15 L6.5,15 Z M9,15 L15,15 L15,9 L9,9 L9,15 Z" />
  </SvgIcon>
  );