import React from 'react';
import { TabsProvider, TabSetter } from './tabs-provider.component';
import { TabList } from './tab-list.component';
import { TabListItem } from './tab-list-item.component';
import { TabPanel } from './tab-panel.component';

type Props = {
  children: Array<JSX.Element | boolean | null>;
  initialTab: string;
  onChange: TabSetter;
};

/**
 * @deprecated replaced by Tabs from design-system
 */
export const DSMTabs = ({ children, initialTab, onChange }: Props) => (
  <TabsProvider initialTab={initialTab} onChange={onChange}>
    {children}
  </TabsProvider>
);

DSMTabs.Bar = TabList;
DSMTabs.Tab = TabListItem;
DSMTabs.Panel = TabPanel;
