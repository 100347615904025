import React, { forwardRef } from 'react';
import { WeaveTheme } from '../theme/weaveTheme';
import { ButtonProps, ButtonRef } from './button-type';
import { baseButtonStyle } from './common';
import { css } from '@emotion/core';

/**
 * @deprecated import from design-system
 */
export const SecondaryButton = forwardRef<ButtonRef, ButtonProps>(
  ({ children, size = 'small', ...rest }, ref) => (
    <button
      css={(theme: WeaveTheme) => [
        baseButtonStyle(theme, size),
        css`
          background: transparent;
          border: 2px solid ${theme.colors.gray[400]};
          color: ${theme.colors.gray[500]};
          transition: background 250ms ease-out, border-color 250ms ease-out;

          :hover,
          :focus {
            background: ${theme.colors.gray[200]};
            border-color: ${theme.colors.gray[200]};
          }

          :active {
            background: transparent;
            border-color: ${theme.colors.weaveBlue};
          }

          :disabled {
            background: white;
            border-color: ${theme.colors.gray[200]};
          }
        `,
      ]}
      {...rest}
      ref={ref}
    >
      {children}
    </button>
  )
);
