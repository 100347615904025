import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MUITextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { TextLink } from '../text-link';

const useStyles = makeStyles({
  textFieldRoot: {
    marginBottom: '24px',
  },
  helperTextRoot: {
    marginBottom: '-24px',
  },
});

export interface TextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  className?: string;
  label?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  multiline?: boolean;
  rows?: number;
  fullWidth?: boolean;
  disabled?: boolean;
  type?: string;
  actionText?: string;
  onActionClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

/**
 * @deprecated import from design-system
 */
export const TextField: React.FC<TextFieldProps> = ({
  children,
  actionText,
  onActionClick,
  onChange,
  error,
  disabled,
  value,
  fullWidth,
  type,
  ...props
}) => {
  const isControlled = !!onChange && typeof value !== 'undefined';
  const classes = useStyles();
  const [hasValue, setHasValue] = useState<boolean>(
    isControlled ? !!value : !!props.defaultValue
  );

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!isControlled && !!actionText) {
      setHasValue(!!e.target.value);
    }
    if (onChange && typeof onChange === 'function') {
      onChange(e);
    }
  };

  const textLinkActive = !error && !disabled && (isControlled ? !!value : hasValue);
  const actionProps = !!actionText
    ? {
        endAdornment: (
          <TextLink
            disabled={!textLinkActive}
            css={{ textTransform: 'capitalize' }}
            size="large"
            weight="regular"
            onClick={onActionClick}
          >
            {actionText || ''}
          </TextLink>
        ),
      }
    : {};

  return (
    <MUITextField
      value={value}
      error={error}
      disabled={disabled}
      fullWidth={!!actionText ? true : fullWidth} //input size will jump when we show/hide the actiontext, so set to fullwidth
      margin="dense"
      variant="outlined"
      classes={{
        root: classes.textFieldRoot,
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperTextRoot,
        },
      }}
      onChange={handleValueChange}
      InputProps={{
        type,
        ...actionProps,
      }}
      {...props}
    />
  );
};
