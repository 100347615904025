/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const ChatStatusInvertedIconSmall: React.FC<SvgIconProps> = props => (
  <SvgIcon size={16} {...props} viewBox="0 0 16 16">
    <path d="M13.7452181,0 C14.8497876,-2.02906125e-16 15.7452181,0.8954305 15.7452181,2 L15.7452181,10 C15.7452181,11.1045695 14.8497876,12 13.7452181,12 L4.71805475,12 L0.649830649,13.2152825 C0.531549003,13.2506162 0.404377414,13.2409448 0.292799505,13.1881301 C0.0432063137,13.0699867 -0.0633548406,12.7718774 0.0547885051,12.5222842 L0.0547885051,12.5222842 L1.72203926,9 L1.74459956,9.023 L1.74521809,2 C1.74521809,0.8954305 2.64064859,2.02906125e-16 3.74521809,0 L13.7452181,0 Z" />
  </SvgIcon>
  );