import { jubcss } from '../../../../helpers/jub-css';
import { gray3, weaveGreen } from '../../../colors/colors';

export const radioStyleJub = jubcss(`
  font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
  appearance: none !important;
  height: 20px !important;
  width: 20px !important;
  background-color: white !important;
  border: 1px solid ${gray3} !important;
  border-radius: 50px !important;
  outline: none !important;
  cursor: pointer !important;
  transition: background-color 0.1s ease-in, border-color 0.1s ease-in !important;

  &:checked {
    background-color: ${weaveGreen} !important;
    border-color: ${weaveGreen} !important;
    
    &:focus {
      border-color: ${weaveGreen} !important;
      outline: none !important;
    }
  }

  &:focus {
    border-color: ${gray3} !important;
    outline: none !important;
  }

  label: checkbox;
`);
