/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const EmojiHappyIconSmall: React.FC<SvgIconProps> = props => (
  <SvgIcon size={16} {...props} viewBox="0 0 16 16">
    <path d="M8,15.5 C3.85786438,15.5 0.5,12.1421356 0.5,8 C0.5,3.85786438 3.85786438,0.5 8,0.5 C12.1421356,0.5 15.5,3.85786438 15.5,8 C15.5,12.1421356 12.1421356,15.5 8,15.5 Z M8,13.5 C11.0375661,13.5 13.5,11.0375661 13.5,8 C13.5,4.96243388 11.0375661,2.5 8,2.5 C4.96243388,2.5 2.5,4.96243388 2.5,8 C2.5,11.0375661 4.96243388,13.5 8,13.5 Z M6,7 C5.44771525,7 5,6.55228475 5,6 C5,5.44771525 5.44771525,5 6,5 C6.55228475,5 7,5.44771525 7,6 C7,6.55228475 6.55228475,7 6,7 Z M10,7 C9.44771525,7 9,6.55228475 9,6 C9,5.44771525 9.44771525,5 10,5 C10.5522847,5 11,5.44771525 11,6 C11,6.55228475 10.5522847,7 10,7 Z M11.7583274,10.1519462 C10.6602307,11.4293664 9.6997013,12.0000723 8,12.0000723 C6.25094151,12.0000723 5.09637102,11.3385066 4.18998018,10.0864748 C3.86611902,9.63911326 3.96623591,9.01391369 4.4135975,8.69005253 C4.86095909,8.36619137 5.48615866,8.46630825 5.81001982,8.91366985 C6.35911473,9.67215529 6.93139851,10.0000723 8,10.0000723 C9.02472746,10.0000723 9.48823431,9.72467617 10.2416726,8.84819848 C10.6016926,8.42938582 11.2330612,8.38172494 11.6518739,8.74174493 C12.0706865,9.10176493 12.1183474,9.73313354 11.7583274,10.1519462 Z" />
  </SvgIcon>
  );