import React from 'react';
import { useActiveTab } from './tabs-provider.component';

type Props = React.HTMLAttributes<HTMLElement> & {
  as?: React.ElementType | React.FC;
  children: React.ReactNode;
  controller: string;
  id: string;
};

export const TabPanel = ({ as = 'section', controller, id, ...rest }: Props) => {
  const hidden = useActiveTab() !== controller;
  const Component = as;
  return (
    <Component {...rest} role="tabpanel" hidden={hidden} aria-labelledby={controller} />
  );
};
