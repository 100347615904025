import { css } from 'emotion';
import {
  gray3,
  gray5,
  gray7,
  gray9,
  weaveBlue,
  weaveBlueDark,
  weaveGreen,
  weaveRed,
  weaveYellow,
  weavePink,
  weavePurple,
} from '../foundation/colors/colors';

const svg = (key: string, fillColor: string, hoverColor: string) => css`
  &.${key} {
    background: ${fillColor};

    &.interactive:hover {
      background: ${hoverColor};
    }
  }
`;

export const svgButtonStyle = css`
  label: svgButtonStyle;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.1s ease-in;
  width: 44px;
  height: 44px;
  background: ${weaveBlue};

  &.sm {
    width: 32px;
    height: 32px;
  }

  &.rg,
  &.md {
    width: 44px;
    height: 44px;
  }

  &.lg {
    width: 68px;
    height: 68px;
  }

  &.xl {
    width: 80px;
    height: 80px;
  }

  &.interactive {
    cursor: pointer;
    &:hover {
      background: ${weaveBlueDark};
    }
  }

  &.white {
    background: white;

    &.interactive:hover {
      background: ${weaveBlue};

      & path {
        fill: white;
      }
    }
  }

  ${svg('gray', gray5, gray7)};
  ${svg('gray3', gray3, gray5)};
  ${svg('gray5', gray5, gray7)};
  ${svg('gray9', gray9, gray5)};
  ${svg('green', weaveGreen, gray9)};
  ${svg('red', weaveRed, gray9)};
  ${svg('yellow', weaveYellow, weaveRed)};
  ${svg('pink', weavePink, weavePurple)};
  ${svg('purple', weavePurple, weavePink)};
`;
