import { jubcss } from '../../../helpers/jub-css';
import { gray3, gray7, weaveGreen } from '../../colors/colors';

export const checkboxStyleJub = jubcss(`
  font-family:  'Proxima Nova', 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;  
  appearance: none !important;
  height: 20px !important;
  width: 20px !important;
  background-color: white !important;
  border: 1px solid ${gray3} !important;
  border-radius: 50px !important;
  margin: 0 !important;
  outline: none !important;
  cursor: pointer !important;
  transition: background-color 0.1s ease-in, border-color 0.1s ease-in !important;

  :focus {
    border-color: ${gray3} !important;
  }

  :checked {
    background-color: ${weaveGreen} !important;
    border-color: ${weaveGreen} !important;
    
    :focus {
      border-color: ${weaveGreen} !important;
    }
  }

  &[disabled],
  &.disabled {
    background-color: ${gray7} !important;
    cursor: not-allowed !important;
  }

  label: checkbox;
`);

export const toggleStyleJub = jubcss(`
  font-family:  'Proxima Nova', 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;  
  position: relative !important;
  width: 43px !important;
  height: 24px !important;
  border: 1px solid ${gray3} !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
  vertical-align: middle !important;

  .horizontal input[type="checkbox"] {
    top: -1px !important;
    
    :checked {
      left: 18px !important;
    }
  }

  input[type="checkbox"] {
    transition: left 0.1s ease-in !important;
    background-color: ${gray3} !important;
    position: absolute !important;
    left: 1px !important;

    :focus {
      border-color: ${gray3} !important;
    }

    :checked {
      left: 20px !important;
      background-color: ${weaveGreen} !important;

      :focus {
        border-color: ${weaveGreen} !important;
      }
    }
  }

  label: toggleStyle;
`);

export const svgJub = jubcss(`
  font-family: 'Proxima Nova', 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  width: 13px !important;
  height: 15px !important;
  cursor: pointer !important;
  pointer-events: none !important;
  transform: translate(-50%, -50%);
  
  & path {
    fill: ${gray3};
    transition: fill 0.1s ease-in, border-color 0.1s ease-in !important;
  }
`);
