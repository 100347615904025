import { fontSize } from './font-size';
import { spacing } from './spacing';
import { useTheme } from 'emotion-theming';

const colors = {
  weaveBlue: '#28caf4',
  hoverBlue: '#4adaff',
  pressedBlue: '#25BBE2',
  pizazz: '#FF9000',
  studio: '#864ba0',
  strawberry: '#ff31a2',
  gray: {
    100: '#f9fbfe',
    200: '#f3f7fc',
    300: '#e2e7f1',
    400: '#c3c8d2',
    500: '#8d919b',
    600: '#31353d',
  },
  success: '#2bd53c',
  warning: '#ffb92e',
  error: '#ff6b59',
  disabled: '#E4E9EB',
  white: '#ffffff',
} as const;

/**
 * @deprecated import from design-system
 */
export const weaveTheme = Object.freeze({
  colors: {
    ...colors,
    gray100: colors.gray[100],
    gray200: colors.gray[200],
    gray300: colors.gray[300],
    gray400: colors.gray[400],
    gray500: colors.gray[500],
    gray600: colors.gray[600],
  },
  spacing,
  shadows: {
    none: 'none',
    light: '0 4px 10px 0 rgba(49, 53, 61, 0.13)',
    heavy: '0 4px 10px 0 rgba(49, 53, 61, 0.24)',
    footer: '0 -2px 4px 0 rgba(49, 53, 61, 0.2)',
    header: '0 4px 4px 0 rgba(49, 53, 61, 0.19)',
    hover: '0 8px 20px 0 rgba(49, 53, 61, 0.25)',
  },
  borderRadius: {
    none: '0px',
    small: '4px',
    medium: '8px',
  },
  fontFamily: "'Proxima Nova', sans-serif",
  fontSize: fontSize,
  font: {
    family: "'Proxima Nova', sans-serif",
    colors: {
      primary: colors.weaveBlue,
      default: colors.gray[600],
      light: colors.gray[500],
      disabled: colors.gray[400],
      white: colors.white,
      error: colors.error,
      success: colors.success,
      warn: colors.warning,
    },
    lineHeight: 1.375,
    size: {
      large: fontSize(16),
      medium: fontSize(14),
      small: fontSize(12),
      h1: fontSize(24),
      h2: fontSize(20),
      inherit: 'inherit',
    },
    weight: {
      regular: 'normal',
      semibold: 600,
      bold: 800,
      inherit: 'inherit',
    },
  },
  zIndex: {
    background: -1,
    low: 1,
    middle: 5,
    high: 10,
    higher: 100,
    highest: 1000,
    // RESERVED: not for normal usage
    modals: 10000,
    hints: 11000,
    alerts: 100000,
  },
});

/**
 * @deprecated import from design-system
 */
export const useWeaveTheme: () => WeaveTheme = useTheme;

export type WeaveTheme = typeof weaveTheme;
export type WeaveThemeColors = keyof typeof weaveTheme.colors;
export type WeaveThemeShadows = keyof typeof weaveTheme.shadows;
export type WeaveThemeBorderRadius = keyof typeof weaveTheme.borderRadius;
