import { css } from 'emotion';
import { gray3, weaveBlue } from '../../../colors/colors';

export const radioStyle = css`
  appearance: none;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid ${gray3};
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.1s ease-in, border-color 0.1s ease-in;

  &:checked {
    background-color: ${weaveBlue};
    border-color: ${weaveBlue};

    &:focus {
      border-color: ${weaveBlue};
      outline: none;
    }
  }

  &:focus {
    border-color: ${gray3};
    outline: none;
  }

  label: checkbox;
`;
