import { TagType, TAG_ELEMENT_TYPE, getTag } from '../message-template.models';
import { Node } from 'slate';

const NEW_LINE_DELIMITER = String.fromCharCode(10); // 10 is \n
const TAG_REG_EXP = '{([a-zA-Z][a-zA-Z _-]*?)}|\\[{([a-zA-Z][a-zA-Z _-]*?)}\\]';

const createTextNode = (text: string): Node => ({
  text,
});

const createTagNode = (tag: TagType): Node => ({
  type: TAG_ELEMENT_TYPE,
  tag,
  children: [createTextNode('')],
});

const createInvalidTagNode = (label: string): Node => ({
  type: TAG_ELEMENT_TYPE,
  tag: {
    label,
    key: '',
    value: '',
    invalid: true,
  },
  children: [createTextNode('')],
});

export const hasTag = (text: string): boolean => {
  return !!text.match(new RegExp(TAG_REG_EXP, 'g'));
};

export const isTag = (text: string): boolean => {
  const match = text.match(new RegExp(TAG_REG_EXP));
  return match ? match[0] === match.input : false;
};

export const validateTags = (tags: TagType[]): boolean => {
  let valid = true;

  // validate that keys are tags
  tags.forEach((tag) => {
    if (!isTag(tag.key)) {
      valid = false;
      console.warn(
        `Invalid tag key given to MessageTemplate, ${tag.key}. Needs to be letters, spaces, dashes or underscore surrounded by {}`
      );
    }
    const matches = tags.filter((item) => item === tag);
    if (matches.length > 1) {
      valid = false;
      console.warn(
        `Duplicate tag keys given to MessageTemplate for ${tag.key}. This this can cause unexpected behavior.`
      );
    }
  });

  return valid;
};

export const parseLine = (tags: TagType[], text: string): Node[] => {
  // build expression from tags
  const reg = new RegExp(TAG_REG_EXP, 'g');

  const nodes: Node[] = [];

  // Iterates through message to create nodes for the Slate editor
  // Fragments are text followed by a tag, tags are always at the end
  //    Ex: "this is text {tag_here}""
  //       -> "thi is text " would be a text node
  //       -> "{tag_here}" would be a tag node

  let match: RegExpExecArray | null;
  let fragmentStart = 0;
  let fragmentEnd = 0;

  // the es 2020 string.matchesAll is not well supported
  while ((match = reg.exec(text)) !== null) {
    const matchStart = match.index;
    const matchEnd = matchStart + match[0].length;
    fragmentEnd = matchEnd;

    // text before the tag
    if (fragmentStart < matchStart) {
      nodes.push(createTextNode(text.slice(fragmentStart, matchStart)));
    } else {
      // tags need a text node in front
      nodes.push(createTextNode(''));
    }

    // add tag if it exists
    if (match[0]) {
      const tag = getTag(tags, match[0]);
      tag ? nodes.push(createTagNode(tag)) : nodes.push(createInvalidTagNode(match[1])); // match[1] holds the matching group, content between the {...}
    }

    fragmentStart = fragmentEnd;
  }

  // text at end of fragment
  if (fragmentEnd < text.length) {
    nodes.push(createTextNode(text.slice(fragmentEnd, text.length)));
  } else if (fragmentEnd === text.length) {
    // empty text node if we ended with a tag
    nodes.push(createTextNode(''));
  } else if (fragmentEnd === 0) {
    // no tags were found
    nodes.push(createTextNode(text));
  }

  return nodes.length > 0 ? nodes : [createTextNode(text)];
};

export const parseTemplate = (text: string, tags: TagType[]): Node[] => {
  return text
    .split(NEW_LINE_DELIMITER)
    .map((line) => ({ children: parseLine(tags, line) }));
};

// not all items we use for unit test are exposed
export const testItems = {
  createTextNode,
  createTagNode,
  createInvalidTagNode,
  parseLine,
  parseTemplate,
  validateTags,
};

export const regExpTest = {
  TAG_REG_EXP,
  isTag,
};
