/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const StarFilledIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M8.04049647,7.20183022 L10.8683776,1.50013502 C11.2817769,0.666621659 12.4706767,0.666621659 12.884076,1.50013502 L15.7119571,7.20183022 L22.0381748,8.12209849 C22.9622491,8.25652261 23.3297393,9.39320872 22.6592514,10.0431541 L18.0857652,14.4765174 L19.1648846,20.7383711 C19.3231474,21.65673 18.3601971,22.3582943 17.5345504,21.9261626 L11.8762268,18.9646768 L6.21790319,21.9261626 C5.39225651,22.3582943 4.42930618,21.65673 4.58756905,20.7383711 L5.66668843,14.4765174 L1.09320225,10.0431541 C0.42271435,9.39320872 0.790204517,8.25652261 1.71427877,8.12209849 L8.04049647,7.20183022 Z" />
  </SvgIcon>
  );