/**
* This component was generated from a script. DO NOT MODIFY THIS FILE or your
* changes will be overridden the next time icon components are generated. to
* make persistent changes, modify the icon-template.hbs file and re run the
* "npm run generate-icons" script
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon.component';

/**
* @deprecated import from design-system
*/
export const CheckIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon size={24} {...props} viewBox="0 0 24 24">
    <path d="M19.2621165,5.11611652 C19.7502719,4.62796116 20.5417281,4.62796116 21.0298835,5.11611652 C21.5180388,5.60427189 21.5180388,6.39572811 21.0298835,6.88388348 L9.57523061,18.3385363 C9.08707524,18.8266917 8.29561902,18.8266917 7.80746366,18.3385363 L3.11611652,13.6471892 C2.62796116,13.1590338 2.62796116,12.3675776 3.11611652,11.8794223 C3.60427189,11.3912669 4.39572811,11.3912669 4.88388348,11.8794223 L8.69134713,15.6868859 L19.2621165,5.11611652 Z" />
  </SvgIcon>
  );