import React, { HTMLAttributes } from 'react';
import { Text, WeaveTheme, XIconSmall } from '../..';
import { css } from '@emotion/core';
import { TagType } from '../message-template.models';
import { KeyCodes } from 'constants/key-codes';
import { useHoverLabel } from '../../../hooks';
import { NakedButton } from '../../../naked';

type MessageTagProps = Omit<HTMLAttributes<HTMLElement>, 'color'> & {
  tag: TagType;
  placed?: boolean;
  error?: boolean;
  onSelect?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onRemove?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  focused?: boolean;
  selected?: boolean;
  contentEditable?: boolean; //used by slate-js in editor
};

export const MessageTemplateTag = React.forwardRef<HTMLSpanElement, MessageTagProps>(
  (
    { tag, placed = false, focused, error, selected, onRemove, onSelect, ...props },
    ref
  ) => {
    const { HoverLabel, labelProps, triggerProps } = useHoverLabel({ placement: 'top' });
    const showLabel = placed && error;

    return (
      <>
        <Text
          as={NakedButton}
          ref={ref}
          size="small"
          tabIndex={placed ? -1 : 0}
          css={(theme: WeaveTheme) => [
            css`
              display: inline-flex;
              flex: 0 1;
              align-items: center;
              height: 24px;
              padding: ${theme.spacing(0, 1)};
              border: 1px solid ${error ? theme.colors.error : theme.colors.gray[400]};
              border-radius: ${theme.borderRadius.small};
              user-select: none;
              white-space: nowrap;
              margin: 0;
              line-height: 0;
              outline: none;

              :hover,
              :focus {
                cursor: pointer;
                box-shadow: ${theme.shadows.light};
                svg {
                  color: ${theme.colors.gray[600]};
                }
              }
            `,
            placed &&
              focused &&
              selected &&
              css`
                box-shadow: ${theme.shadows.light};
              `,
            !placed &&
              css`
                :focus {
                  box-shadow: ${theme.shadows.light};
                }
              `,
          ]}
          onClick={(e) => {
            // stop events from propagating to the Slate editor
            // Slate has an onClick handler that handles the cursor
            // position. Can get goofed up if the node is removed.
            e.stopPropagation();
            if (placed) {
              if (typeof onRemove === 'function') {
                onRemove(e);
              }
            } else {
              if (typeof onSelect === 'function') {
                onSelect(e);
              }
            }
          }}
          onKeyDown={(e) => {
            if (!placed && e.keyCode === KeyCodes.Enter) {
              if (typeof onSelect === 'function') {
                onSelect(e);
              }
            }
          }}
          onDragStart={(e) => {
            e.dataTransfer.setData('text/plain', tag.key);
          }}
          draggable={!placed}
          {...(showLabel ? triggerProps : {})}
          {...props}
        >
          {tag.label}
          {placed && (
            <XIconSmall
              width={12}
              height={12}
              css={(theme) => ({ color: theme.colors.gray[400], marginLeft: 4 })}
            />
          )}
        </Text>
        {showLabel && (
          <HoverLabel theme="dark" css={{ padding: '8px' }} {...labelProps}>
            Invalid tag
          </HoverLabel>
        )}
      </>
    );
  }
);
