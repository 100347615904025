import { css } from 'emotion';
import {
  gray5,
  gray7,
  gray9,
  weaveBlue,
  weaveBlueDark,
  weaveBlueLight,
  weaveGreen,
  weaveRed,
  weaveYellow,
  weavePink,
} from '../colors/colors';

export const dotStyle = css`
  font-size: 11px;
  display: inline-block;
  border-radius: 40px;
  margin: 0 4px;
  cursor: pointer;
  outline: none;
  transition: opacity 0.2s ease-in;
  box-sizing: border-box;
  text-align: center;
  line-height: 1em;

  &:focus,
  &:active {
    outline: none;
  }
  &.md {
    width: 8px;
    height: 8px;
    padding: 4px;
  }
  &.has-text {
    width: 18px;
    height: 18px;
    padding: 3px 0 0 0;
    &.is-active {
      color: white;
    }
  }
  &.blue {
    background: transparent;
    border: 1px solid ${weaveBlue};
    color: ${weaveBlue};
    &.is-active {
      background: ${weaveBlue};
    }
  }
  &.lightBlue {
    background: transparent;
    border: 1px solid ${weaveBlueLight};
    color: ${weaveBlueLight};
    &.is-active {
      background: ${weaveBlueLight};
    }
  }
  &.darkBlue {
    background: transparent;
    border: 1px solid ${weaveBlueDark};
    color: ${weaveBlueDark};
    &.is-active {
      background: ${weaveBlueDark};
    }
  }
  &.green {
    background: transparent;
    border: 1px solid ${weaveGreen};
    color: ${weaveGreen};
    &.is-active {
      background: ${weaveGreen};
    }
  }
  &.yellow {
    background: transparent;
    border: 1px solid ${weaveYellow};
    color: ${weaveYellow};
    &.is-active {
      background: ${weaveYellow};
    }
  }
  &.red {
    background: transparent;
    border: 1px solid ${weaveRed};
    color: ${weaveRed};
    &.is-active {
      background: ${weaveRed};
    }
  }
  &.gray9 {
    background: transparent;
    border: 1px solid ${gray9};
    color: ${gray9};
    &.is-active {
      background: ${gray9};
    }
  }
  &.gray7 {
    background: transparent;
    border: 1px solid ${gray7};
    color: ${gray7};
    &.is-active {
      background: ${gray7};
    }
  }
  &.gray5 {
    background: transparent;
    border: 1px solid ${gray5};
    color: ${gray5};
    &.is-active {
      background: ${gray5};
    }
  }
  &.pink {
    background: transparent;
    border: 1px solid ${weavePink};
    color: ${weavePink};
    &.is-active {
      background: ${weavePink};
    }
  }
  &:hover {
    opacity: 0.8;
  }

  label: dotStyle;
`;
