import React from 'react';
import { WeaveTheme } from '..';

export type ModalBodyProps = {
  className?: string;
  children: React.ReactNode;
};

export const DSMModalBody = React.forwardRef<HTMLDivElement, ModalBodyProps>(
  ({ children, className }: ModalBodyProps, ref) => (
    <div
      ref={ref}
      tabIndex={-1}
      className={className}
      css={(theme: WeaveTheme) => ({
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        borderRadius: '8px',
        backgroundColor: theme.colors.white,
        boxShadow: theme.shadows.none,
        padding: theme.spacing(3),
        width: 'calc(350px - 48px)',
        '&:focus': {
          outline: 'none',
        },
      })}
    >
      {children}
    </div>
  )
);
